var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._t("filter"),_c('v-data-table',{staticClass:"mt-3 elevation-1 row-pointer",attrs:{"dense":!_vm.$vuetify.breakpoint.mdAndDown,"headers":_vm.cols,"items":_vm.data ? _vm.data.value : [],"options":_vm.opt,"server-items-length":_vm.data ? _vm.data['@odata.count'] : 0,"loading":_vm.isLoading,"show-select":_vm.showSelect,"item-class":_vm.getItemClass,"value":_vm.value,"footer-props":{ 'items-per-page-options': [10, 15, 30, 50, 100] }},on:{"input":function($event){return _vm.$emit('input', $event)},"update:options":function($event){return _vm.$emit('optionsUpdate', $event)},"click:row":function($event){return _vm.$emit('rowClick', $event)}},scopedSlots:_vm._u([{key:"item.najemOd",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$formatDate(item.najemOd))+" ")]}},{key:"item.najemDo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$formatDate(item.najemDo))+" ")]}},{key:"item.garanceNajemDo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$formatDate(item.garanceNajemDo))+" ")]}},{key:"item.najemOdBudouci",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$formatDate(item.najemOdBudouci))+" ")]}},{key:"item.maxNajem",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$formatNumber(item.maxNajem))+" ")]}},{key:"item.cenaSluzebBezDph",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$formatNumber(item.cenaSluzebBezDph))+" ")]}},{key:"item.plocha",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$formatNumber(item.plocha))+" ")]}}],null,true)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }