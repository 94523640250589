













































import { formatDate } from '@/helpers';
import { Komentar, User } from '@/models';
import { AuthGetter } from '@/store/auth/auth.getter';
import { Modules } from '@/store/modules';
import { orderBy } from 'lodash';
import { mapGetters } from 'vuex';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    computed: {
        ...mapGetters(Modules.AUTH, [AuthGetter.CURRENT_USER])
    }
})
export default class KomentarComponent extends Vue {
    @Prop() value!: Komentar[];
    @Prop({ default: 100 }) maxlength!: number;
    @Prop() readonly!: boolean;

    CURRENT_USER!: User;

    pridat = false;
    text = '';

    get komentare(): Komentar[] {
        return orderBy(
            this.value.map(m => {
                return { ...m, datum: new Date(m.datum) };
            }),
            v => v.datum,
            'desc'
        );
    }

    formatDate(date: any) {
        return formatDate(date, 'dd. MM. yyyy HH:mm');
    }

    add(): void {
        if (this.text) {
            const koment: Komentar = {
                tmpId: new Date().getTime(),
                text: this.text,
                uzivatel: this.CURRENT_USER,
                datum: new Date()
            };

            this.$emit('input', [...this.value, koment]);

            this.text = '';
            this.pridat = false;
        }
    }

    remove(item: Komentar): void {
        if (confirm('Opravdu chcete odstranit Váš komentár?')) {
            this.$emit('input', [...this.komentare.filter(f => f.tmpId != item.tmpId)]);
        }
    }
}
