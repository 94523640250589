import { AuthState } from './auth.state';
import { GetterTree } from 'vuex';
import { Modules } from '../modules';
import { User } from '@/models/user';
import { UserRight } from '@/models/user-right';

export enum AuthGetter {
    IS_LOGGED_IN = 'IS_LOGGED_IN',
    TOKEN = 'TOKEN',
    AUTH_HEADER = 'AUTH_HEADER',

    CURRENT_USER = 'CURRENT_USER',
    USER_RIGHT = 'USER_RIGHT'
}

export enum ModAuthGetter {
    IS_LOGGED_IN = Modules.AUTH + '/' + AuthGetter.IS_LOGGED_IN,
    TOKEN = Modules.AUTH + '/' + AuthGetter.TOKEN,
    AUTH_HEADER = Modules.AUTH + '/' + AuthGetter.AUTH_HEADER,

    CURRENT_USER = Modules.AUTH + '/' + AuthGetter.CURRENT_USER,
    USER_RIGHT = Modules.AUTH + '/' + AuthGetter.USER_RIGHT
}

export interface AuthGetterTypes {
    [AuthGetter.IS_LOGGED_IN](state: AuthState): boolean;
    [AuthGetter.TOKEN](state: AuthState): string | null;
    [AuthGetter.AUTH_HEADER](state: AuthState): string;

    [AuthGetter.CURRENT_USER](state: AuthState): User | null;
    [AuthGetter.USER_RIGHT](state: AuthState): UserRight[];
}

export const authGetters: GetterTree<AuthState, any> & AuthGetterTypes = {
    [AuthGetter.IS_LOGGED_IN]: state => {
        return !!state.token && !!state.expiration && new Date() < state.expiration;
    },

    [AuthGetter.TOKEN]: state => {
        return state.token;
    },

    [AuthGetter.AUTH_HEADER]: state => {
        return state.type + ' ' + state.token;
    },

    [AuthGetter.CURRENT_USER]: state => {
        return state.user;
    },

    [AuthGetter.USER_RIGHT]: state => {
        return state.prava;
    }
};
