

















































import { Component, Vue, Watch } from 'vue-property-decorator';
import R from '@/models/resources';

import CmsPasswordFieldComponent from '@/ui/components/inputs/password-field.component.vue';
import { UserService } from '@/services/user.service';
import { AxiosError } from 'node_modules/axios';
import { ModAuthAction } from '@/store/auth/auth.types';

@Component({
    components: {
        'cms-password-field': CmsPasswordFieldComponent
    }
})
export default class LoginPage extends Vue {
    username = '';
    password = '';

    usernamePh = '';
    passwordPh = '';

    errorMessage = '';

    isLoading = false;
    valid = true;
    formVisible = false;

    async ad() {
        await Vue.prototype.$msal
            .loginPopup({
                authority: process.env.AZURE_AUTHORITY ?? 'https://login.microsoftonline.com/common',
                scopes: [process.env.AZURE_SCOPE ?? '7fef5da9-7f75-484c-963e-da55c93da07f/CG.Login']
            })
            .then((resp: any) => {
                this.$store.dispatch(ModAuthAction.LOGIN.toString(), {
                    token: resp.accessToken,
                    expiration: resp.expiresOn,
                    type: resp.tokenType,
                    ad: true
                });
                this.$snackbar.showSnackbar({
                    text: 'Byl jste přihlášen: ' + resp.account?.name ?? '',
                    color: 'success'
                });
            })
            .catch((error: any) => {
                this.errorMessage = error.message;
            });
    }

    validate(e: boolean) {
        this.valid = e;
    }

    @Watch('username')
    onChangeUserName() {
        if (this.username) {
            this.usernamePh = ' ';
        } else {
            this.usernamePh = '';
        }
    }

    @Watch('password')
    onChangePassword() {
        if (this.password) {
            this.passwordPh = ' ';
        } else {
            this.passwordPh = '';
        }
    }

    async submit(): Promise<void> {
        if ((this.$refs.form as any).validate()) {
            this.isLoading = true;
            this.errorMessage = '';

            try {
                const result = await new UserService().login(this.username, this.password);
                await this.$store.dispatch(ModAuthAction.LOGIN.toString(), { ...result.data, ad: false });

                this.$snackbar.showSnackbar({
                    text: 'Byl jste přihlášen: ' + this.username,
                    color: 'success'
                });
            } catch (ex) {
                const error = ex as AxiosError;
                if (error.response?.status === 401) {
                    this.errorMessage = R.LoginChyba;
                } else {
                    this.errorMessage = error.message;
                }
            }

            this.isLoading = false;
        }
    }
}
