export interface StavZadost {
    id: number;

    nazev: string;
}

export enum eStavZadost {
    Neodeslano = 1,
    NovyPozadavek = 2,
    Akceptovano = 3,
    Neakceptovano = 4,
    CekaNaOpravu = 5,
    Opraveno = 6,
    Odeslano = 7,
    Podepsano = 8,
    Stornovano = 9
}
