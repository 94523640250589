







































import { Podlazi } from '@/models';
import { Nemovitost } from '@/models/nemovitost';
import { PnpsNemovitostVazba, PozadavekNaPripravuSmlouvy } from '@/models/pnps';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import PrilohyComponent from '../../prilohy/prilohy.component.vue';

@Component({ components: { 'cms-prilohy': PrilohyComponent } })
export default class PnpsSpravaTab extends Vue {
    @Prop() entityId!: number;
    @Prop() data!: PnpsNemovitostVazba[];
    @Prop() value!: PozadavekNaPripravuSmlouvy;
    @Prop() readonly!: boolean;

    editPnps: PozadavekNaPripravuSmlouvy | null = null;

    editDialogShow = false;
    editPnpsNemovitostVazba: PnpsNemovitostVazba | null = null;
    editDatumPredani: Date | null = null;
    editDatumPrevzeti: Date | null = null;
    headers = [
        { text: 'Tento dodatek', value: 'tentoDodatek' },
        { text: 'Katalogové číslo', value: 'nemovitost.nemovitostCvs[0].katalogoveCislo' },
        { text: 'Interní označení', value: 'nemovitost.nemovitostCvs[0].oznaceni' },
        { text: 'Budova', value: 'nemovitost.budova.nazev' },
        { text: 'Podlaží', value: 'nemovitost.podlazis' },
        { text: 'Typ nemovitosti', value: 'nemovitost.typNemovitost.nazev' },
        { text: 'Datum předání', value: 'datumPredani' },
        { text: 'Datum převzetí', value: 'datumPrevzeti' },
        { text: '', value: 'editButton' }
    ];

    created(): void {
        this.editPnps = { ...this.value };
    }

    edit(item: PnpsNemovitostVazba) {
        this.editPnpsNemovitostVazba = item;
        this.editDatumPredani = this.editPnpsNemovitostVazba.datumPredani;
        this.editDatumPrevzeti = this.editPnpsNemovitostVazba.datumPrevzeti;
        this.editDialogShow = true;
    }

    okDialog() {
        if (this.editPnpsNemovitostVazba != null) {
            this.editPnpsNemovitostVazba.datumPredani = this.editDatumPredani;
            this.editPnpsNemovitostVazba.datumPrevzeti = this.editDatumPrevzeti;
            this.$emit('editVazba', this.editPnpsNemovitostVazba);
        }
        this.editDatumPrevzeti = null;
        this.editDatumPrevzeti = null;
        this.editDialogShow = false;
    }
    closeDialog() {
        this.editDialogShow = false;
        this.editDatumPrevzeti = null;
        this.editDatumPrevzeti = null;
    }
    getPodlazis(nemovitost: Nemovitost) {
        return nemovitost.podlazis.map((x: Podlazi) => x.nazev).join(', ');
    }

    getInfoTentoDodatek(item: PnpsNemovitostVazba) {
        if (item.datumOdebrani) return item.pnpsOdebralId === this.editPnps?.id;
        return item.pnpsPridalId === this.editPnps?.id;
    }

    getItemClass(item: PnpsNemovitostVazba): string {
        if (item.datumOdebrani != null) {
            return 'stav-odebrano';
        }

        return '';
    }

    @Watch('editPnps')
    onEditPartner(): void {
        this.$emit('input', this.editPnps);
    }
}
