import { RouteConfig } from 'vue-router';

import ReportPage from '@/ui/pages/reporter/report.page.vue';
import DesignerPage from '@/ui/pages/reporter/designer.page.vue';

export const REPORTER_ROUTES: RouteConfig[] = [
    {
        path: 'report/:reportId',
        component: ReportPage,
        name: 'reporter.report',
        meta: {
            breadcrumps: [
                {
                    text: 'Reporty',
                    href: '/list/report'
                }
            ],
            breadcrump: 'Správa reportů'
        }
    },
    {
        path: 'myreport/:reportId',
        component: ReportPage,
        name: 'myreporter.report',
        meta: {
            breadcrumps: [
                {
                    text: 'Reporty',
                    href: '/list/myreports'
                }
            ],
            breadcrump: 'Reporty'
        }
    },
    {
        path: 'designer/:reportId',
        component: DesignerPage,
        name: 'reporter.designer',
        meta: {
            breadcrumps: [
                {
                    text: 'Reporty',
                    href: '/list/report'
                }
            ],
            breadcrump: 'Designer'
        }
    }
];
