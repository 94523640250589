import { NemovitostCv } from '@/models/nemovitost';
import { PozadavekNaPripravuSmlouvy } from '@/models/pnps';
import { GetterTree } from 'vuex';
import { Modules } from '../modules';
import { DraftState } from './draft.state';

export enum DraftGetter {
    PNPS = 'PNPS',
    PNPS_DRAFT = 'PNPS_DRAFT',
    NEMOVITOSTS = 'NEMOVITOSTS',

    SIDE_EDIT = 'SIDE_EDIT'
}

export enum ModDraftGetter {
    PNPS_DRAFT = Modules.DRAFT + '/' + DraftGetter.PNPS_DRAFT,

    PNPS = Modules.DRAFT + '/' + DraftGetter.PNPS,
    NEMOVITOSTS = Modules.DRAFT + '/' + DraftGetter.NEMOVITOSTS,

    SIDE_EDIT = Modules.DRAFT + '/' + DraftGetter.SIDE_EDIT
}

export interface DraftGetterTypes {
    [DraftGetter.PNPS_DRAFT](state: DraftState): boolean;
    [DraftGetter.NEMOVITOSTS](state: DraftState): NemovitostCv[];
    [DraftGetter.PNPS](state: DraftState): PozadavekNaPripravuSmlouvy | null;
    [DraftGetter.SIDE_EDIT](state: DraftState): boolean;
}

export const draftGetters: GetterTree<DraftState, any> & DraftGetterTypes = {
    [DraftGetter.PNPS]: state => {
        return state.pnps;
    },

    [DraftGetter.PNPS_DRAFT]: state => {
        return state.pnpsDraft;
    },

    [DraftGetter.NEMOVITOSTS]: state => {
        return state.nemovitosts;
    },

    [DraftGetter.SIDE_EDIT]: state => {
        return state.pnpsDraft || !!state.pnps;
    }
};
