export interface ArrayOfUplouderResponseXml {
    ArrayOfUplouderResponse: {
        UplouderResponse: UplouderResponse | UplouderResponse[];
    };
}

export interface UplouderResponse {
    FileName: string;
    NewFileGuid: string;
    OutputStatus: OutputStatus;
}

export enum OutputStatus {
    SavedOK = 'SavedOK',
    DeletedOK = 'DeletedOK',
    NoFile = 'NoFile',
    Forbidden = 'Forbidden',
    MergeOk = 'MergeOk',
    CorruptedFile = 'CorruptedFile'
}
