















































import { Lokalita, TimeVersion, User } from '@/models';
import { PozadavekNaPripravuSmlouvy } from '@/models/pnps';
import { AuthGetter } from '@/store/auth/auth.getter';
import { Modules } from '@/store/modules';
import { mapGetters } from 'vuex';
import { Component, Vue, Watch } from 'vue-property-decorator';

import LokalitaListSmallComponent from '@/ui/components/lokalita/lokalita-list-small.component.vue';
import PnpsListSmallComponent from '@/ui/components/pnps/pnps-list-small.component.vue';
import { AppGetter } from '@/store/app/app.getter';

@Component({
    components: {
        'cms-lokalita-list-small': LokalitaListSmallComponent,
        'cms-pnps-list-small': PnpsListSmallComponent
    },
    computed: {
        ...mapGetters(Modules.AUTH, [AuthGetter.CURRENT_USER]),
        ...mapGetters(Modules.APP, [AppGetter.CURR_TIMEVERSION])
    }
})
export default class DashboardPage extends Vue {
    isLoadingLokality = false;
    isLoadingPnpsZadatel = false;
    isLoadingPnpsZpracovatel = false;

    CURRENT_USER!: User;
    CURR_TIMEVERSION!: TimeVersion;

    lokalitas: Lokalita[] = [];
    pnpsZadatel: PozadavekNaPripravuSmlouvy[] = [];
    pnpsZpracovatel: PozadavekNaPripravuSmlouvy[] = [];

    headersLokalita = [];

    pnpsClick(pnps: PozadavekNaPripravuSmlouvy) {
        if (pnps.parentPnpsId) {
            this.$router.push({
                name: 'podpozadavek.pnps.detail',
                params: { pnpsId: String(pnps.parentPnpsId), podPnpsId: String(pnps.id) }
            });
        } else {
            this.$router.push({
                name: 'pnps.detail',
                params: { pnpsId: String(pnps.id) }
            });
        }
    }

    lokalitaClick(e: Lokalita) {
        this.$router.push({ name: 'nemovitost.list', query: { filter: JSON.stringify({ lokalitaId: e.id }) } });
    }

    @Watch('CURR_TIMEVERSION', { immediate: true })
    onChangeTimeVersion() {
        this.fetchLokality();
    }

    mounted() {
        this.fetchPnps();
    }

    fetchLokality() {
        this.$odata
            .getList<Lokalita>('lokalita', {
                orderBy: 'volneNemovitosti desc',
                top: 5
            })
            .then(res => {
                this.lokalitas = res.data.value;
            });
    }

    fetchPnps() {
        this.$odata
            .getList<PozadavekNaPripravuSmlouvy>('pnps', {
                expand: {
                    obchodniPripad: {},
                    najemcePartner: { select: ['nazev'] },
                    zadatelUzivatel: { select: ['fullName'] },
                    zpracovatelUzivatel: { select: ['fullName'] },
                    stavZadost: { select: ['nazev'] }
                },
                orderBy: ['datumPodpisuPlan', 'id'],
                filter: {
                    zadatelUzivatelId: { eq: +this.CURRENT_USER.id }
                },
                top: 5
            })
            .then(res => {
                this.pnpsZadatel = res.data.value;
            });

        this.$odata
            .getList<PozadavekNaPripravuSmlouvy>('pnps', {
                expand: {
                    obchodniPripad: {},
                    najemcePartner: { select: ['nazev'] },
                    zadatelUzivatel: { select: ['fullName'] },
                    zpracovatelUzivatel: { select: ['fullName'] },
                    stavZadost: { select: ['nazev'] }
                },
                orderBy: ['datumPodpisuPlan', 'id'],
                filter: {
                    zpracovatelUzivatelId: { eq: +this.CURRENT_USER.id }
                }
            })
            .then(res => {
                this.pnpsZpracovatel = res.data.value;
            });
    }
}
