









































import { Component, Mixins, Ref, Vue, Watch } from 'vue-property-decorator';
import { Partner } from '@/models/partner';

import PartnerAdresyTab from '@/ui/components/partner/detail-tabs/partner-adresy.tab.vue';
import PartnerDetailTab from '@/ui/components/partner/detail-tabs/partner-detail.tab.vue';
import PartnerKontaktyJednaniTab from '@/ui/components/partner/detail-tabs/partner-kontakty-jednani.tab.vue';
import PartnerOsobyTab from '@/ui/components/partner/detail-tabs/partner-osoby.tab.vue';
import PartnerPrehledSmluvTab from '@/ui/components/partner/detail-tabs/partner-prehled-smuv.tab.vue';
import { VForm } from '@/models/vue-components';
import NotSavedComponent from '@/ui/components/not-saved.component';
import { handleException } from '@/helpers';
import R from '@/models/resources';

@Component({
    components: {
        'cms-tab-osoby': PartnerOsobyTab,
        'cms-tab-detail': PartnerDetailTab,
        'cms-tab-adresy': PartnerAdresyTab,
        'cms-tab-jednani': PartnerKontaktyJednaniTab,
        'cms-tab-smlouvy': PartnerPrehledSmluvTab
    }
})
export default class PartnerDetailPage extends Mixins(Vue, NotSavedComponent) {
    @Ref('partnerForm') partnerForm!: VForm;

    activeTab = 0;
    partner: Partner | null = null;
    isSaving = false;

    @Watch('$route.params.id', { immediate: true })
    onParamsChange(id: number) {
        this.fetchPartner(id);
    }

    @Watch('partner', { deep: true })
    onChangePartner() {
        this.isChanged = true;
    }

    ulozit() {
        if (!this.partnerForm.validate()) return;

        this.isSaving = true;

        this.activeTab = 0;
        this.$nextTick(() => {

                this.$odata
                    .update<Partner>('partner', this.partner?.id, this.partner)
                    .then(a => {
                        this.isChanged = false;
                        this.isSaving = false;

                        this.$snackbar.success(R.Ulozeno);

                        this.fetchPartner(a.data.id);
                    })
                    .catch(err => {
                        handleException(err);
                    })
                    .finally(() => {this.isSaving = false});
            });
   
    }

    async fetchPartner(id: number) {
        const expand = { adresas: {}, osobas: {} } as any;
        this.partner = (await this.$odata.getById<Partner>('partner', +id, { expand })).data;

        this.$nextTick(() => (this.isChanged = false));
    }
}
