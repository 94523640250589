import { AuthResponse } from '@/models/auth-reponse';
import router from '@/router';
import { UserService } from '@/services/user.service';
import { ActionTree } from 'vuex';
import { AuthGetter } from './auth.getter';
import { AuthMutation } from './auth.mutation';
import { AuthState } from './auth.state';
import { AugmentedActionContext, AuthAction } from './auth.types';

export interface AuthActionsTypes {
    [AuthAction.LOGIN](context: AugmentedActionContext, payload: AuthResponse): void;
    [AuthAction.LOGOUT](context: AugmentedActionContext): void;
    [AuthAction.RE_LOGIN](context: AugmentedActionContext): void;
    [AuthAction.INIT_USER](context: AugmentedActionContext): void;
    [AuthAction.INIT_RIGHT](context: AugmentedActionContext): void;
}

export const authActions: ActionTree<AuthState, any> & AuthActionsTypes = {
    [AuthAction.LOGIN]: async (context, payload: AuthResponse) => {
        context.commit(AuthMutation.SET_AUTH, {
            token: payload.token,
            expiration: new Date(payload.expiration),
            type: payload.type,
            refreshToken: payload.refreshToken,
            ad: payload.ad
        });

        const rd = router.currentRoute.query['redirect'];
        router.push((rd as string) || '/');
    },

    [AuthAction.LOGOUT]: context => {
        new UserService().logout().finally(() => {
            context.commit(AuthMutation.CLEAR_AUTH);
            router.push({ name: 'login-page' });
        });
    },

    [AuthAction.RE_LOGIN]: context => {
        new UserService().logout().finally(() => {
            context.commit(AuthMutation.CLEAR_AUTH);
            router.push({ name: 'login-page', query: { redirect: router.currentRoute.path } });
        });
    },

    [AuthAction.INIT_AUTH]: context => {
        if (context.getters[AuthGetter.IS_LOGGED_IN]) {
            context.commit(AuthMutation.INIT_AUTH);
        } else {
            context.dispatch(AuthAction.RE_LOGIN);
        }
    },

    [AuthAction.INIT_USER]: context => {
        new UserService()
            .getCurrentUser()
            .then(user => {
                context.commit(AuthMutation.SET_USER, { user });
            })
            .catch(() => {
                context.dispatch(AuthAction.RE_LOGIN);
            });
    },

    [AuthAction.INIT_RIGHT]: context => {
        new UserService()
            .getPrava()
            .then(prava => {
                context.commit(AuthMutation.SET_PRAVA, { prava });
            })
            .catch(() => {
                context.dispatch(AuthAction.RE_LOGIN);
            });
    }
};
