







































































































































































































































































































































import { PnpsDph, PnpsNemovitostVazba, PnpsPojisteni, PnpsZakon340, PozadavekNaPripravuSmlouvy } from '@/models/pnps';
import { ePrava } from '@/models/user-right';
import { eTypPronajem, TypPronajem } from '@/models/typ-pronajem';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import PrilohyComponent from '@/ui/components/prilohy/prilohy.component.vue';
import KomentarComponent from '../../komentar/komentar.component.vue';
import { Sluzby } from '@/models/sluzby';
import { TypDodatek } from '@/models/typ-dodatek';
import { CenoTvorba } from '@/models/nemovitost';
import { eStavZadost } from '@/models/stav-zadost';

@Component({
    components: {
        'cms-prilohy': PrilohyComponent,
        'cms-komentare': KomentarComponent
    }
})
export default class PnpsDetailTab extends Vue {
    @Prop() value!: PozadavekNaPripravuSmlouvy;
    @Prop({ default: false }) hidePodpozadavek!: boolean;
    @Prop() sumaCena!: number | null;
    @Prop({ default: false }) validovatRequiredPole!: boolean;
    @Prop() readonly!: boolean;
    @Prop() vazbas!: PnpsNemovitostVazba[];

    rulesCislo: any[] = [];
    /* Vypíná se při stornu, tam se může uložit i bez těchto polí*/
    zpracovatelPravo = ePrava.JeZpracovatel;
    typPronajems: TypPronajem[] = [];
    pnpsDphs: PnpsDph[] = [];
    pnpsPojistenis: PnpsPojisteni[] = [];
    pnpsZakons: PnpsZakon340[] = [];
    dodatky: PozadavekNaPripravuSmlouvy[] = [];
    sluzby: Sluzby[] = [];
    typyDodatku: TypDodatek[] = [];
    editPnps: PozadavekNaPripravuSmlouvy | null = null;

    created(): void {
        this.editPnps = { ...this.value };
        if (this.editPnps.stavZadostId > eStavZadost.NovyPozadavek) {
            //Číslování hlavního požadavku xxx-xxxxxx
            if (this.editPnps.parentPnpsId == null) {
                this.rulesCislo = [
                    (cislo: string) => {
                        const regexp = new RegExp('^[0-9]{3}(-){1}[0-9]{6}$');
                        if (!regexp.test(cislo)) {
                            return 'Požadovaný formát čísel: xxx-xxxxxx';
                        }

                        return true;
                    }
                ];
            }
            //Číslování dodatku (1,2,3...). Dodatky obsahující v názvu "Dohoda" se nečíslují
            else {
                this.rulesCislo = [
                    (cislo: string) => {
                        const regexp = new RegExp('^[0-9]*$');
                        if (!regexp.test(cislo)) {
                            return 'Požadovaný formát čísel: x';
                        }
                        return true;
                    }
                ];
            }
        }
    }

    async beforeMount() {
        this.typPronajems = (await this.$odata.getList<TypPronajem>('typpronajem')).data.value;
        this.pnpsDphs = (await this.$odata.getList<PnpsDph>('pnpsdph')).data.value;
        this.pnpsPojistenis = (await this.$odata.getList<PnpsPojisteni>('pnpspojisteni')).data.value;
        this.pnpsZakons = (await this.$odata.getList<PnpsZakon340>('pnpszakon340')).data.value;
        this.sluzby = (await this.$odata.getList<Sluzby>('sluzby', { orderBy: ['nazev'] })).data.value;
        this.typyDodatku = (await this.$odata.getList<TypDodatek>('typDodatek', { orderBy: ['nazev'] })).data.value;
        await this.loadHistoryValues();

        if (this.editPnps?.id) {
            this.dodatky = (
                await this.$odata.getList<PozadavekNaPripravuSmlouvy>('pnps', {
                    filter: { parentPnpsId: this.editPnps?.id },
                    expand: {
                        typPronajem: {},
                        stavPronajem: {},
                        stavZadost: {},
                        zadatelUzivatel: {},
                        zpracovatelUzivatel: {},
                        typDodatek: {},
                        cenoTvorbas: {}
                    }
                })
            ).data.value;
        }
    }

    @Watch('editPnps')
    onEditPartner(): void {
        this.$emit('input', this.editPnps);
    }

    openPodpozadavek(item: PozadavekNaPripravuSmlouvy) {
        this.$router.push({
            name: 'podpozadavek.pnps.detail',
            params: { pnpsId: String(this.editPnps?.id), podPnpsId: String(item.id) }
        });
    }

    removePodpozadavek(item: any) {}

    typPronajemChange(item: any) {
        if (this.editPnps != null) {
            if (item == +eTypPronajem.DobaNeurcita) {
                this.editPnps.vypovedniLhuta = 3;
            } else {
                this.editPnps.vypovedniLhuta = null;
            }
        }
    }

    //--- Funkce pro tabulku s dodatky ---//

    /*Ukázat všechny nemovitosti přidané tímto dodatkem kromě nemovitostí, které jím byly přidány a pak odebrány*/
    getDodatekNemovitost(dodatekId: number): string {
        const nemovitosts = this.vazbas
            .filter(x => x.pnpsPridalId == dodatekId && (x.pnpsOdebralId == null || x.pnpsOdebralId != x.pnpsPridalId))
            .map(x => (x.nemovitost.typNemovitost?.nazev ?? '') + ' ' + x.nemovitost.nemovitostCvs[0].oznaceni);
        return nemovitosts.join('  ');
    }

    getDodatekJistota(dodatekId: number): number {
        const dodatek = this.dodatky.filter(x => x.id == dodatekId)[0];
        if (dodatek.stavZadostId == eStavZadost.Stornovano) return 0;
        if (dodatek.cenoTvorbas.length) {
            const sluzby = dodatek.cenoTvorbas
                .filter(x => x.nemovitostId != null && this.isAktualniNemovitost(x.nemovitostId))
                .map(x => (x.navyseniJistoty ?? 0) * (x.plocha ?? 0));
            return sluzby?.reduce((a, b) => a + b) ?? 0;
        }
        return 0;
    }

    getDodatekNajemnePnpsCelkemSDph(dodatekId: number): number {
        const dodatek = this.dodatky.filter(x => x.id == dodatekId)[0];
        if (dodatek.stavZadostId == eStavZadost.Stornovano) return 0;
        if (dodatek.cenoTvorbas.length) {
            const celkem = dodatek.cenoTvorbas
                .filter(x => x.nemovitostId != null && this.isAktualniNemovitost(x.nemovitostId))
                .map(x => new CenoTvorba(x).cenaNajemnePnpsCelkemSDph ?? 0);
            return celkem?.reduce((a, b) => a + b) ?? 0;
        }
        return 0;
    }

    getDodatekServisniPoplatek(dodatekId: number): number {
        const dodatek = this.dodatky.filter(x => x.id == dodatekId)[0];
        if (dodatek.stavZadostId == eStavZadost.Stornovano) return 0;
        if (dodatek.cenoTvorbas.length) {
            const poplatek = dodatek.cenoTvorbas
                .filter(x => x.nemovitostId != null && this.isAktualniNemovitost(x.nemovitostId))
                .map(x => new CenoTvorba(x).cenaServisniPoplatekFinal);

            return Math.round(poplatek?.reduce((a, b) => a + b) ?? 0);
        }
        return 0;
    }

    getDodatekCenaSluzeb(dodatekId: number): number {
        const dodatek = this.dodatky.filter(x => x.id == dodatekId)[0];
        if (dodatek.stavZadostId == eStavZadost.Stornovano) return 0;
        if (dodatek.cenoTvorbas.length) {
            const sluzby = dodatek.cenoTvorbas
                .filter(x => x.nemovitostId != null && this.isAktualniNemovitost(x.nemovitostId))
                .map(x => x.cenaSluzebBezDph ?? 0);
            return Math.round(sluzby?.reduce((a, b) => a + b) ?? 0);
        }
        return 0;
    }

    getDodatekNajem(dodatekId: number): number {
        const dodatek = this.dodatky.filter(x => x.id == dodatekId)[0];
        if (dodatek.stavZadostId == eStavZadost.Stornovano) return 0;
        if (dodatek.cenoTvorbas.length) {
            const najemne = dodatek.cenoTvorbas
                .filter(x => x.nemovitostId != null && this.isAktualniNemovitost(x.nemovitostId))
                .map(x => new CenoTvorba(x).najemneBezDph);
            return Math.round(najemne?.reduce((a, b) => a + b) ?? 0);
        }
        return 0;
    }

    rulesVypovedniLhuta: any[] = [
        (v: string) => {
            return !v || (+v > 0 && +v < 7) || 'Délka může být 1 - 6 měsíců';
        }
    ];

    //Zjistí, zda nemovitost není odebrána.
    isAktualniNemovitost(nemovitostId: number): boolean {
        const aktualniNemovitosti = this.vazbas.filter(x => x.datumOdebrani == null).map(x => x.nemovitostId);
        return aktualniNemovitosti.includes(nemovitostId);
    }

    async loadHistoryValues() {
        this.$odata
            .function<any>('pnps', {
                key: this.editPnps?.id,
                func: 'GetPreviousValuesHistory()'
            })
            .then(res => {
                this.typPronajemOld = res?.data?.typPronajem?.oldValue ?? '';
                this.zadatelUzivatelOld = res?.data?.zadatelUzivatel?.oldValue ?? '';
                this.zpracovatelUzivatelOld = res?.data?.zpracovatelUzivatel?.oldValue ?? '';
                this.pronajimatelPartnerOld = res?.data?.pronajimatelPartner?.oldValue ?? '';
                this.najemcePartnerOld = res?.data?.najemcePartner?.oldValue ?? '';
                this.planovanyTerminPodpisuOld = res?.data?.datumPodpisuPlan?.oldValue ?? '';
                this.najemOdOld = res?.data?.najemOd?.oldValue ?? '';
                this.najemDoOld = res?.data?.najemDo?.oldValue ?? '';
                this.najemPredaniOld = res?.data?.najemPredani?.oldValue ?? '';
                this.emailFakturyOld = res?.data?.emailProFaktury?.oldValue ?? '';
                this.pojisteniOld = res?.data?.pojisteni?.oldValue ?? '';
                this.dphOld = res?.data?.dph?.oldValue ?? '';
                this.zakon340Old = res?.data?.zakon340?.oldValue ?? '';
                this.planovanyTerminPodpisuOld =
                    this.planovanyTerminPodpisuOld != '' ? this.$formatDate(this.planovanyTerminPodpisuOld, 'dd. MM. yyyy HH:mm') : '';
                this.najemOdOld = this.najemOdOld != '' ? this.$formatDate(this.najemOdOld, 'dd. MM. yyyy') : '';
                this.najemDoOld = this.najemDoOld != '' ? this.$formatDate(this.najemDoOld, 'dd. MM. yyyy') : '';
                this.najemPredaniOld = this.najemPredaniOld != '' ? this.$formatDate(this.najemPredaniOld, 'dd. MM. yyyy') : '';
                this.rucitelTextOld = res?.data?.rucitelText?.oldValue ?? '';
                this.vypovedniLhutaOld = res?.data?.vypovedniLhuta?.oldValue ?? '';
                this.nahradaOld = res?.data?.nahrada?.oldValue ?? '';
                this.administrativniPoplatekOld = res?.data?.administrativniPoplatek?.oldValue ?? '';
            });
    }

    typPronajemOld = '';
    zadatelUzivatelOld = '';
    zpracovatelUzivatelOld = '';
    pronajimatelPartnerOld = '';
    najemcePartnerOld = '';
    planovanyTerminPodpisuOld = '';
    najemOdOld = '';
    najemDoOld = '';
    najemPredaniOld = '';
    emailFakturyOld = '';
    pojisteniOld = '';
    dphOld = '';
    zakon340Old = '';
    rucitelTextOld = '';
    vypovedniLhutaOld = '';
    administrativniPoplatekOld = '';
    nahradaOld = '';
}
