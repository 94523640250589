import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

import CmsButtonComponent from '@/ui/components/inputs/button.component.vue';
import CmsTextFieldComponent from '@/ui/components/inputs/text-field.component.vue';
import CmsTextAreaComponent from '@/ui/components/inputs/text-area.component.vue';
import CmsDateFieldComponent from '@/ui/components/inputs/date-field.component.vue';
import CmsNumberFieldComponent from '@/ui/components/inputs/number-field.component.vue';
import CmsDateTimePickerComponent from '@/ui/components/inputs/datetime-picker.component.vue';
import CmsSelectFieldComponent from '@/ui/components/inputs/select-field.component.vue';
import CmsCardTitleComponent from '@/ui/components/card/card-title.component.vue';
import PartnerSelectComponent from '@/ui/components/partner/partner-select.component.vue';
import UzivatelSelectComponent from '@/ui/components/uzivatel/uzivatel-select.component.vue';
import LokalitaSelectComponent from '@/ui/components/lokalita/lokalita-select.component.vue';
import NoRightComponent from '@/ui/components/inputs/no-right.component.vue';
import { right } from './directive/right.directive';
import vuePrototype from './plugins/vue-prototype';
import { PublicClientApplication } from '@azure/msal-browser';

Vue.config.productionTip = false;

Vue.component('cms-btn', CmsButtonComponent);
Vue.component('cms-text-field', CmsTextFieldComponent);
Vue.component('cms-date-field', CmsDateFieldComponent);
Vue.component('cms-number-field', CmsNumberFieldComponent);
Vue.component('cms-datetime-picker', CmsDateTimePickerComponent);
Vue.component('cms-select-field', CmsSelectFieldComponent);
Vue.component('cms-partner-select', PartnerSelectComponent);
Vue.component('cms-uzivatel-select', UzivatelSelectComponent);
Vue.component('cms-lokalita-select', LokalitaSelectComponent);
Vue.component('cms-no-right', NoRightComponent);
Vue.component('cms-card-title', CmsCardTitleComponent);
Vue.component('cms-textarea', CmsTextAreaComponent);
Vue.directive('cms-right', right);

Vue.use(vuePrototype);

// Azure AD - nastavení CG Client
Vue.prototype.$msal = new PublicClientApplication({
    auth: {
        clientId: process.env.AZURE_CLIENT_ID ?? '97bb5cc3-ce00-4fff-9059-ca67d4005114',
        authority: process.env.AZURE_AUTHORITY ?? 'https://login.microsoftonline.com/1e6904b8-c267-4cd9-ba4e-031ff3f17aa4',
    },
    cache: {
        cacheLocation: 'localStorage',
    }
});

Vue.filter('kb', (val: number) => {
    return Math.ceil(val / 1024);
});

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
}).$mount('#app');
