






























import { TimeVersion } from '@/models';
import { Partner } from '@/models/partner';
import { AppGetter } from '@/store/app/app.getter';
import { Modules } from '@/store/modules';
import { debounce } from 'lodash';
import { mapGetters } from 'vuex';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
    computed: {
        ...mapGetters(Modules.APP, [AppGetter.CURR_TIMEVERSION])
    }
})
export default class PartnerSelectComponent extends Vue {
    @Prop() value!: number;
    @Prop({ default: true, type: Boolean }) dense!: boolean;
    @Prop({ type: Boolean }) readonly!: boolean;
    @Prop({ default: 'Partner' }) label!: string;

    @Prop({ default: () => [] }) rules!: any[];

    @Prop({ type: Boolean }) required!: boolean;

    @Prop({ type: Boolean }) cv!: boolean;
    @Prop() hintText!: string;

    @Prop() right!: string;

    CURR_TIMEVERSION!: TimeVersion;

    get hasRight(): boolean {
        return this.$right.hasRead(this.right);
    }

    get isReadonly(): boolean {
        return (
            this.readonly ||
            !this.$right.hasWrite(this.right) ||
            (this.cv && (!this.CURR_TIMEVERSION || this.CURR_TIMEVERSION.casovaHladinaTyp.id === TimeVersion.Archivacni))
        );
    }

    get ruleset(): any[] {
        const result = [...this.rules];

        if (this.required) result.push((v: any) => !!v || this.label + ' musí být vyplněn');

        return result;
    }

    isLoading = false;
    data: Partner[] = [];
    search = '';
    model: Partner | null = null;

    exceptionCounter = 0;

    async loadPartner() {
        if (this.value) {
            try {
                const res = (await this.$odata.getById<Partner>('partnerView', this.value, { select: ['id', 'nazev', 'nazevEmail'] })).data;
                if (res) {
                    this.model = res;
                    this.data = [this.model];
                }
            } catch {
                if (!this.exceptionCounter) {
                    this.$nextTick(() => this.loadPartner());
                    this.exceptionCounter++;
                    return;
                }

                this.exceptionCounter = 0;

                this.model = null;
                this.data = [];
            }
        } else {
            this.model = null;
            this.data = [];
        }
    }

    @Watch('value', { immediate: true })
    async onChangeValue() {
        await this.loadPartner();
    }

    @Watch('model')
    onModelChange(val: Partner) {
        this.$emit('input', val?.id);
    }

    @Watch('search')
    onSearchChangeDebounce = debounce(this.onSearchChange, 400);

    async onSearchChange(val: string) {
        if (!val) {
            this.data = [];
            this.model = null;
            return;
        }

        if (val === this.model?.nazevEmail) return;

        this.isLoading = true;

        //  const filter = { 'toLower(unaccentNazev)': { contains: this.$lowerUnaccent(val) } };

        try {
            /* this.data = (
                await this.$odata.getList<Partner>('partnerView', { select: ['id', 'nazev', 'nazevEmail'], filter, top: 100 })


            ).data.value;*/

            this.data = (
                await this.$odata.function<any>(
                    'partnerView',

                    { func: { PartnerSearch: { nazev: this.$lowerUnaccent(val) } } }
                )
            ).data.value;
        } catch {
            this.data = [];
        }

        this.isLoading = false;
    }
}
