






































































import { ODataResult, Lokalita, Kraj, Obec, CastObce, GridOptions } from '@/models';
import LokalitaListComponent from '@/ui/components/lokalita/lokalita-list.component.vue';
import { Component, Vue } from 'vue-property-decorator';

interface LokalitaFilter {
    cislo: string;
    cisloOd: string;
    cisloDo: string;
    nazev: string;
    krajId: number[];
    obecId: number[];
    castObceId: number[];
}

@Component({
    components: {
        'cms-lokalita-list': LokalitaListComponent
    }
})
export default class SpLokalitaListPage extends Vue {
    data: ODataResult<Lokalita> | null = null;
    krajs: ODataResult<Kraj> | null = null;
    obecs: ODataResult<Obec> | null = null;
    castObecs: ODataResult<CastObce> | null = null;

    defaultFilter = { obchodniNazev: { ne: null } };

    options: GridOptions = {
        page: 1,
        itemsPerPage: 15,
        sortBy: ['cislo'],
        sortDesc: [false],
        multiSort: true,
        mustSort: false
    };

    filter: LokalitaFilter = {
        cislo: '',
        cisloOd: '',
        cisloDo: '',
        nazev: '',
        krajId: [],
        obecId: [],
        castObceId: []
    };

    applyFilter?: LokalitaFilter;

    isLoading = false;

    async created(): Promise<void> {
        await this.fetchKrajs();
        await this.fetchObecs();
        await this.fetchCastObecs();
    }

    async fetchKrajs(): Promise<void> {
        this.krajs = (await this.$odata.getList<Kraj>('kraj', { orderBy: ['nazev'] as any })).data;
    }

    async fetchObecs(): Promise<void> {
        this.obecs = (await this.$odata.getList<Obec>('obec', { orderBy: ['nazev'] as any })).data;
    }

    async fetchCastObecs(): Promise<void> {
        this.castObecs = (await this.$odata.getList<CastObce>('castobce', { orderBy: ['nazev'] as any })).data;
    }

    async fetchData(): Promise<void> {
        this.isLoading = true;

        const skip = (this.options.page - 1) * this.options.itemsPerPage;
        const orderBy = this.options.sortBy.map((sort, index) => sort + (this.options.sortDesc[index] ? ' desc' : '')) as any;

        const filter: any[] = [];

        if (this.applyFilter) {
            if (this.applyFilter.nazev)
                filter.push({ 'tolower(obchodniNazev)': { contains: this.applyFilter.nazev.toLowerCase() } });
            if (this.applyFilter.cisloOd) filter.push({ cislo: { ge: +this.applyFilter.cisloOd } });
            if (this.applyFilter.cisloDo) filter.push({ cislo: { le: +this.applyFilter.cisloDo } });
            if (this.applyFilter.cislo) filter.push({ cislo: { eq: +this.applyFilter.cislo } });
            if (this.applyFilter.krajId.length) filter.push({ krajId: { in: this.applyFilter.krajId } });
            if (this.applyFilter.obecId.length) filter.push({ obecId: { in: this.applyFilter.obecId } });
            if (this.applyFilter.castObceId.length) filter.push({ castObceId: { in: this.applyFilter.castObceId } });
        }

        filter.push({ pocetNemovitosti: { gt: 0 } });

        this.data = (
            await this.$odata.getList<Lokalita>('lokalita', {
                top: this.options.itemsPerPage,
                count: true,
                filter: filter,
                skip,
                orderBy
            })
        ).data;

        this.isLoading = false;
    }

    async filterFormSubmit(): Promise<void> {
        this.applyFilter = { ...this.filter };
        this.options.page = 1;

        await this.fetchData();
    }

    async resetFilter(): Promise<void> {
        this.filter = {
            cislo: '',
            cisloOd: '',
            cisloDo: '',
            nazev: '',
            krajId: [],
            obecId: [],
            castObceId: []
        };

        this.applyFilter = undefined;

        this.options.page = 1;

        await this.fetchData();
    }

    optionsUpdate(opt: GridOptions): void {
        this.options = { ...opt };

        this.fetchData();
    }

    rowClick(e: any): void {
        this.$router.push({ name: 'sp.nemovitost.list', params: { lokId: e.id } });
    }
}
