import buildQuery, { QueryOptions } from 'odata-query';
import { BaseApiService } from './base-api.service';
import { ODataResult } from '@/models/odata.results';
import { AxiosResponse } from 'axios';

export class ODataService extends BaseApiService {
    getList<T>(controller: string, query?: Partial<QueryOptions<T>>): Promise<AxiosResponse<ODataResult<T>>> {
        return this.get<ODataResult<T>>('odata/' + controller + buildQuery(query));
    }

    getById<T>(controller: string, key: number | string, query?: Partial<QueryOptions<T>>): Promise<AxiosResponse<T>> {
        return this.get<T>(`odata/${controller}` + buildQuery({ ...query, key }));
    }

    create<T>(controller: string, data: any): Promise<AxiosResponse<T>> {
        return this.post<T>(`odata/${controller}`, data);
    }

    update<T>(controller: string, key: any, data: any): Promise<AxiosResponse<T>> {
        return this.patch<T>(`odata/${controller}(${key})`, data);
    }

    remove(controller: string, key: any): Promise<AxiosResponse> {
        return this.delete(`odata/${controller}(${key})`);
    }

    function<T>(controller: string, query: Partial<QueryOptions<any>>): Promise<AxiosResponse<T>> {
        return this.get<T>('odata/' + controller + buildQuery(query));
    }

    property<T>(controller: string, key: number, property: string, query?: Partial<QueryOptions<T>>): Promise<AxiosResponse<T>> {
        return this.get<T>(`odata/${controller}(${key})/${property}` + buildQuery(query));
    }

    propertyList<T>(
        controller: string,
        key: number,
        property: string,
        query?: Partial<QueryOptions<T>>
    ): Promise<AxiosResponse<ODataResult<T>>> {
        return this.get<ODataResult<T>>(`odata/${controller}(${key})/${property}` + buildQuery(query));
    }
}
