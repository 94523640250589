

































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CmsButtonComponent extends Vue {
    @Prop({ default: 'button' }) type!: string;
    @Prop() color!: any;
    @Prop({ type: Boolean }) block!: boolean;
    @Prop({ type: Boolean }) icon!: boolean;
    @Prop({ type: Boolean }) text!: boolean;
    @Prop({ type: Boolean }) plain!: boolean;
    @Prop({ type: Boolean, default: true }) outlined!: boolean;
    @Prop({ type: Boolean }) loading!: boolean;
    @Prop({ type: Boolean }) disabled!: boolean;
    @Prop() right!: string;
    @Prop() to!: string | object;
    @Prop() readonly!: boolean;

    get outlinedSetUp(): boolean {
        return this.outlined && !this.icon;
    }

    get isDisabled(): boolean {
        return !this.$right.hasWrite(this.right) || this.readonly;
    }
}
