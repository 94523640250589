import { RouteConfig } from 'vue-router';

import EmptyView from '@/ui/views/empty.view.vue';

import VybaveniListPage from '@/ui/pages/lists/vybaveni-list.page.vue';
import PodlaziListPage from '@/ui/pages/lists/podlazi-list.page.vue';
import TypPronajemListPage from '@/ui/pages/lists/typ-pronajem.page.vue';
import TypDodatekListPage from '@/ui/pages/lists/typ-dodatek.page.vue';
import BudovaListPage from '@/ui/pages/lists/budova-list.page.vue';
import SluzbyListPage from '@/ui/pages/lists/sluzby-list.page.vue';
import ReportListPage from '@/ui/pages/lists/report-list.page.vue';
import LokalitaCiselnikListPage from '@/ui/pages/lists/lokalita-ciselnik-list.page.vue';
import MojeReportyPage from '@/ui/pages/lists/moje-reporty.page.vue';
import LokalitaDetailPage from '@/ui/pages/lokalita/lokalita-detail.page.vue';

export const LISTS_ROUTES: RouteConfig[] = [
    {
        path: 'vybaveni',
        component: VybaveniListPage,
        meta: {
            breadcrump: 'Seznam vybavení'
        }
    },
    {
        path: 'podlazi',
        component: PodlaziListPage,
        meta: {
            breadcrump: 'Seznam podlaží'
        }
    },

    {
        path: 'typpronajem',
        component: TypPronajemListPage,
        meta: {
            breadcrump: 'Seznam typu pronájmů'
        }
    },
    {
        path: 'typdodatek',
        component: TypDodatekListPage,
        meta: {
            breadcrump: 'Seznam typu dodatků'
        }
    },
    {
        path: 'sluzby',
        component: SluzbyListPage,
        meta: {
            breadcrump: 'Služby'
        }
    },
    {
        path: 'budova',
        component: BudovaListPage,
        meta: {
            breadcrump: 'Seznam budov'
        }
    },
    {
        path: 'report',
        component: ReportListPage,
        meta: {
            breadcrump: 'Správa reportů'
        }
    },
    {
        path: 'myreports',
        component: MojeReportyPage,
        meta: {
            breadcrump: 'Reporty'
        }
    },
    {
        path: 'lokality',
        component: EmptyView,
        meta: {
            breadcrump: 'Číselník lokalit'
        },
        children: [
            {
                path: '',
                component: LokalitaCiselnikListPage
            },

            {
                path: ':lokId',
                component: LokalitaDetailPage,
                name: 'lokalita.detail',
                meta: {
                    breadcrump: 'Detail lokality',
                }
            }
        ]
    }
];
