


































































































import { formatDate } from '@/helpers';
import { ODataResult, Podlazi } from '@/models';
import { NemovitostCv } from '@/models/nemovitost';
import { ObchodniPripad } from '@/models/obp';
import { Component, Vue, Watch } from 'vue-property-decorator';

@Component
export default class SpNemovitostDetailPage extends Vue {
    data: ODataResult<ObchodniPripad> | null = null;

    isLoading = false;

    podlazis: Podlazi[] = [];

    nemovitostCv?: NemovitostCv | null = null;

    @Watch('$route.params.nemCvId', { immediate: true })
    async onChangeParams() {
        await this.fetchNemovitost();
        await this.fetchData();
    }

    formatDate(item: string) {
        return formatDate(item, 'dd. MM. yyyy');
    }

    async fetchNemovitost(): Promise<void> {
        this.nemovitostCv = (
            await this.$odata.getById<NemovitostCv>('nemovitostCv', +this.$route.params.nemCvId, {
                expand: { nemovitost: { expand: { typNemovitost: {}, budova: {}, podlazis: {} } }, cenoTvorba: {} }
            })
        ).data;

        this.podlazis = this.nemovitostCv.nemovitost.podlazis;
    }

    async fetchData(): Promise<void> {
        this.isLoading = true;

        this.data = (
            await this.$odata.getList<ObchodniPripad>('obchodnipripad', {
                filter: { nemovitosts: { any: { nemovitostCvs: { any: { id: { eq: +this.$route.params.nemCvId } } } } } },
                expand: { partner: {}, stavPronajem: {}, prodejceUzivatel: {} }
            })
        ).data;

        this.isLoading = false;
    }

    getItemClass(e: ObchodniPripad) {
        const stav = e.stavPronajemId;
        const r = stav === 2 ? 'stav-prodeje-nur' : stav === 3 ? 'stav-prodeje-zur' : stav === 4 ? 'stav-prodeje-prodano' : '';
        return r;
    }

    options = {
        page: 1,
        itemsPerPage: 15,
        sortBy: ['stavPronajemId'],
        sortDesc: [true],
        multiSort: true,
        mustSort: false
    };

    headers = [
        { text: 'Partner', value: 'partner.nazev' },
        { text: 'Draft', value: 'isDraft' },
        { text: 'Stav', value: 'stavPronajemId' },
        { text: 'Číslo', value: 'cisloObp' },
        { text: 'Nájem od', value: 'najemOd' },
        { text: 'Nájem do', value: 'najemDo' },
        { text: 'Prodejce', value: 'prodejceUzivatel.fullName', sortable: false }
    ];

    rowClick(e: ObchodniPripad) {
        this.$router.push({ name: 'obp.detail', params: { obpId: String(e.id) } });
    }
}
