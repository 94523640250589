import { Module } from 'vuex';
import { authGetters } from './auth.getter';
import { authMutations } from './auth.mutation';
import { authActions } from './auth.action';
import { AuthState } from './auth.state';

const authModule: Module<AuthState, any> = {
    namespaced: true,
    state: {
        expiration: localStorage.getItem('cms_expi') ? new Date(localStorage.getItem('cms_expi') || '') : null,
        token: localStorage.getItem('cms_token'),
        type: localStorage.getItem('cms_type'),
        user: null,
        prava: [],
        ad: localStorage.getItem('cms_ad') == 'true'
    },
    getters: authGetters,
    mutations: authMutations,
    actions: authActions
};

export default authModule;
