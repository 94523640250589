


























































































































import { Component, Mixins, Ref, Vue, Watch } from 'vue-property-decorator';
import PnpsCelkoveCenyTab from '@/ui/components/pnps/detail-tabs/pnps-celkoveceny.vue';
import PnpsCenoTvorbaTab from '@/ui/components/pnps/detail-tabs/pnps-cenotvorba.tab.vue';
import PnpsDetailTab from '@/ui/components/pnps/detail-tabs/pnps-detail.tab.vue';
import PnpsHistoryTab from '@/ui/components/pnps/detail-tabs/pnps-history.tab.vue';
import PnpsNemovitostTab from '@/ui/components/pnps/detail-tabs/pnps-nemovitost.tab.vue';
import PnpsSpravaTab from '@/ui/components/pnps/detail-tabs/pnps-sprava.tab.vue';
import NotSavedComponent from '@/ui/components/not-saved.component';
import { ModAppAction } from '@/store/app/app.action';
import { PnpsNemovitostVazba, PnpsUpdate, PozadavekNaPripravuSmlouvy } from '@/models/pnps';
import { CenoTvorba, NemovitostCv } from '@/models/nemovitost';
import { QueryOptions } from 'odata-query';
import { eStavZadost } from '@/models/stav-zadost';
import { eTypPronajem } from '@/models/typ-pronajem';
import { VForm } from '@/models/vue-components';
import R from '@/models/resources';
import { ModDraftMutation } from '@/store/draft/draft.mutation';
import { handleException } from '@/helpers';
import { sumBy } from 'lodash';
import { RightService } from '@/services/right.service';
import store from '@/store';

@Component({
    components: {
        'cms-pnps-detail-tab': PnpsDetailTab,
        'cms-pnps-nemovitost-tab': PnpsNemovitostTab,
        'cms-pnps-cenotvorba-tab': PnpsCenoTvorbaTab,
        'cms-pnps-history-tab': PnpsHistoryTab,
        'cms-pnps-sprava-tab': PnpsSpravaTab,
        'cms-celkove-ceny-tab': PnpsCelkoveCenyTab
    }
})
export default class PodpozadavekPnpsDetailPage extends Mixins(Vue, NotSavedComponent) {
    @Ref('form') form!: VForm;

    refresher = 0;
    activeTab = 0;
    isReadOnly = true;

    isSaving = false;
    loaded = false;
    eStavZadost = eStavZadost;
    dialogStorno = false;

    pnps: PozadavekNaPripravuSmlouvy | null = null;
    nemovitosts: NemovitostCv[] = [];
    nemovitostsOdebratId: number[] = [];
    nemovitostVazbas: PnpsNemovitostVazba[] = [];
    cenoTvorbas: CenoTvorba[] = [];
    validovatRequiredPole = true; //Vypíná se při storno požadavku

    @Watch('pnps', { deep: true })
    onChangePnps() {
        this.isChanged = true;
    }

    @Watch('$route.params.podPnpsId', { immediate: true })
    async onChangePnpsId() {
        this.loaded = false;
        await this.fetchPnps();
    }

    @Watch('pnps.typPronajemId')
    onChangeTypPronajem() {
        this.cenoTvorbas.forEach(c => {
            c.setCenyPnps(this.pnps?.typPronajemId as eTypPronajem, this.pnps?.dphId === 1);
        });
    }

    @Watch('pnps.dphId')
    onChangeDph() {
        this.cenoTvorbas.forEach(c => {
            c.setCenyPnps(this.pnps?.typPronajemId as eTypPronajem, this.pnps?.dphId === 1);
        });
    }

    async mounted(): Promise<void> {
        this.isReadOnly = !new RightService().hasWrite(this.$route.meta.right);
    }

    get sumaCena() {
        if (
            this.pnps?.typPronajemId == null ||
            this.cenoTvorbas.length == 0 ||
            this.cenoTvorbas.every(c => c.cenaNajemnePnpsCelkemSDph == null)
        )
            return null;
        return sumBy(this.cenoTvorbas, v => v.cenaNajemnePnpsCelkemSDph ?? 0);
    }

    async fetchPnps() {
        this.loaded = false;
        const query: Partial<QueryOptions<PozadavekNaPripravuSmlouvy>> = {
            expand: {
                pnpsSluzbys: {},
                komentare: {
                    expand: {
                        uzivatel: {}
                    }
                },

                soubors: {},
                parentPnps: {},
                pnpsSpravaSoubors: {
                    expand: { soubor: {} }
                },
                cenoTvorbas: { expand: { nemovitost: {} } },
                obchodniPripad: { select: ['id', 'cisloObp', 'lokalitaId'] },
                stavZadost: {
                    select: ['nazev']
                },
                stavPronajem: {}
            }
        };

        this.pnps = null; //Kvůli zaznamenání změny - zjistit jak řešit líp
        this.pnps = (await this.$odata.getById<PozadavekNaPripravuSmlouvy>('pnps', +this.$route.params.podPnpsId, query)).data;

        const vazbas = (
            await this.$odata.getList<PnpsNemovitostVazba>('pnpsNemovitostVazba', {
                filter: { pnpsId: { eq: this.pnps.parentPnpsId == null ? this.pnps.id : this.pnps.parentPnpsId } },
                expand: { pnpsPridal: {}, nemovitost: { expand: { budova: {}, nemovitostCvs: {}, podlazis: {}, typNemovitost: {} } } }
            })
        ).data.value;
        this.pnps.sluzbyPausalni = [];
        this.pnps.sluzbyZalohove = [];
        this.pnps.pnpsSluzbys.forEach(v =>
            v.typ == 0 ? this.pnps?.sluzbyPausalni.push(v.sluzbyId) : this.pnps?.sluzbyZalohove.push(v.sluzbyId)
        );
        this.pnps.spravaSoubors = this.pnps.pnpsSpravaSoubors.map(i => i.soubor);
        this.nemovitostVazbas = vazbas;

        //Nemovitosti které nejsou odebrány a přidávající je tento dodatek
        const aktualniNemovitostiIds = this.nemovitostVazbas
            .filter(
                x =>
                    x.datumOdebrani == null &&
                    x.pnpsPridalId == (this.pnps?.id ?? -1) &&
                    x.pnpsPridal?.stavZadostId != eStavZadost.Stornovano
            )
            .map(x => x.nemovitostId);

        this.cenoTvorbas = this.pnps.cenoTvorbas
            .filter(x => x.nemovitostId && aktualniNemovitostiIds.includes(x.nemovitostId))
            .map(m => new CenoTvorba(m));
        this.cenoTvorbas.forEach(x => (x.cisloSmlouvy = this.pnps?.cisloSmlouvyText ?? ' - '));
        this.cenoTvorbas.forEach(x => x.setCenyPnps(this.pnps?.typPronajemId as eTypPronajem, this.pnps?.dphId === 1));

        if (vazbas.length) {
            this.nemovitosts = (
                await this.$odata.getList<NemovitostCv>('nemovitostCv', {
                    filter: { nemovitostId: { in: vazbas.map(m => m.nemovitostId) } },
                    expand: { nemovitost: { expand: { budova: {}, typNemovitost: {}, podlazis: {} } }, cenoTvorba: {} },
                    orderBy: ['katalogoveCislo']
                })
            ).data.value;
        } else {
            this.nemovitosts = [];
        }

        this.$nextTick(() => {
            this.isChanged = false;
        });

        this.loaded = true;
    }

    ulozit(afterSave: () => void): void {
        if (this.validovatRequiredPole && !this.form.validate()) return;

        if (this.pnps) {
            this.isSaving = true;

            const res: PnpsUpdate = {
                id: this.pnps.id,
                typPronajemId: this.pnps.typPronajemId,
                zadatelUzivatelId: this.pnps.zadatelUzivatelId,
                zpracovatelUzivatelId: this.pnps.zpracovatelUzivatelId,
                najemOd: this.pnps.najemOd,
                najemDo: this.pnps.najemDo,
                najemPredani: this.pnps.najemPredani,
                datumPodpisuPlan: this.pnps.datumPodpisuPlan,
                emailProFaktury: this.pnps.emailProFaktury,
                dphId: this.pnps.dphId,
                zakon340Id: this.pnps.zakon340Id,
                pojisteniId: this.pnps.pojisteniId,
                pronajimatelPartnerId: this.pnps.pronajimatelPartnerId,
                najemcePartnerId: this.pnps.najemcePartnerId,
                zadano: this.pnps.zadano,
                cenoTvorbas: this.cenoTvorbas,
                poznamka: this.pnps.poznamka,
                soubors: this.pnps.soubors,
                spravaSoubors: this.pnps.spravaSoubors,
                nemovitostIds: this.nemovitosts.filter(m => !this.nemovitostsOdebratId.includes(m.nemovitostId)).map(m => m.nemovitostId),
                komentare: this.pnps.komentare.filter(f => f.tmpId),
                cisloSmlouvyText: this.pnps.cisloSmlouvyText,
                sluzbyZalohove: this.pnps.sluzbyZalohove,
                sluzbyPausalni: this.pnps.sluzbyPausalni,
                rucitel: this.pnps.rucitel,
                rucitelText: this.pnps.rucitelText,
                vypovedniLhuta: this.pnps.vypovedniLhuta,
                typDodatekId: this.pnps.typDodatekId,
                nahrada: this.pnps.nahrada,
                administrativniPoplatek: this.pnps.administrativniPoplatek,
                pnpsNemovovitostVazbas: this.nemovitostVazbas //U podpozadavku vazby nejsou ale kvůli updatu datumů ve správě jsou posílány všechny z hlavního požadavku
            };

            this.$odata
                .update('pnps', this.pnps?.id, res)
                .then(() => {
                    this.isSaving = false;
                    this.isChanged = false;

                    if (afterSave != undefined) {
                        afterSave();
                    } else {
                        this.$snackbar.success(R.Ulozeno);
                        this.$nextTick(() => {
                            this.fetchPnps();
                        });
                    }
                })
                .catch(i => {
                    this.isSaving = false;
                    this.$snackbar.error(i.response.data.value);
                });
        }
    }

    nemovitostRemove(e: number) {
        const nem = this.nemovitosts.find(f => f.id == e);
        //this.nemovitosts = this.nemovitosts.filter(f => f.id != e);
        this.nemovitostsOdebratId.push(e);
        this.cenoTvorbas = this.cenoTvorbas.filter(
            f => (f.nemovitostId && f.nemovitostId != nem?.nemovitostId) || (f.nemovitostCvId && f.nemovitostCvId != e)
        );

        this.isChanged = true;
    }

    spravovatNemovitosti() {
        this.activeTab = 0;

        this.ulozit(async () => {
            await this.$store.dispatch(ModAppAction.INIT_PUBLISHED.toString());

            const items = this.nemovitosts.filter(n =>
                this.nemovitostVazbas
                    .filter(v => v.datumOdebrani == null)
                    .map(i => i.nemovitostId)
                    .includes(n.nemovitostId)
            );

            this.$store.commit(ModDraftMutation.SET_PNPS.toString(), { pnps: this.pnps });
            this.$store.commit(ModDraftMutation.ADD_NEMOVITOST.toString(), { nemovitosts: [...items] });

            this.$router.push({
                name: 'nemovitost.list',
                query: { filter: JSON.stringify({ lokalitaId: this.pnps?.obchodniPripad.lokalitaId }) }
            });
        });
    }

    setStav(stavId: number): void {
        if (this.pnps?.stavZadostId == stavId) this.$snackbar.info('Podpožadavek je v požadovaném stavu');
        else {
            this.ulozit(() => {
                this.$odata
                    .function<any>('pnps', { key: this.pnps?.id, func: { setstav: { stavId } } })
                    .then(a => {
                        if (this.pnps) {
                            this.pnps.cisloSmlouvyText = a.data.cisloSmlouvy;
                            this.pnps.stavZadost.nazev = a.data.stavZadostNazev;
                            this.pnps.stavZadost.id = a.data.stavZadostId;
                            this.pnps.stavZadostId = a.data.stavZadostId;

                            if (stavId == eStavZadost.Podepsano) {
                                this.pnps.stavPronajemId = 4;
                                this.pnps.stavPronajem.zkratka = 'Pronajmuto';
                            }

                            this.$snackbar.success('Nastaven stav ' + this.pnps.stavZadost.nazev);
                        }
                    })
                    .catch(error => {
                        handleException(error);
                    });
            });
        }
    }

    setStavOdeslat() {
        if (this.pnps?.stavZadostId == eStavZadost.Stornovano) {
            this.$snackbar.warn('Požadavek je stornován, nelze měnit stavy');
        } else if (this.pnps?.stavZadostId == eStavZadost.NovyPozadavek) {
            this.$snackbar.info('Požadavek je v požadovaném stavu');
        } else {
            this.ulozit(() => {
                this.$odata
                    .function<any>('pnps', {
                        key: this.pnps?.id,
                        func: { setStavOdeslat: { ciselnaRadaId: null } }
                    })
                    .then(a => {
                        if (this.pnps) {
                            this.pnps.cisloSmlouvyText = a.data.cisloSmlouvy;
                            this.pnps.stavZadost.nazev = a.data.stavZadostNazev;
                            this.pnps.stavZadost.id = a.data.stavZadostId;
                            this.pnps.stavZadostId = a.data.stavZadostId;
                            this.$snackbar.success('Nastaven stav ' + this.pnps.stavZadost.nazev);
                        }
                    })
                    .catch(error => {
                        handleException(error);
                    });
            });
        }
    }

    zur() {
        this.ulozit(() => {
            this.$odata
                .function<any>('pnps', { key: this.pnps?.id, func: 'Zur()' })
                .then(a => {
                    if (this.pnps) {
                        this.pnps.stavPronajem = a.data;
                        this.pnps.stavPronajemId = 3;
                        this.$snackbar.success('Nastavena Závazná ústní rezervace');
                    }
                })
                .catch(error => {
                    handleException(error);
                });
        });
    }

    duvodStorna = '';
    stornoDialog(): void {
        if (this.pnps?.stavZadostId == eStavZadost.Stornovano) this.$snackbar.info('Požadavek je stornován');
        else {
            this.dialogStorno = true;
        }
    }

    storno() {
        if (this.duvodStorna.length < 10) {
            this.$snackbar.error('Důvod storna musí být dlouhý alespoň 10 znaků');
            return;
        }
        this.dialogStorno = false;

        this.validovatRequiredPole = false;
        this.ulozit(() => {
            this.$odata
                .function<any>('pnps', { key: this.pnps?.id, func: { storno: { duvod: this.duvodStorna } } })
                .then(a => {
                    if (this.pnps) {
                        this.pnps.cisloSmlouvyText = a.data.cisloSmlouvy;
                        this.pnps.stavZadost.nazev = a.data.stavZadostNazev;
                        this.pnps.stavZadost.id = a.data.stavZadostId;
                        this.pnps.stavZadostId = eStavZadost.Stornovano;
                        this.$snackbar.success('Požadavek byl stornován');
                    }
                })
                .catch(error => {
                    handleException(error);
                })
                .finally(() => (this.validovatRequiredPole = true));
        });
    }

    updatePnpsNemovitostVazba(vazba: PnpsNemovitostVazba) {
        const vazbaEdit = this.nemovitostVazbas.filter(x => x.id === vazba.id)[0];
        vazbaEdit.datumPrevzeti = vazba.datumPrevzeti;
        vazbaEdit.datumPredani = vazba.datumPredani;
    }
}
