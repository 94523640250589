















































import { GridOptions, ODataResult } from '@/models';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class NemovitostListComponent extends Vue {
    @Prop() value!: any[];

    @Prop({ required: true }) data!: ODataResult<any>;
    @Prop({ required: true }) isLoading!: boolean;
    @Prop({ required: true }) opt!: GridOptions;

    @Prop({ required: false }) headers?: any[];
    @Prop({ default: false }) showSelect!: boolean;

    get cols(): any[] {
        return this.headers || this.defaultHeaders;
    }

    getItemClass(item: any): string {
        const stav = item.stavPronajemDnesId;
        const r =
            stav == 1 && !!item.najemOdBudouci
                ? 'stav-prodeje-budouci-pronajem'
                : stav === 2
                ? 'stav-prodeje-nur'
                : stav === 3
                ? 'stav-prodeje-zur'
                : stav === 4
                ? 'stav-prodeje-prodano'
                : item.nepronajmutelne
                ? 'stav-prodeje-nepronajmutelne'
                : '';
        return r;
    }

    defaultHeaders = [
        /*  { text: 'Id', value: 'id', visible: false },*/
        { text: 'Katalogové číslo', value: 'katalogoveCislo' },
        { text: 'Lokalita', value: 'lokalita', sortable: false },
        { text: 'Budova', value: 'budova' },
        { text: 'Podlaží', value: 'podlazi' },
        { text: 'Stav', value: 'stavPronajemDnes' },
        { text: 'Typ nemovitosti', value: 'typNemovitostiNazev' },
        { text: 'Nájem od', value: 'najemOd' },
        { text: 'Nájem do', value: 'najemDo' },

        { text: 'Garance nájmu', value: 'garanceNajemDo' },
        { text: 'Nájem od (příští)', value: 'najemOdBudouci' },
        { text: 'Označení (interní)', value: 'oznaceni' },
        /*    { text: 'Nájemné', value: 'maxNajem' },
        { text: 'Cena služeb', value: 'cenaSluzebBezDph' },*/

        { text: 'Číslo smlouvy', value: 'cisloSmlouvyText' },
        { text: 'Nájemce', value: 'partner' },

        { text: 'Plocha', value: 'plocha' }
    ];
}
