import store from '@/store';
import { ModAuthGetter } from '@/store/auth/auth.getter';
import { ModAuthAction } from '@/store/auth/auth.types';
import { ModAppGetter } from '@/store/app/app.getter';
import { ModAppMutation } from '@/store/app/app.mutation';
import axios from 'axios';
import { NavigationGuard, Route } from 'vue-router';

export const isAuth: NavigationGuard = async (to: Route) => {
    if (!store.getters[ModAppGetter.APIURL]) {
        try {
            const res = await axios.get<any>(process.env.NODE_ENV == 'production' ? '/api/config' : 'https://localhost:6001/api/config');
            store.commit(ModAppMutation.SET_CONFIG.toString(), {
                apiUrl: res.data.apiUrl,
                fileUrl: res.data.fileUrl
            });
        } catch {
            store.commit(ModAppMutation.SET_CONFIG.toString(), {
                apiUrl: 'http://localhost:5000',
                fileUrl: 'http://localhost:1238'
            });
        }
    }

    if (to.matched.some(m => m.meta.authRequired)) {
        if (!store.getters[ModAuthGetter.IS_LOGGED_IN]) {
            await store.dispatch(ModAuthAction.RE_LOGIN.toString());
        }
    }
};
