












import CmsTextFieldComponent from '@/ui/components/inputs/text-field.component.vue';
import CmsListComponent from '@/ui/components/list/list.component.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
        'cms-list': CmsListComponent,
        'cms-text-field': CmsTextFieldComponent
    }
})
export default class PodlaziListPage extends Vue {
    item = {
        id: '',
        nazev: ''
    };

    headers = [{ text: 'Název', value: 'nazev' }];
}
