import Vue from 'vue';
import Vuex from 'vuex';

import { Modules } from './modules';

import appModule from './app/app.module';
import authModule from './auth/auth.module';
import draftModule from './draft/draft.module';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        [Modules.AUTH]: authModule,
        [Modules.APP]: appModule,
        [Modules.DRAFT]: draftModule
    }
});

export default store;
