import { Module } from 'vuex';
import { draftGetters } from './draft.getter';
import { obpMutations } from './draft.mutation';
import { DraftState } from './draft.state';

const draftModule: Module<DraftState, any> = {
    namespaced: true,
    state: {
        pnps: null,
        pnpsDraft: false,
        nemovitosts: []
    },
    getters: draftGetters,
    mutations: obpMutations
};

export default draftModule;
