import { BaseApiService } from './base-api.service';
import buildQuery, { QueryOptions } from 'odata-query';
import { TimeVersion } from '@/models/timeversion';
import { ODataResult } from '@/models/odata.results';

export class TimeVersionService extends BaseApiService {
    query: Partial<QueryOptions<TimeVersion>> = {
        select: ['id', 'nazev', 'datum', 'casovaHladinaTyp', 'komerceNahrany'],
        expand: {
            casovaHladinaTyp: { select: ['id', 'nazev'] }
        }
    };

    getById(id: number): Promise<TimeVersion> {
        return this.get<TimeVersion>(`odata/timeversion(${id})` + buildQuery(this.query)).then(d => new TimeVersion(d.data));
    }

    getPublished(): Promise<TimeVersion> {
        return this.get<TimeVersion>('odata/timeversion/getpublikacni()' + buildQuery(this.query)).then(
            d => new TimeVersion(d.data)
        );
    }

    getPrepared(): Promise<TimeVersion> {
        return this.get<TimeVersion>('odata/timeversion/getpripravna()' + buildQuery(this.query)).then(
            f => new TimeVersion(f.data)
        );
    }

    migration(): Promise<any> {
        return this.get<any>('odata/timeversion/migrovat()');
    }

    async getVersions(): Promise<TimeVersion[]> {
        const orderBy = ['casovaHladinaTypId', 'datum'] as any;

        return this.get<ODataResult<TimeVersion>>(
            'odata/timeversion' +
                buildQuery({ ...this.query, orderBy, filter: { casovaHladinaTypId: { ne: 3 }, komerceNahrany: { eq: 1 } } })
        ).then(d => d.data.value.map(m => new TimeVersion(m)));
    }

    async getVersionsArchive(): Promise<TimeVersion[]> {
        return this.get<ODataResult<TimeVersion>>(
            'odata/timeversion' +
                buildQuery({
                    ...this.query,
                    orderBy: 'datum desc',
                    filter: { casovaHladinaTypId: { eq: 3 }, komerceNahrany: { eq: 1 } },
                    top: 2
                })
        ).then(d => d.data.value.map(m => new TimeVersion(m)));
    }

    async getNewVersios(): Promise<TimeVersion[]> {
        const orderBy = ['casovaHladinaTypId', 'datum'] as any;

        return this.get<ODataResult<TimeVersion>>(
            'odata/timeversion' +
                buildQuery({ ...this.query, orderBy, filter: { casovaHladinaTypId: { ne: 3 }, komerceNahrany: { eq: 0 } } })
        ).then(d => d.data.value.map(m => new TimeVersion(m)));
    }
}
