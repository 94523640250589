











import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class NoRightComponent extends Vue {
    @Prop() label!: string;

    value = 'Nemáte oprávnění';
}
