






























import { TimeVersion, User } from '@/models';
import { AppGetter } from '@/store/app/app.getter';
import { Modules } from '@/store/modules';
import { debounce } from 'lodash';
import { mapGetters } from 'vuex';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ODataResult } from '@/models/odata.results';
import { ePrava } from '@/models/user-right';

@Component({
    computed: {
        ...mapGetters(Modules.APP, [AppGetter.CURR_TIMEVERSION])
    }
})
export default class UzivatelSelectComponent extends Vue {
    @Prop() value!: number;
    @Prop({ default: true, type: Boolean }) dense!: boolean;
    @Prop({ type: Boolean }) readonly!: boolean;
    @Prop({ default: 'Uživatel' }) label!: string;

    @Prop({ type: Boolean }) required!: boolean;
    @Prop({ default: () => [] }) rules!: any[];
    @Prop() props!: object;

    @Prop({ type: Boolean }) cv!: boolean;
    @Prop() hintText!: string;
    @Prop({}) prava!: number[];

    @Prop() right!: string;

    CURR_TIMEVERSION!: TimeVersion;

    get hasRight(): boolean {
        return this.$right.hasRead(this.right);
    }

    get isReadonly(): boolean {
        return (
            this.readonly ||
            !this.$right.hasWrite(this.right) ||
            (this.cv && (!this.CURR_TIMEVERSION || this.CURR_TIMEVERSION.casovaHladinaTyp.id === TimeVersion.Archivacni))
        );
    }

    get ruleset(): any[] {
        const result = [...this.rules];
        if (this.required) result.push((v: any) => !!v || this.label + ' musí být vyplněn');
        return result;
    }

    isLoading = false;
    data: User[] = [];
    search = '';
    model: User | null = null;

    exceptionCounter = 0;

    async loadUser() {
        if (this.value) {
            try {
                const res = (await this.$odata.getById<User>('uzivatelView', this.value, { select: ['id', 'fullName'] })).data;
                if (res) {
                    this.model = res;
                    this.data = [this.model];
                }
            } catch {
                if (!this.exceptionCounter) {
                    this.$nextTick(() => this.loadUser());
                    this.exceptionCounter++;
                    return;
                }

                this.exceptionCounter = 0;

                this.model = null;
                this.data = [];
            }
        } else {
            this.model = null;
            this.data = [];
        }
    }

    @Watch('value', { immediate: true })
    onChangeValue() {
        this.loadUser();
    }

    @Watch('model')
    onModelChange(val: User) {
        this.$emit('input', val?.id);
    }

    @Watch('search')
    onSearchChangeDebounce = debounce(this.onSearchChange, 400);

    async onSearchChange(val: string) {
        if (!val) {
            this.data = [];
            this.model = null;
            return;
        }

        if (val === this.model?.fullName) return;

        this.isLoading = true;

        const filter = {
            or: {
                "toLower(concat(concat(unaccentLastName, ' '), unaccentFirstName))": { contains: this.$lowerUnaccent(val) }
            }
        };

        try {
            if (!this.prava) {
                this.data = (
                    await this.$odata.getList<User>('uzivatelView', {
                        filter,
                        orderBy: ['fullName'],
                        select: ['id', 'fullName'],
                        top: 50
                    })
                ).data.value;
            } else {
                this.data = (
                    await this.$odata.function<any>('uzivatelView', {
                        filter,
                        orderBy: ['fullName'],
                        select: ['id', 'fullName'],
                        func: { GetUzivatelSPravy: { prava: this.prava } }
                    })
                ).data.value;
            }
        } catch {
            this.data = [];
        }

        this.isLoading = false;
    }
}
