















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { Modules } from '@/store/modules';
import { AppGetter } from '@/store/app/app.getter';
import { TimeVersion } from '@/models';

@Component({
    computed: {
        ...mapGetters(Modules.APP, [AppGetter.CURR_TIMEVERSION])
    }
})
export default class CmsDateFieldComponent extends Vue {
    @Prop() value!: string;
    @Prop() label!: string;

    @Prop({ type: Boolean }) readonly!: boolean;
    @Prop({ default: true, type: Boolean }) dense!: boolean;

    @Prop({ default: () => [] }) rules!: any[];

    @Prop({ type: Boolean }) required!: boolean;

    @Prop({ type: Boolean }) cv!: boolean;

    @Prop() right!: string;
    @Prop() hintText!: string;

    CURR_TIMEVERSION!: TimeVersion;

    dateHumanFormat = '';

    get hasRight(): boolean {
        return this.$right.hasRead(this.right);
    }

    get isReadonly(): boolean {
        return (
            this.readonly ||
            !this.$right.hasWrite(this.right) ||
            (this.cv && (!this.CURR_TIMEVERSION || this.CURR_TIMEVERSION.casovaHladinaTyp.id === TimeVersion.Archivacni))
        );
    }

    get ruleset(): any[] {
        const result = [...this.rules];

        if (this.required) result.push((v: any) => !!v || this.label + ' musí být vyplněn');

        result.push((v: any) => this.validaFormat(v) || 'Nesprávný formát datumu');

        return result;
    }

    get computerFormat(): string | null {
        return this.value ? this.$formatDate(this.value, 'yyyy-MM-dd') : null;
    }

    get humanFormat(): string | null {
        return this.value ? this.$formatDate(this.value) : null;
    }

    onChangeText(e: string): void {
        this.dateHumanFormat = e;
    }

    onClearClicked(): void {
        this.$emit('input', null);
    }

    onInputChange(e: any): void {
        this.$emit('input', e ? new Date(e) : null);
        this.modal = false;
    }

    validaFormat(v: string) {
        if (!v) return true;

        try {
            const [day, month, year] = v.split('.');
            return !isNaN(new Date(`${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`).getTime());
        } catch {
            return false;
        }
    }

    parseDate(date: string) {
        if (!date) {
            return;
        }

        if (this.validaFormat(date)) {
            const [day, month, year] = date.split('.');
            this.$emit('input', new Date(`${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`));
        }
    }

    modal = false;
}
