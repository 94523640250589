

















































import { PartnerOsoba } from '@/models/partner';
import { VForm } from '@/models/vue-components';
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import PartnerOsobaComponent from '../partner-osoba.component.vue';

@Component({
    components: {
        'cms-partner-osoba': PartnerOsobaComponent
    }
})
export default class PartnerOsobyTab extends Vue {
    @Prop() value!: PartnerOsoba[];
    @Ref('osobaForm') osobaForm!: VForm;

    editDialog = false;

    editOsoba = {};

    headers = [
        { text: 'Titul před.', value: 'titulPred' },
        { text: 'Příjmení', value: 'prijmeni' },
        { text: 'Jméno', value: 'jmeno' },
        { text: 'Titul za', value: 'titulZa' },
        { text: 'Email', value: 'email' },
        { text: 'Telefon', value: 'tel' }
    ];

    pridat() {
        this.openEdit();
    }

    openEdit(e?: PartnerOsoba): void {
        this.editDialog = true;

        this.$nextTick(() => {
            this.editOsoba = { ...e };
            this.osobaForm.resetValidation();
        });
    }

    save(): void {
        if (!this.osobaForm.validate()) return;

        const osoba = (this.editOsoba as any) as { id: number; tmpId: number };

        let result = [];

        if (osoba.id) {
            result = [...this.value.filter(f => f.id !== osoba.id), { ...osoba }];
        } else if (osoba.tmpId) {
            result = [...this.value.filter(f => (f as any).tmpId !== osoba.tmpId), { ...osoba }];
        } else {
            result = [...this.value, { ...osoba, tmpId: new Date().getTime() }];
        }

        this.$emit('input', result);

        this.osobaForm.resetValidation();

        this.editOsoba = {};

        this.$nextTick(() => {
            this.editDialog = false;
        });
    }

    close() {
        this.editOsoba = {};
        this.$nextTick(() => {
            this.editDialog = false;
        });
    }
}
