






























import { Component, Ref, Vue, Watch } from 'vue-property-decorator';

import { mapGetters } from 'vuex';

import { TimeVersion } from '@/models';

import { Modules } from '@/store/modules';
import { AppGetter } from '@/store/app/app.getter';
import { AuthGetter } from '@/store/auth/auth.getter';
import { ModAppAction } from '@/store/app/app.action';
import { ModAuthAction } from '@/store/auth/auth.types';
import { DraftGetter } from '@/store/draft/draft.getter';

import TopbarComponent from '@/ui/components/topbar/topbar.component.vue';
import SideMenuComponent from '@/ui/components/side-menu/side-menu.component.vue';
import CmsSnackbarComponent from '@/ui/components/snackbar/snackbar.component.vue';
import CmsConfirmComponent from '@/ui/components/confirm/confirm.component.vue';
import DraftComponent from '@/ui/components/draft/draft.component.vue';

@Component({
    computed: {
        ...mapGetters(Modules.APP, [AppGetter.CURR_TIMEVERSION]),
        ...mapGetters(Modules.DRAFT, [DraftGetter.SIDE_EDIT]),
        ...mapGetters(Modules.AUTH, [AuthGetter.USER_RIGHT, AuthGetter.IS_LOGGED_IN])
    },
    components: {
        'cms-topbar': TopbarComponent,
        'cms-side-menu': SideMenuComponent,
        'cms-draft': DraftComponent,
        'cms-snackbar': CmsSnackbarComponent,
        'cms-confirm': CmsConfirmComponent
    }
})
export default class LayoutView extends Vue {
    @Ref('confirm') confirm!: CmsConfirmComponent;

    [DraftGetter.SIDE_EDIT]!: boolean;

    CURR_TIMEVERSION!: TimeVersion;
    USER_RIGHT!: any[];

    navDrawer = false;
    draftDrawer = false;

    open = true;

    isMiniVariant = false;

    get isLoading(): boolean {
        return !(this.CURR_TIMEVERSION && this.USER_RIGHT.length);
    }

    get temporary(): boolean {
        return this.$vuetify.breakpoint.mdAndDown;
    }

    mounted() {
        this.$root.$confirm = this.confirm.open;
    }

    @Watch('IS_LOGGED_IN', { immediate: true })
    onChangeLoggedIn(val: boolean) {
        if (val) {
            this.$store.dispatch(ModAppAction.INIT_TIMEVERSION.toString());
            this.$store.dispatch(ModAppAction.INIT_TIMEVERSIONS.toString());
            this.$store.dispatch(ModAuthAction.INIT_USER.toString());
            this.$store.dispatch(ModAuthAction.INIT_RIGHT.toString());
        }
    }

    @Watch('temporary')
    onChangeTemporary() {
        this.$nextTick(() => {
            this.draftDrawer = !this.temporary && this.SIDE_EDIT;
        });
    }

    @Watch('SIDE_EDIT')
    onChangeSideEdit() {
        this.$nextTick(() => {
            this.draftDrawer = this.SIDE_EDIT;
        });
    }

    toggle(): void {
        this.navDrawer = !this.navDrawer;
        this.open = !this.open;
    }

    sideEditToggle(): void {
        this.draftDrawer = !this.draftDrawer;
    }
}
