import { RouteConfig } from 'vue-router';

import PartnerListPage from '@/ui/pages/partner/partner-list.page.vue';
import PartnerDetailPage from '@/ui/pages/partner/partner-detail.page.vue';
import PartnerObcanNewPage from '@/ui/pages/partner/partner-obcan-new.page.vue';
import PartnerFirmaNewPage from '@/ui/pages/partner/partner-firma-new.page.vue';

export const PARTNER_ROUTES: RouteConfig[] = [
    {
        path: '',
        component: PartnerListPage,
        name: 'partner.list'
    },
    {
        path: 'list',
        redirect: { name: 'partner.list' }
    },
    {
        path: 'obcan-new',
        component: PartnerObcanNewPage,
        name: 'obcan.partner.new',
        meta: {
            breadcrump: 'Založení partnera - občan'
        }
    },
    {
        path: 'firma-new',
        component: PartnerFirmaNewPage,
        name: 'firma.partner.new',
        meta: {
            breadcrump: 'Založení partnera - firma'
        }
    },
    {
        path: ':id',
        component: PartnerDetailPage,
        name: 'partner.detail',
        meta: {
            breadcrump: 'Detail partnera'
        }
    },
    {
        path: '*',
        redirect: { name: 'partner.list' }
    }
];
