














































































































































import { ObchodniPripad } from '@/models/obp';
import { Component, Vue, Watch, Ref, Mixins } from 'vue-property-decorator';
import R from '@/models/resources';
import { NemovitostCv } from '@/models/nemovitost';
import { VForm } from '@/models/vue-components';
import { ModAppAction } from '@/store/app/app.action';
import NotSavedComponent from '@/ui/components/not-saved.component';
import { mapGetters } from 'vuex';
import { Modules } from '@/store/modules';
import { AppGetter } from '@/store/app/app.getter';
import ObpHistoryComponent from '@/ui/components/obp/obp-history.component.vue';
import { ModDraftMutation } from '@/store/draft/draft.mutation';

@Component({
    components: {
        'cms-obp-history': ObpHistoryComponent
    },
    computed: {
        ...mapGetters(Modules.APP, [AppGetter.CURR_TIMEVERSION])
    }
})
export default class ObpDetailPage extends Mixins(Vue, NotSavedComponent) {
    @Ref('form') form!: VForm;

    obp: ObchodniPripad | null = null;
    nemovitostCvs: NemovitostCv[] = [];
    sumaCena = 0;

    isSaving = false;
    dialogSpravaNemovitosti = false;

    @Watch('obp', { deep: true })
    onChangeObp() {
        this.isChanged = true;
    }

    @Watch('$route.params.obpId', { immediate: true })
    async onChangeObpId(val: any) {
        const obpId = +val;
        this.obp = (
            await this.$odata.getById<ObchodniPripad>('obchodnipripad', obpId, {
                expand: {
                    partner: { select: ['id', 'nazev'] },
                    // stavPronajem: { select: ['zkratka'] },
                    cenoTvorbas: {}
                }
            })
        ).data;

        this.nemovitostCvs = (
            await this.$odata.propertyList<NemovitostCv>('obchodnipripad', obpId, 'nemovitostCvs', {
                expand: {
                    cenoTvorba: {},
                    nemovitost: { expand: { typNemovitost: {} } }
                },
                orderBy: ['katalogoveCislo']
            })
        ).data.value;

        this.$nextTick(() => {
            this.isChanged = false;
        });

        this.prepocitatCenu();
    }

    prepocitatCenu() {
        this.sumaCena = 0;
        this.obp?.cenoTvorbas.forEach(c => {
            if (this.nemovitostCvs.some(n => n.nemovitostId == c.nemovitostId)) {
                this.sumaCena = this.sumaCena + (c.cenaNajemneDNCelkem ?? 0);
            }
        });
    }

    async save(afterSave: () => void): Promise<void> {
        if (this.obp == null) return;
        if (!this.form.validate()) return;

        this.isSaving = true;

        const dto = {
            id: this.obp.id,
            isDraft: this.obp.isDraft,
            partnerId: this.obp.partnerId,
            nemovitostIds: this.nemovitostCvs.map(m => m.nemovitostId),
            prodejceUzivatelId: this.obp.prodejceUzivatelId,
            najemOd: this.obp.najemOd ? new Date(this.obp.najemOd) : null,
            najemDo: this.obp.najemDo ? new Date(this.obp.najemDo) : null
        };

        this.$odata
            .update<ObchodniPripad>('obchodniPripad', this.obp.id, dto)
            .then(() => {
                this.isChanged = false;

                if (afterSave != undefined) afterSave();
                else this.$snackbar.success(R.Ulozeno);
            })
            .catch(i => {
                this.$snackbar.error(i.response.data.value);
            })
            .finally(() => {
                this.isSaving = false;
            });
    }

    nur(): void {
        if (this.obp?.stavPronajemId == 2) {
            this.$snackbar.info('Obchodní případ je ve stavu NUR');
        } else if (confirm('Chcete přejít do stavu NUR?')) {
            this.setStav(2);
        }
    }

    zur(): void {
        if (this.obp?.stavPronajemId == 3) {
            this.$snackbar.info('Obchodní případ je ve stavu ZUR');
        } else if (confirm('Chcete přejít do stavu ZUR?')) {
            this.setStav(3);
        }
    }

    setStav(stavId: number) {
        this.save(() => {
            this.$odata
                .function<any>('obchodnipripad', { key: this.obp?.id, func: { setstav: { stavId } } })
                .then(s => {
                    if (this.obp) {
                        this.obp.stavPronajem.zkratka = s.data.value;
                        this.obp.stavPronajemId = stavId;
                        this.$snackbar.success('Nastaven stav: ' + this.obp.stavPronajem.zkratka);

                        this.$nextTick(() => {
                            this.isChanged = false;
                        });
                    }
                });
        });
    }

    pnps(): void {
        this.save(() => {
            this.$odata
                .function<any>('obchodnipripad', { key: this.obp?.id, func: 'GetPnps()' })
                .then(res => {
                    if (res.data.value == 0) {
                        if (confirm('Chcete vytvořit nový požadavek?')) {
                            this.$odata
                                .function<any>('obchodnipripad', { key: this.obp?.id, func: 'VytvoritPnps()' })
                                .then(d => this.$router.push({ name: 'pnps.detail', params: { pnpsId: String(d.data.value) } }));
                        } else {
                            return;
                        }
                    } else {
                        this.$router.push({ name: 'pnps.detail', params: { pnpsId: String(res.data.value) } });
                    }
                });
        });
    }

    zrusit(): void {
        if (confirm('Chcete smazat tento obchodní případ?')) {
            if (this.obp == null) return;
            this.$odata.remove('obchodniPripad', this.obp.id);
            this.$router.push({ name: 'obp.list' });
        }
    }

    removeNemovitost(id: any): void {
        if (this.obp != null) {
            this.$root.$confirm('Obchodní případ', 'Odebrat vybranou nemovitost?', { type: 'info' }).then(a => {
                if (a) {
                    this.nemovitostCvs = this.nemovitostCvs.filter(n => n.nemovitostId !== id);
                    this.prepocitatCenu();
                }
            });
        }
    }

    confirmSpravaNemovitosti() {
        this.dialogSpravaNemovitosti = false;

        this.save(async () => {
            await this.$store.dispatch(ModAppAction.INIT_PUBLISHED.toString());
            // this.$store.commit(ModDraftMutation.SET_OBP.toString(), { obp: this.obp });
            this.$store.commit(ModDraftMutation.ADD_NEMOVITOST.toString(), { nemovitosts: this.nemovitostCvs || [] });

            this.$router.push({ name: 'sp.nemovitost.list', params: { lokId: String(this.obp?.lokalitaId) } });
        });
    }
}
