var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1 row-pointer",attrs:{"dense":!_vm.$vuetify.breakpoint.mdAndDown,"headers":_vm.cols,"items":_vm.data,"loading":_vm.isLoading,"hide-default-footer":"","disable-sort":""},on:{"click:row":function($event){return _vm.$emit('rowClick', $event)}},scopedSlots:_vm._u([{key:"item.pocetSklad",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.volneSklad))]),_vm._v(" / "),_c('span',[_vm._v(_vm._s(item.pocetSklad))])]}},{key:"item.pocetKancelar",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.volneKancelar))]),_vm._v(" / "),_c('span',[_vm._v(_vm._s(item.pocetKancelar))])]}},{key:"item.pocetParkovaciStani",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.volneParkovaciStani))]),_vm._v(" / "),_c('span',[_vm._v(_vm._s(item.pocetParkovaciStani))])]}},{key:"item.pocetPozemek",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.volnePozemek))]),_vm._v(" / "),_c('span',[_vm._v(_vm._s(item.pocetPozemek))])]}},{key:"item.pocetOstatni",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.volneOstatni))]),_vm._v(" / "),_c('span',[_vm._v(_vm._s(item.pocetOstatni))])]}},{key:"item.pocetNemovitosti",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.volneNemovitosti))]),_vm._v(" / "),_c('span',[_vm._v(_vm._s(item.pocetNemovitosti))])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }