




























import { Component, Vue } from 'vue-property-decorator';

import { ConfirmOptions } from '@/ui/components/confirm/confirm-options';

@Component
export default class CmsConfirmComponent extends Vue {
    dialog = false;

    resolve!: Function;
    reject!: Function;

    message = '';
    title = '';

    defaultOptions: ConfirmOptions = {
        confirmColor: 'primary',
        cancelColor: 'black',
        confirmText: 'Ano',
        cancelText: 'Ne',
        type: 'grey',
        width: 400,
        zIndex: 200,
        thirdOption: false,
        thirdText: 'Zrušit',
        thirdColor: 'black'
    };

    options: ConfirmOptions = {};

    open(title: string, message: string, options?: ConfirmOptions): Promise<boolean> {
        this.dialog = true;

        this.title = title;
        this.message = message;

        this.options = { ...this.defaultOptions };
        if (options) {
            this.options = Object.assign(this.options, options);
        }

        return new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });
    }

    confirm() {
        this.resolve(true);
        this.dialog = false;
    }

    denied() {
        this.resolve(false);
        this.dialog = false;
    }

    cancel() {
        this.resolve(null);
        this.dialog = false;
    }
}
