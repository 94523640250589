import { render, staticRenderFns } from "./pnps-list.page.vue?vue&type=template&id=7af52d96&v-slot%3Afilter=true&"
import script from "./pnps-list.page.vue?vue&type=script&lang=ts&"
export * from "./pnps-list.page.vue?vue&type=script&lang=ts&"
import style0 from "./pnps-list.page.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./pnps-list.page.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VCard,VCardActions,VCol,VDataTable,VDialog,VDivider,VIcon,VRow,VSpacer,VSwitch,VToolbar,VToolbarTitle})
