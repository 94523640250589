import { RouteConfig } from 'vue-router';

import LoginPage from '@/ui/pages/auth/login.page.vue';

export const AUTH_ROUTES: RouteConfig[] = [
    {
        path: '',
        redirect: { name: 'login-page' }
    },
    {
        path: 'login',
        name: 'login-page',
        component: LoginPage
    },
    {
        path: '*',
        redirect: { name: 'login-page' }
    }
];
