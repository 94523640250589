


































import { TimeVersion } from '@/models';
import { mapGetters } from 'vuex';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Modules } from '@/store/modules';
import { AppGetter } from '@/store/app/app.getter';

@Component({
    computed: {
        ...mapGetters(Modules.APP, [AppGetter.CURR_TIMEVERSION])
    }
})
export default class CmsSelectFieldComponent extends Vue {
    @Prop() items!: any[];
    @Prop() value!: any;
    @Prop() label!: string;
    @Prop() hintText!: string;

    @Prop({ default: 'id' }) itemValue!: string;
    @Prop({ default: 'nazev' }) itemText!: string;

    @Prop({ default: false, type: Boolean }) multiple!: boolean;
    @Prop({ default: 3 }) maxDisplay!: number | string;

    @Prop({ type: Boolean, default: true }) dense!: boolean;
    @Prop({ type: Boolean }) readonly!: boolean;
    @Prop({ type: Boolean }) required!: boolean;
    @Prop({ type: Boolean }) clearable!: boolean;

    @Prop({ default: () => [] }) rules!: any[];

    @Prop() props!: object;

    @Prop({ type: Boolean }) cv!: boolean;

    @Prop() right!: string;

    CURR_TIMEVERSION!: TimeVersion;

    get getMaxDisplay(): number {
        return +this.maxDisplay;
    }

    get hasRight(): boolean {
        return this.$right.hasRead(this.right);
    }

    get isReadonly(): boolean {
        return (
            this.readonly ||
            !this.$right.hasWrite(this.right) ||
            (this.cv && (!this.CURR_TIMEVERSION || this.CURR_TIMEVERSION.casovaHladinaTyp.id === TimeVersion.Archivacni))
        );
    }

    get ruleset(): any[] {
        const result = [...this.rules];

        if (this.required) result.push((v: any) => !!v || this.label + ' musí být vyplněn');

        return result;
    }
}
