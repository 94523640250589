























































import { Modules } from '@/store/modules';
import { Component, Vue, Watch, Ref } from 'vue-property-decorator';
import { mapGetters, mapMutations } from 'vuex';
import { NemovitostCv } from '@/models/nemovitost';
import { PnpsCreate, PozadavekNaPripravuSmlouvy } from '@/models/pnps';
import R from '@/models/resources';
import { VForm } from '@/models/vue-components';
import { handleException } from '@/helpers';
import { sumBy } from 'lodash';
import { DraftGetter } from '@/store/draft/draft.getter';
import { DraftMutation, ModDraftMutation } from '@/store/draft/draft.mutation';

@Component({
    computed: {
        ...mapGetters(Modules.DRAFT, [DraftGetter.PNPS_DRAFT, DraftGetter.PNPS, DraftGetter.NEMOVITOSTS])
    },
    methods: {
        ...mapMutations(Modules.DRAFT, [DraftMutation.CLEAR_ALL])
    }
})
export default class DraftComponent extends Vue {
    @Ref('form') form!: VForm;

    PNPS_DRAFT!: boolean;
    PNPS!: PozadavekNaPripravuSmlouvy | null;

    NEMOVITOSTS!: NemovitostCv[];

    CLEAR_ALL!: () => void;

    isChanged = false;

    partnerId = 0;
    sumaCena = 0;

    @Watch('partnerId')
    onChangePartnerId() {
        this.isChanged = true;
    }

    @Watch('PNPS_DRAFT', { immediate: true })
    onChangePnpsDraft() {
        if (!this.PNPS && this.PNPS_DRAFT) {
            this.partnerId = 0;

            this.$nextTick(() => (this.isChanged = false));
        }
    }

    @Watch('PNPS', { immediate: true })
    onChangePNPS() {
        if (this.PNPS && !this.PNPS_DRAFT) {
            this.partnerId = this.PNPS.najemcePartnerId;

            this.$nextTick(() => (this.isChanged = false));
        }
    }

    @Watch('NEMOVITOSTS', { immediate: true, deep: true })
    onChangeNemovitosts() {
        this.sumaCena = sumBy(this.NEMOVITOSTS, s => s.cenoTvorba.cenaNajemneDNCelkem || 0);
    }

    remove(id: number): void {
        const title = this.PNPS ? 'PNPS' : 'PNPS Draft';

        this.$root.$confirm(title, 'Odebrat vybranou nemovitost?', { type: 'info' }).then(a => {
            if (a) {
                this.$store.commit(ModDraftMutation.DEL_NEMOVITOST.toString(), { id });
                this.isChanged = true;
            }
        });
    }

    async save(stavPronajemId?: number) {
        if (!this.form.validate()) return;

        if (this.PNPS_DRAFT) {
            const newPnps: PnpsCreate = {
                stavPronajemId: stavPronajemId,
                partnerId: this.partnerId,
                nemovitostIds: this.NEMOVITOSTS.map(m => m.nemovitostId)
            };

            this.$odata
                .create<PozadavekNaPripravuSmlouvy>('pnps', newPnps)
                .then(a => {
                    this.$snackbar.success(R.Ulozeno);

                    this.CLEAR_ALL();
                    this.$router.push({ name: 'pnps.detail', params: { pnpsId: String(a.data.id) } });
                })
                .catch(i => {
                    this.$snackbar.error(i.response.data.value);
                });
        }

        if (this.PNPS) {
            const id = this.PNPS.id;
            const parentId = this.PNPS.parentPnpsId;

            this.$odata
                .function('pnps', {
                    key: id,
                    func: { setNemovitosts: { nemovitostIds: this.NEMOVITOSTS.map(m => m.nemovitostId) } }
                })
                .then(() => {
                    this.$snackbar.success(R.Ulozeno);

                    this.CLEAR_ALL();

                    if (parentId) {
                        this.$router.push({
                            name: 'podpozadavek.pnps.detail',
                            params: { pnpsId: String(parentId), podPnpsId: String(id) }
                        });
                    } else {
                        this.$router.push({ name: 'pnps.detail', params: { pnpsId: String(id) } });
                    }
                })
                .catch(i => {
                    handleException(i);
                });
        }
    }

    nur(): void {
        this.save(2);
    }

    zur(): void {
        this.save(3);
    }

    zrusit(): void {
        if (this.isChanged) {
            this.$root.$confirm(R.NeulozeneZmeny, R.NeulozeneZmenyOtazka, { type: 'warning' }).then(a => {
                if (a) {
                    this.ukoncit();
                    this.isChanged = false;
                }
            });
        } else {
            this.ukoncit();
        }
    }

    ukoncit() {
        this.form.resetValidation();
        this.CLEAR_ALL();
    }
}
