


















































import { Component, Prop, Vue, Ref, Watch } from 'vue-property-decorator';

import { PartnerAdresa } from '@/models/partner';
import { ODataService } from '@/services/odata.service';
import { TypAdresa } from '@/models';
import CmsTextFieldComponent from '../../inputs/text-field.component.vue';
import PartnerAdresaComponent from '../partner-adresa.component.vue';
import { VForm } from '@/models/vue-components';
import CmsCisloPopisneFieldComponent from '../../inputs/cislo-popisne-field.vue';
import CmsPSCFieldComponent from '../../inputs/psc-field.component.vue';

@Component({
    components: {
        'cms-text-field': CmsTextFieldComponent,
        'cms-cislo-field': CmsCisloPopisneFieldComponent,
        'cms-psc-field': CmsPSCFieldComponent,
        'cms-partner-adresa': PartnerAdresaComponent
    }
})
export default class PartnerAdresyTab extends Vue {
    readonly service = new ODataService();
    @Prop() value!: PartnerAdresa[];
    @Ref('adresaForm') adresaForm!: VForm;
    @Watch('editDialog')
    onChangeDialog() {
        if (!this.editDialog) {
            this.editAdresa = {};
        }
    }

    editDialog = false;

    adresaTypes: TypAdresa[] = [];

    editAdresa = {};

    headers = [
        { text: 'Typ adresy', value: 'typAdresaId' },
        { text: 'Adresát', value: 'adresat' },
        { text: 'Ulice', value: 'ulice' },
        { text: 'Město', value: 'mesto' },
        { text: 'PSČ', value: 'psc' },
        { text: 'Stát', value: 'stat' }
    ];

    getTypAdresa(id: number): string {
        return this.adresaTypes.find(f => f.typAdresaId === id)?.nazev || '';
    }

    openEdit(e: PartnerAdresa): void {
        this.editAdresa = { ...e };
        this.editDialog = true;
    }

    save(): void {
        if (!this.adresaForm.validate()) return;

        const adresa = this.editAdresa as { id: number; tmpId: number };
        let result = [];

        if (adresa.id) {
            result = [...this.value.filter(f => f.id !== adresa.id), { ...adresa }];
        } else if (adresa.tmpId) {
            result = [...this.value.filter(f => (f as any).tmpId !== adresa.tmpId), { ...adresa }];
        } else {
            result = [...this.value, { ...adresa, tmpId: new Date().getTime() }];
        }

        this.$emit('input', result);

        this.editAdresa = {};
        this.editDialog = false;
    }

    async mounted(): Promise<void> {
        this.adresaTypes = (await this.service.getList<TypAdresa>('typadresa')).data.value;
    }
}
