



































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { Modules } from '@/store/modules';
import { AppGetter } from '@/store/app/app.getter';
import { TimeVersion } from '@/models';

@Component({
    computed: {
        ...mapGetters(Modules.APP, [AppGetter.CURR_TIMEVERSION])
    }
})
export default class CmsNumberFieldComponent extends Vue {
    @Prop() value!: number;
    @Prop({ type: Boolean }) readonly!: boolean;
    @Prop({ default: true, type: Boolean }) dense!: boolean;
    @Prop() label!: string;
    @Prop() suffix!: string;
    @Prop() prefix!: string;
    @Prop({ type: Boolean }) clearable!: boolean;
    @Prop({ type: Boolean }) required!: boolean;
    @Prop() type?: string;
    @Prop({ default: () => [] }) rules!: any[];

    @Prop() props!: object;

    @Prop({ type: Boolean }) cv!: boolean;

    @Prop() right!: string;

    //pro pretizeni
    @Prop() pretizeno!: boolean;

    @Prop() hintText!: string;
    @Prop({ type: Boolean, default: false }) hintVisible!: boolean;

    backgroundColor = '';

    @Watch('pretizeno', { immediate: true })
    setPretizeni() {
        this.backgroundColor = this.pretizeno ? 'rgb(248,255,221)' : '';
    }

    CURR_TIMEVERSION!: TimeVersion;

    get isHintVisible(): boolean {
        return this.pretizeno || this.hintVisible;
    }

    get hasRight(): boolean {
        return this.$right.hasRead(this.right);
    }

    get ruleset(): any[] {
        const result = [...this.rules];

        if (this.required) result.push((v: any) => !!v || this.label + ' musí být vyplněn');

        return result;
    }

    get isReadonly(): boolean {
        return (
            this.readonly ||
            !this.$right.hasWrite(this.right) ||
            (this.cv && (!this.CURR_TIMEVERSION || this.CURR_TIMEVERSION.casovaHladinaTyp.id === TimeVersion.Archivacni))
        );
    }

    onChangeInput(val: string): void {
        this.$emit('input', this.getNumber(val));
    }

    get userFormat(): string | null {
        if (this.value != null && this.value != undefined && String(this.value) != '' && !isNaN(+this.value)) {
            return new Intl.NumberFormat('cs-CZ').format(this.value);
        }

        return null;
    }

    reverseFormatNumber(val: string, locale: string) {
        const group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, '');
        const decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, '');
        let reversedVal = val.replace(new RegExp('\\' + group, 'g'), '');
        reversedVal = reversedVal.replace(new RegExp('\\' + decimal, 'g'), '.');
        return Number.isNaN(reversedVal) ? 0 : reversedVal;
    }

    getNumber(val: any) {
        if (val != null && val != undefined && val != '') {
            const number = +this.reverseFormatNumber(val, 'cs-CZ');

            if (!isNaN(number)) {
                return number;
            }
        }

        return null;
    }
}
