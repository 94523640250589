
































































class PnpsNemovitostRowItem {
    constructor() {
        this.tentoDodatek = false;
        this.dodatekPridalCislo = '';
        this.katalogoveCislo = '';
        this.podlazis = '';
        this.oznaceni = '';
        this.typ = '';
        this.plocha = null;
        this.datumOdebrani = null;
        this.odstranitEnabled = false;
        this.id = -1;
        this.nemovitostCvId = -1;
        this.budeOdebrana = false;
        this.vazbaId = -1;
        this.budova = '';
    }

    vazbaId: number;
    dodatekPridalCislo: string;
    id: number;
    tentoDodatek: boolean; //Nemovitost byla odebrána tímto dodatkem
    katalogoveCislo: string;
    podlazis: string;
    oznaceni: string;
    typ: string;
    plocha: number | null;
    datumOdebrani: string | null;
    odstranitEnabled: boolean;
    nemovitostCvId: number;
    budeOdebrana: boolean;
    budova: string;
}

import { NemovitostCv } from '@/models/nemovitost';
import { PnpsNemovitostVazba, PozadavekNaPripravuSmlouvy } from '@/models/pnps';
import { AppGetter } from '@/store/app/app.getter';
import { Modules } from '@/store/modules';
import { mapGetters } from 'vuex';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { format } from 'date-fns';

@Component({
    computed: {
        ...mapGetters(Modules.APP, [AppGetter.CURR_TIMEVERSION])
    }
})
export default class PnpsNemovitostTab extends Vue {
    @Prop() value!: NemovitostCv[];
    @Prop() vazbas!: PnpsNemovitostVazba[];
    @Prop() pnps!: PozadavekNaPripravuSmlouvy;
    @Prop() readonly!: boolean;
    headers = [
        /*  { text: 'Id', value: 'id', visible: false },*/

        { text: 'Tento dodatek', value: 'tentoDodatek', sortable: false },
        { text: 'Přidáno dodatkem', value: 'dodatekPridalCislo' },
        { text: 'Katalogové číslo', value: 'katalogoveCislo' },
        { text: 'Budova', value: 'budova' },
        { text: 'Podlaží', value: 'podlazis', sortable: false },

        { text: 'Interní označení', value: 'oznaceni' },
        { text: 'Typ', value: 'typ' },
        { text: 'Plocha', value: 'plocha' },
        { text: 'Datum odebrání', value: 'datumOdebrani' },
        { text: '', value: 'odebrat' }
    ];

    datasource: PnpsNemovitostRowItem[] = [];

    dialog = false;

    removeNemovitost(item: PnpsNemovitostRowItem) {
        this.$root.$confirm('PNPS', 'Odebrat vybranou nemovitost?', { type: 'info' }).then(a => {
            if (a) {
                // item.budeOdebrana = true;
                this.datasource = this.datasource.filter(i => !(i.nemovitostCvId == item.nemovitostCvId && i.datumOdebrani === ''));

                this.$emit('nemovitostRemove', item.id);
            }
        });
    }

    getItemClass(item: any): string {
        if (item.datumOdebrani != '') {
            return 'stav-odebrano';
        } else if (item.budeOdebrana) {
            return 'bude-odebrano';
        }

        return '';
    }

    getNalezi(item: number) {
        return (
            this.vazbas.filter(v => v.id == item && v.pnpsPridalId == this.pnps.id).length > 0 ||
            this.vazbas.filter(v => v.id == item && v.pnpsOdebralId == this.pnps.id).length > 0
        );
    }

    /*TEST */
    getDatumOdstraneni(nemovitost: NemovitostCv): string {
        const nem = this.vazbas.filter(n => n.nemovitostId === nemovitost.nemovitostId)[0];
        return nem.datumOdebrani == null ? '' : format(new Date(nem.datumOdebrani), 'yyyy-MM-dd');
    }

    confirm() {
        this.dialog = false;
        this.$emit('spravovatNemovitosti');
    }

    rowClick(item: any) {
        this.$router.push({
            name: 'nemovitost.detail',
            params: { nemCvId: String(item.nemovitostCvId) }
        });
    }

    @Watch('value', { deep: true, immediate: true })
    onValueChanged(value: PnpsNemovitostVazba[]) {
        this.datasource = [];

        this.vazbas.forEach(vazba => {
            const nemovitostCv = this.value.filter(ncv => ncv.nemovitostId === vazba.nemovitostId)[0];

            const item = new PnpsNemovitostRowItem();
            item.vazbaId = vazba.id;
            item.dodatekPridalCislo = vazba.pnpsPridal?.cisloSmlouvyText ?? '';
            item.tentoDodatek = this.getNalezi(item.vazbaId);
            item.katalogoveCislo = nemovitostCv.katalogoveCislo;
            item.podlazis = nemovitostCv.nemovitost.podlazis.map(x => x.nazev).join(', ');
            item.oznaceni = nemovitostCv.oznaceni;
            item.typ = nemovitostCv.nemovitost.typNemovitost.nazev;
            item.plocha = nemovitostCv.plocha;
            item.datumOdebrani = vazba.datumOdebrani == null ? '' : format(new Date(vazba.datumOdebrani), 'yyyy-MM-dd');
            item.odstranitEnabled = item.datumOdebrani == '' && !this.readonly;
            item.id = nemovitostCv.nemovitostId;
            item.nemovitostCvId = nemovitostCv.id;
            item.budova = nemovitostCv.nemovitost.budova?.nazev ?? '';

            this.datasource.push(item);
        });
    }
}
