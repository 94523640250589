import { Nemovitost, NemovitostCv } from './nemovitost';
import { eTypPronajem } from '../typ-pronajem';
import { da } from 'date-fns/locale';

export class CenoTvorba {
    constructor(dataObjekt?: CenoTvorba) {
        this.id = dataObjekt?.id;

        this.plocha = dataObjekt?.plocha;
        this.cenaNajemneDN = dataObjekt?.cenaNajemneDN;
        this.cenaNajemneDU1Rok = dataObjekt?.cenaNajemneDU1Rok;
        this.cenaNajemneDUKonecZivotnosti = dataObjekt?.cenaNajemneDUKonecZivotnosti;
        this.cenaZalohySazba1 = dataObjekt?.cenaZalohySazba1;
        this.cenaZalohySazba2 = dataObjekt?.cenaZalohySazba2;
        this.cenaZalohySazbaZaklad = dataObjekt?.cenaZalohySazbaZaklad;
        this.cenaSluzbySazba1 = dataObjekt?.cenaSluzbySazba1;
        this.cenaSluzbySazba2 = dataObjekt?.cenaSluzbySazba2;
        this.cenaSluzbySazbaZaklad = dataObjekt?.cenaSluzbySazbaZaklad;
        this.cenaSluzbySazbaZakladFixni = dataObjekt?.cenaSluzbySazbaZakladFixni;
        this.cenaSluzbySazba1Fixni = dataObjekt?.cenaSluzbySazba1Fixni;
        this.cenaSluzbySazba2Fixni = dataObjekt?.cenaSluzbySazba2Fixni;
        this.cenaServisniPoplatek = dataObjekt?.cenaServisniPoplatek;
        this.cenaServisniPoplatekFixni = dataObjekt?.cenaServisniPoplatekFixni;
        this.puvodniCenaZalohySazba1 = dataObjekt?.puvodniCenaZalohySazba1;
        this.puvodniCenaZalohySazba2 = dataObjekt?.puvodniCenaZalohySazba2;
        this.puvodniCenaZalohySazbaZaklad = dataObjekt?.puvodniCenaZalohySazbaZaklad;
        this.puvodniCenaSluzbySazba1 = dataObjekt?.puvodniCenaSluzbySazba1;
        this.puvodniCenaSluzbySazba2 = dataObjekt?.puvodniCenaSluzbySazba2;
        this.puvodniCenaSluzbySazbaZaklad = dataObjekt?.puvodniCenaSluzbySazbaZaklad;
        this.puvodniCenaServisniPoplatek = dataObjekt?.puvodniCenaServisniPoplatek;

        this.cenaNajemneFixni = dataObjekt?.cenaNajemneFixni;
        this.maxNajem = dataObjekt?.maxNajem;

        this.nemovitostId = dataObjekt?.nemovitostId;
        this.nemovitostCvId = dataObjekt?.nemovitostCvId;
        this.nemovitost = dataObjekt?.nemovitost;
        this.nemovitostCv = dataObjekt?.nemovitostCv;

        this.cenaNajemnePnps = dataObjekt?.cenaNajemnePnps;

        this.cenaNajemnePnpsPocitano = dataObjekt?.cenaNajemnePnpsPocitano;

        this.isDph = dataObjekt?.isDph;
        this.cenaNajemnePnpsCelkem = dataObjekt?.cenaNajemnePnpsCelkem;
        this.cenaNajemnePnpsCelkemSDph = dataObjekt?.cenaNajemnePnpsCelkemSDph;

        this.navyseniJistoty = dataObjekt?.navyseniJistoty;

        this.pnpsId = dataObjekt?.pnpsId;

        this.katalogoveCislo = dataObjekt?.katalogoveCislo;
        this.typ = dataObjekt?.typ;
        this.oznaceni = dataObjekt?.oznaceni;
        this.cisloSmlouvy = dataObjekt?.cisloSmlouvy ?? '';
        this.readonly = dataObjekt?.readonly ?? false;
    }

    private static readonly SAZBA_DPH: number = 1.21;
    private static readonly SAZBA_DPH_SNIZENA1: number = 1.12;
    private static readonly SAZBA_DPH_SNIZENA2: number = 1.10;
    public cisloSmlouvy = "";
    public readonly = false;
    public id?: number;

    public nemovitostId?: number;
    public nemovitost?: Nemovitost;

    public nemovitostCvId?: number;
    public nemovitostCv?: NemovitostCv;

    public pnpsId?: number;

    public plocha?: number | null;

    public cenaNajemneDN?: number | null;
    public cenaNajemneDU1Rok?: number | null;
    public cenaNajemneDUKonecZivotnosti?: number | null;

    public cenaZalohySazbaZaklad?: number | null;
    public cenaZalohySazba1?: number | null;
    public cenaZalohySazba2?: number | null;
    public puvodniCenaZalohySazbaZaklad?: number | null;
    public puvodniCenaZalohySazba1?: number | null;
    public puvodniCenaZalohySazba2?: number | null;

    public cenaSluzbySazbaZaklad?: number | null;
    public cenaSluzbySazba1?: number | null;
    public cenaSluzbySazba2?: number | null;
    public cenaSluzbySazbaZakladFixni?: number | null;
    public cenaSluzbySazba1Fixni?: number | null;
    public cenaSluzbySazba2Fixni?: number | null;



    public puvodniCenaSluzbySazbaZaklad?: number | null;
    public puvodniCenaSluzbySazba1?: number | null;
    public puvodniCenaSluzbySazba2?: number | null;

    public cenaNajemneFixni?: number | null;
    public cenaServisniPoplatek?: number | null;
    public cenaServisniPoplatekFixni?: number | null;
    public puvodniCenaServisniPoplatek?: number | null;
    public pozadavekNaPripravuSmlouvyId?: number | null;

    public navyseniJistoty?: number | null;

    /* Ceny PNPS jsou pro zobrazení zvoleného typu nájmu pro pnps, při změně typu se do ceny nastaví ve funkci setCenyPnps*/
    public cenaNajemnePnps?: number | null;

    //Nastavuje se až na pnps volbou v comboboxu, ovlivnuje cenu cenaNajemnePnpsCelkemSDph
    public isDph?: boolean;

    /* Cena celkem je vynasobena plochou, pokud je vyplneno cenaNejemneFixni tak je přetížena fixní cenou*/
    /* Zobrazuje se na kartě pnps-cenotvorba v poli Nájemné celkem a vstupuje do sumy Cena na detailu pnps*/
    public cenaNajemnePnpsCelkem?: number | null;
    public cenaNajemnePnpsCelkemSDph?: number | null;

    /*Počítáno slouží k tomu, aby se zobrazila původní  hodnota pod polem pnps-cenotvorba-nájemné za m2, když je  přetíženo*/
    public cenaNajemnePnpsPocitano?: number | null;

    public katalogoveCislo?: string;
    public typ?: string;
    public oznaceni?: string;
 

    public get pretizeno() {
        return this.cenaNajemnePnps != this.cenaNajemnePnpsPocitano;
    }

    public setPuvodniHodnoty(typNajem?: eTypPronajem): CenoTvorba {
        switch (typNajem) {
            case eTypPronajem.Kratkodoby:
            case eTypPronajem.DobaNeurcita: {
                this.cenaNajemnePnpsPocitano = this.cenaNajemneDN;
                break;
            }
            case eTypPronajem.DobaUrcitaMin1Rok: {
                this.cenaNajemnePnpsPocitano = this.cenaNajemneDU1Rok;
                break;
            }
            case eTypPronajem.DobaDoKonceZivotnosti: {
                this.cenaNajemnePnpsPocitano = this.cenaNajemneDUKonecZivotnosti;
                break;
            }
            default: {
                this.cenaNajemnePnpsPocitano = null;
                break;
            }
        }

        return this;
    }

    public setCenyPnps(typNajem: eTypPronajem, isDph: boolean): CenoTvorba {
        this.isDph = isDph;
        switch (typNajem) {
            case eTypPronajem.Kratkodoby:
            case eTypPronajem.DobaNeurcita: {
                if (!this.pretizeno) this.cenaNajemnePnps = this.cenaNajemneDN;
                this.cenaNajemnePnpsPocitano = this.cenaNajemneDN;
                break;
            }
            case eTypPronajem.DobaUrcitaMin1Rok: {
                if (!this.pretizeno) this.cenaNajemnePnps = this.cenaNajemneDU1Rok;
                this.cenaNajemnePnpsPocitano = this.cenaNajemneDU1Rok;
                break;
            }
            case eTypPronajem.DobaDoKonceZivotnosti: {
                if (!this.pretizeno) this.cenaNajemnePnps = this.cenaNajemneDUKonecZivotnosti;
                this.cenaNajemnePnpsPocitano = this.cenaNajemneDUKonecZivotnosti;
                break;
            }
            default: {
                this.cenaNajemnePnps = null;
                this.cenaNajemnePnpsPocitano = null;
                break;
            }
        }

        this.vypocitatCelkoveCeny();
        return this;
    }

    //pouze pro filter v seznamu nemovitostí
    public maxNajem?: number | null;

    public get sazbaDph()
    {
        return  (this.isDph || this.nemovitost?.typNemovitostId == 3) ? CenoTvorba.SAZBA_DPH : 1;
    }
    
    //Čisté nájemné bez služeb, poplatku atd. neukládá se do DB
    public get najemneBezDph() 
    {
        return this.cenaNajemneFixni ? this.cenaNajemneFixni : (this.cenaNajemnePnps ?? 0) * (this.plocha ?? 0);
    }
     //Čisté nájemné bez služeb s DPH, poplatku atd. neukládá se do DB
    public get najemneSDph()
    {

        return Math.round(this.najemneBezDph * this.sazbaDph);

    }


    //Výpočet PNPS - Cenotvorba - poslední dva boxy Nájemné celkem a Nájemné celkem s DPH
    public vypocitatCelkoveCeny() {
        const najem = this.cenaNajemneFixni ? this.cenaNajemneFixni : (this.cenaNajemnePnps ?? 0) * (this.plocha ?? 0);
        this.cenaNajemnePnpsCelkemSDph =
            (this.cenaSluzbySDph ?? 0) +
            (this.cenaZalohySDph ?? 0) +
            Math.round(najem *  ((this.isDph || this.nemovitost?.typNemovitostId == 3) ? CenoTvorba.SAZBA_DPH : 1)) +
            Math.round(this.cenaServisniPoplatekFinal * CenoTvorba.SAZBA_DPH);

        this.cenaNajemnePnpsCelkem =
            (this.cenaSluzbyBezDph ?? 0) + (this.cenaZalohyBezDph ?? 0) + najem + Math.round(this.cenaServisniPoplatekFinal);
    }

    //Sečte všechny služby a vynásobí plochou
    public get cenaSluzbyBezDph() {
        if (this.cenaSluzbySazba1 != null || this.cenaSluzbySazba2 != null || this.cenaSluzbySazbaZaklad != null ||
            this.cenaSluzbySazba1Fixni != null || this.cenaSluzbySazba2Fixni != null || this.cenaSluzbySazbaZakladFixni != null) {
            const sz =  Math.round(this.cenaSluzbySazbaZakladFixni != null ? this.cenaSluzbySazbaZakladFixni :  this.cenaSluzbySazbaZaklad != null  ? this.cenaSluzbySazbaZaklad * (this.plocha ?? 0) : 0);
            const s1 =  Math.round(this.cenaSluzbySazba1Fixni != null ? this.cenaSluzbySazba1Fixni :  this.cenaSluzbySazba1 != null ? this.cenaSluzbySazba1 * (this.plocha ?? 0) : 0);
            const s2 =  Math.round(this.cenaSluzbySazba2Fixni != null ? this.cenaSluzbySazba2Fixni :  this.cenaSluzbySazba2 != null ? this.cenaSluzbySazba2 * (this.plocha ?? 0) : 0);
            
            return sz + s1 + s2;
        }
        return null;
    }

    //Sečte všechny služby a vynásobí plochou + DPH
    public get cenaSluzbySDph() {
        if (this.cenaSluzbyBezDph != null) {
            const sz =  Math.round((this.cenaSluzbySazbaZakladFixni != null ? this.cenaSluzbySazbaZakladFixni :  this.cenaSluzbySazbaZaklad != null  ? this.cenaSluzbySazbaZaklad * (this.plocha ?? 0) : 0) * 1.21);
            const s1 =  Math.round((this.cenaSluzbySazba1Fixni != null ? this.cenaSluzbySazba1Fixni :  this.cenaSluzbySazba1 != null ? this.cenaSluzbySazba1 * (this.plocha ?? 0) : 0) * CenoTvorba.SAZBA_DPH_SNIZENA1);
            const s2 =  Math.round((this.cenaSluzbySazba2Fixni != null ? this.cenaSluzbySazba2Fixni :  this.cenaSluzbySazba2 != null ? this.cenaSluzbySazba2 * (this.plocha ?? 0) : 0) * CenoTvorba.SAZBA_DPH_SNIZENA2);
            return sz + s1 + s2;
        }
        return null;
    }

    //Sečte všechny zálohy bez DPH (zálohy se plochou nenásobí)
    public get cenaZalohyBezDph() {
        if (this.cenaZalohySazba1 != null || this.cenaZalohySazba2 != null || this.cenaZalohySazbaZaklad != null) {
            return (this.cenaZalohySazba1 ?? 0) + (this.cenaZalohySazba2 ?? 0) + (this.cenaZalohySazbaZaklad ?? 0);
        }
        return null;
    }

    //Sečte všechny zálohy   s DPH (zálohy se plochou nenásobí)
    public get cenaZalohySDph() {
        if (this.cenaZalohyBezDph != null) {
            return Math.round((this.cenaZalohySazba1 ?? 0) * CenoTvorba.SAZBA_DPH_SNIZENA1) + Math.round((this.cenaZalohySazba2 ?? 0) * CenoTvorba.SAZBA_DPH_SNIZENA2) + Math.round((this.cenaZalohySazbaZaklad ?? 0) * CenoTvorba.SAZBA_DPH);
        }
        return null;
    }

    //Fixni servisní poplatek nebo nefixní x plocha
    public get cenaServisniPoplatekFinal()
    {
        const result =  (this.cenaServisniPoplatekFixni != null && this.cenaServisniPoplatekFixni > 0)  ?  this.cenaServisniPoplatekFixni : ((this.cenaServisniPoplatek ?? 0) * (this.plocha ?? 0));
        return result;

    }

    public get cenaNajemneDNCelkem() {
        if (this.cenaNajemneDN != null) return Math.round(this.cenaNajemneDN * (this.plocha || 0));
        return null;
    }
    public get cenaNajemneDU1RokCelkem() {
        if (this.cenaNajemneDU1Rok != null) return Math.round(this.cenaNajemneDU1Rok * (this.plocha || 0));
        return null;
    }
    public get cenaNajemneDUKonecZivotnostiCelkem() {
        if (this.cenaNajemneDUKonecZivotnosti != null) return Math.round(this.cenaNajemneDUKonecZivotnosti * (this.plocha || 0));
        return null;
    }

    public get celkemSluzbyZalohyServisBezDph() {
        if (this.cenaSluzbyBezDph != null || this.cenaZalohyBezDph != null || this.cenaServisniPoplatekFinal != 0) {
            return (this.cenaSluzbyBezDph ?? 0) + (this.cenaZalohyBezDph ?? 0) + Math.round(this.cenaServisniPoplatekFinal);
        }
        return null;
    }

    public get celkemSluzbyZalohyServisSDph() {
        if (this.cenaSluzbySDph != null || this.cenaZalohySDph != null || this.cenaServisniPoplatekFinal != 0) {
            return (this.cenaSluzbySDph ?? 0) + (this.cenaZalohySDph ?? 0) + Math.round(this.cenaServisniPoplatekFinal * CenoTvorba.SAZBA_DPH);
        }
        return null;
    }

    public get celkemDN() {
        if (this.celkemSluzbyZalohyServisBezDph != null || this.cenaNajemneDNCelkem != null) {
            return (this.celkemSluzbyZalohyServisBezDph ?? 0) + (this.cenaNajemneDNCelkem ?? 0);
        }
        return null;
    }

    public get celkemDU1Rok() {
        if (this.celkemSluzbyZalohyServisBezDph != null || this.cenaNajemneDU1Rok != null) {
            return (this.celkemSluzbyZalohyServisBezDph ?? 0) + (this.cenaNajemneDU1RokCelkem ?? 0);
        }
        return null;
    }

    public get celkemDUKonecZivotnosti() {
        if (this.celkemSluzbyZalohyServisBezDph != null || this.cenaNajemneDUKonecZivotnosti != null) {
            return (this.celkemSluzbyZalohyServisBezDph ?? 0) + (this.cenaNajemneDUKonecZivotnostiCelkem ?? 0);
        }
        return null;
    }

    public get celkemSDphDN() {
        if (this.celkemSluzbyZalohyServisSDph != null || this.cenaNajemneDNCelkem != null) {
            return (this.celkemSluzbyZalohyServisSDph ?? 0) + Math.round((this.cenaNajemneDNCelkem ?? 0) * CenoTvorba.SAZBA_DPH);
        }
        return null;
    }

    public get celkemSDphDU1Rok() {
        if (this.celkemSluzbyZalohyServisSDph != null || this.cenaNajemneDU1RokCelkem != null) {
            return (this.celkemSluzbyZalohyServisSDph ?? 0) + Math.round((this.cenaNajemneDU1RokCelkem ?? 0) * CenoTvorba.SAZBA_DPH);
        }
        return null;
    }

    public get celkemSDphDUKonecZivotnosti() {
        if (this.celkemSluzbyZalohyServisSDph != null || this.cenaNajemneDUKonecZivotnostiCelkem != null) {
            return (this.celkemSluzbyZalohyServisSDph ?? 0) + Math.round((this.cenaNajemneDUKonecZivotnostiCelkem ?? 0) * CenoTvorba.SAZBA_DPH);
        }
        return null;
    }

    public get celkemNajemSluzbyDN() {
        if (this.celkemSluzbyZalohyServisSDph != null || this.cenaNajemneDNCelkem != null) {
            return (this.celkemSluzbyZalohyServisSDph ?? 0) + (this.cenaNajemneDNCelkem ?? 0);
        }
        return null;
    }

    public get celkemNajemSluzbyDU1Rok() {
        if (this.celkemSluzbyZalohyServisSDph != null || this.cenaNajemneDU1RokCelkem != null) {
            return (this.celkemSluzbyZalohyServisSDph ?? 0) + (this.cenaNajemneDU1RokCelkem ?? 0);
        }
        return null;
    }

    public get celkemNajemSluzbyDUKonecZivotnosti() {
        if (this.celkemSluzbyZalohyServisSDph != null || this.cenaNajemneDUKonecZivotnostiCelkem != null) {
            return (this.celkemSluzbyZalohyServisSDph ?? 0) + (this.cenaNajemneDUKonecZivotnostiCelkem ?? 0);
        }
        return null;
    }

    // //
    public cenaSluzebBezDph?: number;
}
