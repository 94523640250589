import store from '@/store';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { BaseService } from './base.service';

import { ModAuthGetter } from '@/store/auth/auth.getter';
import { ModAppGetter } from '@/store/app/app.getter';

export class BaseApiService extends BaseService {
    protected store = store;

    protected async get<T>(url: string, config?: AxiosRequestConfig | undefined): Promise<AxiosResponse<T>> {
        return super.get<T>(store.getters[ModAppGetter.APIURL] + '/' + url, {
            ...config,
            headers: {
                ...config?.headers,
                Authorization: this.store.getters[ModAuthGetter.AUTH_HEADER],
                TimeVersion: this.store.getters[ModAppGetter.CURR_TIMEVERSION]?.id || ''
            }
        });
    }

    protected async post<T>(url: string, data?: any, config?: AxiosRequestConfig | undefined): Promise<AxiosResponse<T>> {
        return super.post<T>(store.getters[ModAppGetter.APIURL] + '/' + url, data, {
            ...config,
            headers: {
                ...config?.headers,
                Authorization: this.store.getters[ModAuthGetter.AUTH_HEADER],
                TimeVersion: this.store.getters[ModAppGetter.CURR_TIMEVERSION]?.id || ''
            }
        });
    }

    protected async patch<T>(url: string, data?: any, config?: AxiosRequestConfig | undefined): Promise<AxiosResponse<T>> {
        return super.patch<T>(store.getters[ModAppGetter.APIURL] + '/' + url, data, {
            ...config,
            headers: {
                ...config?.headers,
                Authorization: this.store.getters[ModAuthGetter.AUTH_HEADER],
                TimeVersion: this.store.getters[ModAppGetter.CURR_TIMEVERSION]?.id || ''
            }
        });
    }

    protected async delete(url: string, config?: AxiosRequestConfig | undefined): Promise<AxiosResponse> {
        return super.delete(store.getters[ModAppGetter.APIURL] + '/' + url, {
            ...config,
            headers: {
                ...config?.headers,
                Authorization: this.store.getters[ModAuthGetter.AUTH_HEADER],
                TimeVersion: this.store.getters[ModAppGetter.CURR_TIMEVERSION]?.id || ''
            }
        });
    }
}
