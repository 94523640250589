import { ActionContext, CommitOptions, DispatchOptions } from 'vuex';
import { Modules } from '../modules';
import { AuthActionsTypes } from './auth.action';
import { AuthGetterTypes } from './auth.getter';
import { AuthMutationTypes } from './auth.mutation';
import { AuthState } from './auth.state';

export enum AuthAction {
    LOGIN = 'LOGIN',
    LOGOUT = 'LOGOUT',
    RE_LOGIN = 'RE_LOGIN',

    INIT_AUTH = 'INIT_AUTH',
    INIT_USER = 'INIT_USER',
    INIT_RIGHT = 'INIT_RIGHT'
}

export enum ModAuthAction {
    LOGIN = Modules.AUTH + '/' + AuthAction.LOGIN,
    LOGOUT = Modules.AUTH + '/' + AuthAction.LOGOUT,
    RE_LOGIN = Modules.AUTH + '/' + AuthAction.RE_LOGIN,

    INIT_AUTH = Modules.AUTH + '/' + AuthAction.INIT_AUTH,
    INIT_USER = Modules.AUTH + '/' + AuthAction.INIT_USER,
    INIT_RIGHT = Modules.AUTH + '/' + AuthAction.INIT_RIGHT
}

export type AugmentedActionContext = Omit<ActionContext<AuthState, any>, 'commit' | 'getters' | 'dispatch'> & {
    commit<K extends keyof AuthMutationTypes, P extends Parameters<AuthMutationTypes[K]>[1]>(
        key: K,
        payload?: P,
        options?: CommitOptions
    ): ReturnType<AuthMutationTypes[K]>;
} & {
    dispatch<K extends keyof AuthActionsTypes, P = Parameters<AuthActionsTypes[K]>[1]>(
        key: K,
        payload?: P,
        options?: DispatchOptions
    ): ReturnType<AuthActionsTypes[K]>;
} & {
    getters: {
        [K in keyof AuthGetterTypes]: ReturnType<AuthGetterTypes[K]>;
    };
};
