import { NemovitostCv } from '@/models/nemovitost';
import { PozadavekNaPripravuSmlouvy } from '@/models/pnps';
import { uniqBy } from 'lodash';
import { MutationTree } from 'vuex';
import { Modules } from '../modules';
import { DraftState } from './draft.state';

export enum DraftMutation {
    SET_DRAFT = 'SET_DRAFT',
    ADD_NEMOVITOST = 'ADD_NEMOVITOST',
    DEL_NEMOVITOST = 'DEL_NEMOVITOST',

    CLEAR_NEMOVITOST = 'CLEAR_NEMOVITOST',

    SET_PNPS = 'SET_PNPS',

    CLEAR_ALL = 'CLEAR_ALL'
}

export enum ModDraftMutation {
    SET_DRAFT = Modules.DRAFT + '/' + DraftMutation.SET_DRAFT,
    ADD_NEMOVITOST = Modules.DRAFT + '/' + DraftMutation.ADD_NEMOVITOST,
    DEL_NEMOVITOST = Modules.DRAFT + '/' + DraftMutation.DEL_NEMOVITOST,

    CLEAR_NEMOVITOST = Modules.DRAFT + '/' + DraftMutation.CLEAR_NEMOVITOST,

    SET_PNPS = Modules.DRAFT + '/' + DraftMutation.SET_PNPS,

    CLEAR_ALL = Modules.DRAFT + '/' + DraftMutation.CLEAR_ALL
}

export interface DraftMutationTypes {
    [DraftMutation.SET_DRAFT](
        state: DraftState,
        payload: {
            draft: boolean;
        }
    ): void;

    [DraftMutation.ADD_NEMOVITOST](
        state: DraftState,
        payload: {
            nemovitosts: NemovitostCv[];
        }
    ): void;

    [DraftMutation.DEL_NEMOVITOST](
        state: DraftState,
        payload: {
            id: number;
        }
    ): void;

    [DraftMutation.CLEAR_NEMOVITOST](state: DraftState): void;

    [DraftMutation.SET_PNPS](state: DraftState, payload: { pnps: PozadavekNaPripravuSmlouvy | null }): void;

    [DraftMutation.CLEAR_ALL](state: DraftState): void;
}

export const obpMutations: MutationTree<DraftState> & DraftMutationTypes = {
    [DraftMutation.SET_DRAFT]: (state, payload) => {
        state.pnpsDraft = payload.draft;

        state.pnps = null;
    },

    [DraftMutation.ADD_NEMOVITOST]: (state, payload) => {
        state.nemovitosts = uniqBy([...state.nemovitosts, ...payload.nemovitosts], v => v.nemovitostId);
    },

    [DraftMutation.DEL_NEMOVITOST]: (state, payload) => {
        state.nemovitosts = [...state.nemovitosts.filter(f => f.nemovitostId !== payload.id)];
    },

    [DraftMutation.CLEAR_NEMOVITOST]: state => {
        state.nemovitosts = [];
    },

    [DraftMutation.SET_PNPS]: (state, payload) => {
        state.pnpsDraft = false;

        state.pnps = payload.pnps;
        state.nemovitosts = [];
    },

    [DraftMutation.CLEAR_ALL]: state => {
        state.pnpsDraft = false;
        state.pnps = null;

        state.nemovitosts = [];
    }
};
