










import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CmsCardTitleComponent extends Vue {
    @Prop() title!: string;
    @Prop({ default: 'mdi-square-medium-outline' }) icon!: string;
    @Prop({ type: Boolean }) hideIcon!: boolean;
    @Prop({ type: [Number, String], default: 6 }) marginBottom!: number | string;
    @Prop() deleteVisible!: boolean;
    @Prop() disableUpperCase!: boolean;
}
