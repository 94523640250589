























































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Partner } from '@/models/partner';
import { ODataResult } from '@/models/odata.results';
import { PlainObject, QueryOptions } from 'odata-query';
import { intersection } from 'lodash';

@Component
export default class PartnerListPage extends Vue {
    data: ODataResult<Partner> | null = null;

    filter = { id: null, ico: '', search: '', katalogoveCislo: '', smlouva: '', telefon: '', email: '' };

    isLoading = false;

    defaultFilter = { nazev: { ne: null } };

    options = {
        page: 1,
        itemsPerPage: 15,
        sortBy: ['id'],
        sortDesc: [false],
        multiSort: true,
        mustSort: false
    };

    headers = [
        { text: 'ID', value: 'id' },
        { text: 'Název partnera', value: 'nazev' },
        { text: 'IČO', value: 'ico' },
        { text: 'Telefon', value: 'telefon1' },
        { text: 'Email', value: 'email' }
    ];

    zalozit() {
        this.$root
            .$confirm('Založení partnera', '', {
                confirmText: 'Občan',
                cancelText: 'Firma',
                confirmColor: 'success',
                cancelColor: 'primary',
                thirdOption: true
            })
            .then(a => {
                if (a) {
                    this.$router.push({ name: 'obcan.partner.new' });
                } else if (a == false) {
                    this.$router.push({ name: 'firma.partner.new' });
                }
            });
    }

    @Watch('$route.query', { immediate: true })
    onChangeQueryParams() {
        if (this.$route.query.filter) {
            this.filter = JSON.parse(String(this.$route.query.filter));
        } else {
            this.setDefaultFilter();
        }

        if (this.$route.query.options) {
            this.options = JSON.parse(String(this.$route.query.options));
        } else {
            this.setDefaultOptions();
        }

        this.getData();
    }

    async getData(): Promise<void> {
        this.isLoading = true;

        const filter: PlainObject[] = [];

        Object.assign(filter, this.defaultFilter);

        if (this.filter.id !== null) {
            filter.push({ id: { eq: this.filter.id } });
        }

        if (this.filter.search) {
            filter.push({ 'tolower(unaccentNazev)': { contains: this.$lowerUnaccent(this.filter.search) } });
        }

        if (this.filter.ico) {
            filter.push({ ico: { contains: this.filter.ico } });
        }
        const filterTelefon: any[] = [];
        if (this.filter.telefon) {
            filterTelefon.push({ telefon1: { contains: this.filter.telefon } });
            filterTelefon.push({ osobas: { any: { tel: { contains: this.filter.telefon } } } });
            filter.push({ or: filterTelefon });
        }

        const filterEmail: any[] = [];
        if (this.filter.email) {
            filterEmail.push({ email: { contains: this.filter.email } });
            filterEmail.push({ osobas: { any: { email: { contains: this.filter.email } } } });
            filter.push({ or: filterEmail });
        }

        let partnerIds: number[] | null = null;
        if (this.filter.katalogoveCislo) {
            partnerIds = (
                await this.$odata.function<ODataResult<number>>('partner', {
                    func: { GetPartnersByKatalogoveCislo: { katalogoveCislo: this.filter.katalogoveCislo } }
                })
            ).data.value;
        }

        if (this.filter.smlouva) {
            if (!partnerIds) partnerIds = [];
            const res = (
                await this.$odata.function<ODataResult<number>>('partner', {
                    func: { GetPartnersBySmlouva: { smlouva: this.filter.smlouva } }
                })
            ).data.value;

            partnerIds = intersection(partnerIds, res);
        }

        if (partnerIds) {
            if (partnerIds.length) {
                filter.push({ id: { in: partnerIds } });
            } else {
                this.data = null;
                this.isLoading = false;
                return;
            }
        }

        const query: Partial<QueryOptions<Partner>> = {
            select: ['id', 'nazev', 'ico', 'telefon1', 'email', 'unaccentNazev'],
            expand: { osobas: {} },
            top: this.options.itemsPerPage,
            count: true,
            filter: filter,
            skip: (this.options.page - 1) * this.options.itemsPerPage,
            orderBy: this.options.sortBy.map((sort, index) => sort + (this.options.sortDesc[index] ? ' desc' : '')) as any
        };

        try {
            this.data = (await this.$odata.getList('partnerView', query)).data;
        } catch (e) {
            this.$snackbar.error(e as any);
        } finally {
            this.isLoading = false;
        }
    }

    async onFilterSubmit() {
        this.options.page = 1;
        this.prepareFilter();
    }

    async resetFilter() {
        this.setDefaultFilter();

        this.options.page = 1;
        this.prepareFilter();
    }

    setDefaultFilter() {
        this.filter = {
            id: null,
            ico: '',
            search: '',
            katalogoveCislo: '',
            smlouva: '',
            telefon: '',
            email: ''
        };
    }

    setDefaultOptions() {
        this.options = {
            page: 1,
            itemsPerPage: 15,
            sortBy: ['id'],
            sortDesc: [false],
            multiSort: true,
            mustSort: false
        };
    }

    prepareFilter() {
        if (this.$route.query.filter) {
            this.$router
                .push({
                    path: this.$route.path,
                    query: { filter: JSON.stringify(this.filter), options: JSON.stringify(this.options) }
                })
                .catch(() => {});
        } else {
            this.$router
                .replace({
                    path: this.$route.path,
                    query: { filter: JSON.stringify(this.filter), options: JSON.stringify(this.options) }
                })
                .catch(() => {});
        }
    }

    rowClick(e: any): void {
        this.$router.push({ name: 'partner.detail', params: { id: e.id } });
    }
}
