var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"pa-2"},[_c('v-data-table',{staticClass:"pointer-class",attrs:{"headers":_vm.headers,"items":_vm.data,"item-class":_vm.getItemClass},scopedSlots:_vm._u([{key:"item.tentoDodatek",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"ripple":false,"value":_vm.getInfoTentoDodatek(item),"readonly":""}})]}},{key:"item.editButton",fn:function(ref){
var item = ref.item;
return [_c('cms-btn',{attrs:{"color":"primary","readonly":_vm.readonly},on:{"click":function($event){return _vm.edit(item)}}},[_vm._v("Upravit")])]}},{key:"item.datumPredani",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$formatDate(item.datumPredani, 'dd. MM. yyyy'))+" ")]}},{key:"item.datumPrevzeti",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$formatDate(item.datumPrevzeti, 'dd. MM. yyyy'))+" ")]}},{key:"item.nemovitost.podlazis",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getPodlazis(item.nemovitost))+" ")]}}])}),_c('cms-prilohy',{attrs:{"readonly":_vm.readonly},model:{value:(_vm.editPnps.spravaSoubors),callback:function ($$v) {_vm.$set(_vm.editPnps, "spravaSoubors", $$v)},expression:"editPnps.spravaSoubors"}}),_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.editDialogShow),callback:function ($$v) {_vm.editDialogShow=$$v},expression:"editDialogShow"}},[(_vm.editPnpsNemovitostVazba != null)?_c('v-card',{staticClass:"pa-4"},[_c('cms-date-field',{attrs:{"label":"Datum předání"},model:{value:(_vm.editDatumPredani),callback:function ($$v) {_vm.editDatumPredani=$$v},expression:"editDatumPredani"}}),_c('cms-date-field',{attrs:{"label":"Datum převzetí"},model:{value:(_vm.editDatumPrevzeti),callback:function ($$v) {_vm.editDatumPrevzeti=$$v},expression:"editDatumPrevzeti"}}),_c('v-card-actions',[_c('v-spacer'),_c('cms-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.okDialog()}}},[_vm._v("Ok")]),_c('cms-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v("Zavřít")])],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }