








































import { ODataResult, Lokalita, Kraj, Obec, CastObce, GridOptions } from '@/models';
import { NemovitostFilter } from '@/models/nemovitost';
import LokalitaListComponent from '@/ui/components/lokalita/lokalita-list.component.vue';
import NemovitostFilterComponent from '@/ui/components/nemovitost/nemovitost-filter.component.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
        'cms-lokalita-list': LokalitaListComponent,
        'cms-nemovitost-filter': NemovitostFilterComponent
    }
})
export default class LokalitaListPage extends Vue {
    data: ODataResult<Lokalita> | null = null;

    zobrazenyVsechnyLokality = false;

    defaultFilter = { obchodniNazev: { ne: null }, pocetNemovitosti: { gt: 0 } };

    zmenitFiltrLokalit() {
        this.defaultFilter = { obchodniNazev: { ne: null }, pocetNemovitosti: { gt: this.zobrazenyVsechnyLokality ? 0 : -1 } };
        this.fetchData();
        this.zobrazenyVsechnyLokality = !this.zobrazenyVsechnyLokality;
    }

    options: GridOptions = {
        page: 1,
        itemsPerPage: 25,
        sortBy: ['cislo'],
        sortDesc: [true],
        multiSort: true,
        mustSort: false
    };

    filter: NemovitostFilter = { pronajmutelne: 1 };

    isLoading = false;

    async fetchData(): Promise<void> {
        this.isLoading = true;

        const skip = (this.options.page - 1) * this.options.itemsPerPage;
        const orderBy = this.options.sortBy.map((sort, index) => sort + (this.options.sortDesc[index] ? ' desc' : '')) as any;

        const filter: any[] = [this.defaultFilter];

        this.data = (
            await this.$odata.getList<Lokalita>('lokalita', {
                top: this.options.itemsPerPage > 0 ? this.options.itemsPerPage : 100,
                count: true,
                filter: filter,
                skip,
                orderBy
            })
        ).data;

        this.isLoading = false;
    }

    async filterFormSubmit(): Promise<void> {
        this.navigateNemovitost(this.filter);
    }

    async resetFilter(): Promise<void> {
        this.filter = { pronajmutelne: 1 };
    }

    optionsUpdate(opt: GridOptions): void {
        this.options = { ...opt };

        this.fetchData();
    }

    rowClick(e: any): void {
        this.navigateNemovitost({
            lokalitaId: e.id,
            pronajmutelne: 1
        });
    }

    navigateNemovitost(filter: NemovitostFilter) {
        this.$router.push({
            name: 'nemovitost.list',
            query: {
                filter: JSON.stringify(filter)
            }
        });
    }
}
