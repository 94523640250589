




import { mapGetters } from 'vuex';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Modules } from '@/store/modules';
import { AuthGetter } from '@/store/auth/auth.getter';

import CmsPasswordFieldComponent from '@/ui/components/inputs/password-field.component.vue';
import { AppGetter } from '@/store/app/app.getter';
import { TimeVersion } from '@/models';

@Component({
    components: {
        'cms-password-field': CmsPasswordFieldComponent
    },
    computed: {
        ...mapGetters(Modules.AUTH, [AuthGetter.TOKEN]),
        ...mapGetters(Modules.APP, [AppGetter.CURR_TIMEVERSION])
    }
})
export default class DesignerPage extends Vue {
    TOKEN!: string;
    CURR_TIMEVERSION!: TimeVersion;

    url = '';

    @Watch('TOKEN', { immediate: true })
    onChangeToken() {
        if (this.TOKEN) {
            this.url =
                (process.env.NODE_ENV == 'production' ? '' : 'https://localhost:6001') +
                '/app/designer?llt=' +
                this.TOKEN +
                '&reportId=' +
                this.$route.params.reportId +
                '&cv=' +
                this.CURR_TIMEVERSION.id;
        }
    }

    get height() {
        return window.innerHeight - 64 - 32 + 'px';
    }
}
