





























import { GridOptions, Lokalita, ODataResult } from '@/models';

import LokalitaListComponent from '@/ui/components/lokalita/lokalita-list.component.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
        'cms-lokalita-list': LokalitaListComponent
    }
})
export default class LokalitaCiselnikListPage extends Vue {
    data: ODataResult<Lokalita> | null = null;
    isLoading = false;
    defaultFilter = { obchodniNazev: { ne: null }, pocetNemovitosti: { gt: 0 } };
    zobrazenyVsechnyLokality = false;

    options: GridOptions = {
        page: 1,
        itemsPerPage: 25,
        sortBy: ['cislo'],
        sortDesc: [true],
        multiSort: true,
        mustSort: false
    };

    headers = [
        { text: 'Číslo', value: 'cislo' },
        { text: 'Obchodní název', value: 'obchodniNazev' },
        { text: 'Kraj', value: 'kraj' },
        { text: 'Obec', value: 'obec' }
    ];

    optionsUpdate(opt: GridOptions): void {
        this.options = { ...opt };

        this.fetchData();
    }
    onChangeFilter(i: any) {
        this.zobrazenyVsechnyLokality = true;
        this.defaultFilter.pocetNemovitosti.gt = -1;
        this.filter.id = i;
        this.fetchData();
    }

    filter = {
        id: -1
    };

    resetFilter() {
        this.zobrazenyVsechnyLokality = false;
        this.defaultFilter.pocetNemovitosti.gt = 0;
        this.filter.id = -1;
        this.fetchData();
        this.zobrazenyVsechnyLokality = false;
    }

    zmenitFiltrLokalit() {
        this.defaultFilter = { obchodniNazev: { ne: null }, pocetNemovitosti: { gt: this.zobrazenyVsechnyLokality ? 0 : -1 } };
        this.fetchData();
        this.zobrazenyVsechnyLokality = !this.zobrazenyVsechnyLokality;
    }

    async onFilterSubmit(): Promise<void> {
        this.options.page = 1;

        this.fetchData();
    }

    async fetchData(): Promise<void> {
        this.isLoading = true;

        const skip = (this.options.page - 1) * this.options.itemsPerPage;
        const orderBy = this.options.sortBy.map((sort, index) => sort + (this.options.sortDesc[index] ? ' desc' : '')) as any;

        const filter: any[] = [this.defaultFilter];

        if (this.filter.id >= 0) filter.push({ id: { eq: this.filter.id } });

        this.data = (
            await this.$odata.getList<Lokalita>('lokalita', {
                top: this.options.itemsPerPage > 0 ? this.options.itemsPerPage : 100,
                count: true,
                filter: filter,
                skip,
                orderBy
            })
        ).data;

        this.isLoading = false;
    }

    rowClick(x: any) {
        this.$router.push({
            name: 'lokalita.detail',
            params: { lokId: x.id }
        });
    }
}
