







































































import { ODataResult } from '@/models';
import R from '@/models/resources';
import { Component, Mixins, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { VForm } from '@/models/vue-components';
import NotSavedComponent from '../not-saved.component';

@Component
export default class CmsListComponent extends Mixins(Vue, NotSavedComponent) {
    @Ref('form') form!: VForm;

    @Prop({ required: true }) title!: string;
    @Prop({ required: true }) controller!: string;
    @Prop({ required: true }) headers!: any;
    @Prop({ type: Boolean, default: false }) view!: boolean;
    @Prop() value!: { id: number };
    @Prop() icon!: string;
    @Prop() expand: any;

    data: ODataResult<any> | null = null;

    isLoading = false;
    isSaving = false;
    dialog = false;

    options = {
        page: 1,
        itemsPerPage: 15,
        sortBy: ['nazev'],
        sortDesc: [false],
        multiSort: true,
        mustSort: false
    };

    filter = {
        nazev: ''
    };

    get listHeaders() {
        return [...this.headers, { text: '', value: 'delete', sortable: false, cellClass: 'list-td-delete', align: 'center' }];
    }

    async fetchData(): Promise<void> {
        this.isLoading = true;

        const skip = (this.options.page - 1) * this.options.itemsPerPage;
        const orderBy = this.options.sortBy.map((sort, index) => sort + (this.options.sortDesc[index] ? ' desc' : '')) as any;

        const filter: any[] = [];
        if (this.filter.nazev) filter.push({ 'toLower(nazev)': { contains: this.filter.nazev.toLowerCase() } });

        this.$odata
            .getList(this.controller + (this.view ? 'View' : ''), {
                expand: this.expand,
                top: this.options.itemsPerPage > 0 ? this.options.itemsPerPage : 100,
                count: true,
                filter: filter,
                skip,
                orderBy
            })
            .then(res => {
                this.data = res.data;
            });

        this.isLoading = false;
    }

    @Watch('dialog')
    onChangeDialog(val: any) {
        val || this.close();
    }

    pridat(): void {
        this.form?.resetValidation();
        this.dialog = true;
    }

    editItem(item: any): void {
        this.$emit('input', Object.assign({}, item));
        this.form?.resetValidation();
        this.dialog = true;
    }

    deleteItem(item: any): void {
        this.$root
            .$confirm(this.title, 'Opravdu chcete vybranou položku odstranit?')
            .then(async a => {
                if (a) {
                    await this.$odata.remove(this.controller.replaceAll('View', ''), item.id);
                    this.$snackbar.success(R.Odstraneno);

                    this.$emit('input', { id: '' });
                    await this.fetchData();
                }
            })
            .catch(x => this.$snackbar.error(R.NelzeSmazat));
    }

    async save(): Promise<void> {
        if ((this.$refs.form as any).validate()) {
            this.isSaving = true;

            if (!this.value['id']) await this.$odata.create(this.controller, this.value);
            else await this.$odata.update(this.controller, this.value.id, this.value);

            this.$snackbar.showSnackbar({ text: R.Ulozeno, color: 'success' });
            this.isSaving = false;

            this.close();

            this.$nextTick(async () => {
                await this.fetchData();
            });
        }
    }

    close(): void {
        this.dialog = false;

        this.$nextTick(() => {
            this.$emit('input', { id: '' });
        });
    }
}
