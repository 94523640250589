




import axios from 'axios';
import { mapGetters } from 'vuex';
import { Component, Vue } from 'vue-property-decorator';
import { ModAppMutation } from './store/app/app.mutation';
import { Modules } from './store/modules';
import { AppGetter, ModAppGetter } from './store/app/app.getter';
import store from './store';

@Component({ computed: { ...mapGetters(Modules.APP, [AppGetter.APIURL]) } })
export default class App extends Vue {
    APIURL!: string;

    async created() {
        if (!this.APIURL) {
            try {
                const res = await axios.get<any>(
                    process.env.NODE_ENV == 'production' ? '/api/config' : 'https://localhost:6001/api/config'
                );
                this.$store.commit(ModAppMutation.SET_CONFIG.toString(), {
                    apiUrl: res.data.apiUrl,
                    fileUrl: res.data.fileUrl
                });
            } catch {
                this.$store.commit(ModAppMutation.SET_CONFIG.toString(), {
                    apiUrl: 'http://localhost:5000',
                    fileUrl: 'http://localhost:1238'
                });
            }
        }
    }

    mounted() {
        axios.interceptors.request.use(config => {
            if (config.url?.startsWith(this.$store.getters[ModAppGetter.APIURL])) {
                config.withCredentials = false;
                const bearer = localStorage.getItem('cms_token');
                if (bearer != null && bearer != undefined && bearer.length > 0) {
                    config.headers['Authorization'] = `Bearer ${bearer}`;
                }

                return config;
            }

            return config;
        });

        axios
            .get<string>(process.env.NODE_ENV == 'production' ? '/api/version' : 'https://localhost:6001/api/version')
            .then(a => {
                this.$store.commit(ModAppMutation.SET_APPVERSION.toString(), { version: a.data });
            })
            .catch(() => {
                this.$store.commit(ModAppMutation.SET_APPVERSION.toString(), { version: 'DEBUG' });
            });
    }
}
