







































import { Lokalita, ODataResult } from '@/models';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class LokalitaListSmallComponent extends Vue {
    @Prop({ required: true }) data!: ODataResult<Lokalita>;
    @Prop({ required: true }) isLoading!: boolean;

    @Prop({ required: false }) headers?: any[];

    get cols(): any[] {
        return this.headers || this.defaultHeaders;
    }

    defaultHeaders = [
        { text: 'Číslo', value: 'cislo' },
        { text: 'Obchodní název', value: 'obchodniNazev' },
        { text: 'Kraj', value: 'kraj' },
        { text: 'Obec', value: 'obec' },
        { text: 'Část obce', value: 'castObce' },
        { text: 'Sklady', value: 'pocetSklad', cellClass: 'lokalita-list__typ_nemovitost-cell' },
        { text: 'Kanceláří', value: 'pocetKancelar', cellClass: 'lokalita-list__typ_nemovitost-cell' },
        {
            text: 'Parkovacích stání',
            value: 'pocetParkovaciStani',
            cellClass: 'lokalita-list__typ_nemovitost-cell'
        },
        { text: 'Pozemků', value: 'pocetPozemek', cellClass: 'lokalita-list__typ_nemovitost-cell' },
        { text: 'Ostatní', value: 'pocetOstatni', cellClass: 'lokalita-list__typ_nemovitost-cell' }
    ];
}
