
















































































































import { GridOptions, Lokalita, ODataResult } from '@/models';
import { PozadavekNaPripravuSmlouvy } from '@/models/pnps';
import { QueryOptions } from 'odata-query';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { uniq } from 'lodash';
import { ModAppAction } from '@/store/app/app.action';
import { ModDraftMutation } from '@/store/draft/draft.mutation';
import { mapGetters } from 'vuex';
import { Modules } from '@/store/modules';
import { AppGetter } from '@/store/app/app.getter';
import { eStavZadost } from '@/models/stav-zadost';
import { ePrava } from '@/models/user-right';

@Component({
    computed: {
        ...mapGetters(Modules.APP, [AppGetter.CURR_TIMEVERSION])
    }
})
export default class PnpsListPage extends Vue {
    data: ODataResult<PozadavekNaPripravuSmlouvy> | null = null;
    lokalitas: Lokalita[] = [];
    isLoading = false;

    zpracovatelPravo = ePrava.JeZpracovatel;

    dialogSpravaNemovitosti = false;

    options: GridOptions = {
        page: 1,
        itemsPerPage: 15,
        sortBy: ['obchodniPripad.cisloObp'],
        sortDesc: [false],
        multiSort: true,
        mustSort: false
    };

    headers = [
        { text: 'Lokalita', value: 'obchodniPripad.lokalitaNazev', sortable: false },
        { text: 'Číslo smlouvy', value: 'cisloSmlouvyText' },
        { text: 'Stav', value: 'stavZadost.nazev' },
        { text: 'Partner', value: 'najemcePartner.nazev' },
        { text: 'Žádáno', value: 'zadano' },
        { text: 'Plánovaný termín podpisu', value: 'datumPodpisuPlan' },
        { text: 'Požadoval', value: 'zadatelUzivatel.fullName', sortable: false },
        { text: 'Zpracovatel', value: 'zpracovatelUzivatel.fullName', sortable: false }
    ];

    @Watch('$route.query', { immediate: true })
    onChangeRouteQuery() {
        if (this.$route.query.filter) {
            this.filter = JSON.parse(String(this.$route.query.filter));
        } else {
            this.setDefaultFilter();
        }

        if (this.$route.query.options) {
            this.options = JSON.parse(String(this.$route.query.options));
        } else {
            this.setDefaultOptions();
        }

        this.fetchData();
    }

    getLokalitaNazev(lokalitaId: number) {
        return (this.lokalitas || []).find(f => f.id == lokalitaId)?.nazev;
    }

    async fetchData(): Promise<void> {
        this.isLoading = true;

        const filter: any[] = [];

        if (!this.filter.vcetneDodatku) filter.push({ parentPnpsId: { eq: null } });
        if (this.filter.lokalitaId) filter.push({ obchodniPripad: { lokalitaId: { eq: +this.filter.lokalitaId } } });
        if (this.filter.partnerId) filter.push({ najemcePartnerId: { eq: +this.filter.partnerId } });
        if (this.filter.cisloSmlouvy) filter.push({ cisloSmlouvyText: { contains: this.filter.cisloSmlouvy } });
        if (this.filter.zadatelUzivatelId) filter.push({ zadatelUzivatelId: { eq: +this.filter.zadatelUzivatelId } });
        if (this.filter.zpracovatelUzivatelId) filter.push({ zpracovatelUzivatelId: { eq: +this.filter.zpracovatelUzivatelId } });

        const selectedStavy = [];
        if (this.filter.stavNove) selectedStavy.push(2);
        if (this.filter.stavAkceptovano) selectedStavy.push(3);
        if (this.filter.stavOdeslanoKlientovi) selectedStavy.push(7);
        if (this.filter.stavPodepsano) selectedStavy.push(8);
        if (this.filter.stavStorno) selectedStavy.push(9);
        if (selectedStavy.length) {
            filter.push({ stavZadostId: { in: selectedStavy } });
        }

        if (!this.filter.stavStorno) {
            filter.push({ stavZadostId: { ne: 9 } });
        }

        const query: Partial<QueryOptions<PozadavekNaPripravuSmlouvy>> = {
            expand: {
                obchodniPripad: {},
                najemcePartner: { select: ['nazev'] },
                zadatelUzivatel: { select: ['fullName'] },
                zpracovatelUzivatel: { select: ['fullName'] },
                stavZadost: { select: ['nazev'] }
            },
            top: this.options.itemsPerPage,
            count: true,
            filter,
            skip: (this.options.page - 1) * this.options.itemsPerPage,
            orderBy: this.options.sortBy.map(
                (sort, index) => sort.replaceAll('.', '/') + (this.options.sortDesc[index] ? ' desc' : '')
            ) as any
        };

        const tmpData = (await this.$odata.getList<PozadavekNaPripravuSmlouvy>('pnps', query)).data;

        if (tmpData['@odata.count']) {
            this.lokalitas = (
                await this.$odata.getList<Lokalita>('lokalitaView', {
                    filter: { id: { in: uniq(tmpData.value.map(m => m.obchodniPripad?.lokalitaId)) } }
                })
            ).data.value;
        } else {
            this.lokalitas = [];
        }

        this.data = tmpData;

        this.isLoading = false;
    }

    onFilterSubmit(): void {
        this.options.page = 1;
        this.prepareFilter();
    }

    async vytvorit() {
        this.dialogSpravaNemovitosti = false;

        await this.$store.dispatch(ModAppAction.INIT_PUBLISHED.toString());
        this.$store.commit(ModDraftMutation.SET_DRAFT.toString(), { draft: true });

        this.$router.push({ name: 'lokalita.list' });
    }

    filter = {
        lokalitaId: 0,
        partnerId: 0,
        zadatelUzivatelId: 0,
        zpracovatelUzivatelId: 0,
        stavNove: false,
        stavAkceptovano: false,
        stavOdeslanoKlientovi: false,
        stavPodepsano: false,
        vcetneDodatku: false,
        stavStorno: false,
        cisloSmlouvy: ''
    };

    setDefaultFilter() {
        this.filter = {
            lokalitaId: 0,
            partnerId: 0,
            zadatelUzivatelId: 0,
            zpracovatelUzivatelId: 0,
            stavNove: false,
            stavAkceptovano: false,
            stavOdeslanoKlientovi: false,
            stavPodepsano: false,
            vcetneDodatku: false,
            stavStorno: false,
            cisloSmlouvy: ''
        };
    }

    setDefaultOptions() {
        this.options = {
            page: 1,
            itemsPerPage: 15,
            sortBy: ['cisloSmlouvyText'],
            sortDesc: [false],
            multiSort: true,
            mustSort: false
        };
    }

    resetFilter(): void {
        this.setDefaultFilter();

        this.options.page = 1;
        this.prepareFilter();
    }

    prepareFilter() {
        if (this.$route.query.filter) {
            this.$router
                .push({
                    path: this.$route.path,
                    query: { filter: JSON.stringify(this.filter), options: JSON.stringify(this.options) }
                })
                .catch(() => {});
        } else {
            this.$router
                .replace({
                    path: this.$route.path,
                    query: { filter: JSON.stringify(this.filter), options: JSON.stringify(this.options) }
                })
                .catch(() => {});
        }
    }

    rowClick(pnps: PozadavekNaPripravuSmlouvy) {
        if (pnps.parentPnpsId) {
            this.$router.push({
                name: 'podpozadavek.pnps.detail',
                params: { pnpsId: String(pnps.parentPnpsId), podPnpsId: String(pnps.id) }
            });
        } else {
            this.$router.push({
                name: 'pnps.detail',
                params: { pnpsId: String(pnps.id) }
            });
        }
    }

    getItemClass(item: any): string {
        const stav = item.stavZadostId;
        const r =
            stav === eStavZadost.Akceptovano
                ? 'stav-akceptovano'
                : stav === eStavZadost.Podepsano
                ? 'stav-podepsano'
                : stav === eStavZadost.Odeslano
                ? 'stav-odeslano'
                : '';
        return r;
    }

    //TODO: upravid kod, nevolalo se bez tech parametru
    updataPnpsDodatkem(): void {
        this.$odata
            .function('pnps', {
                key: 1,
                func: { updatePnpsDodatkem: { testId: 1 } }
            })
            .then(() => {
                this.$snackbar.success('Ok');
            })
            .catch(i => {
                alert(i);
            });
    }
}
