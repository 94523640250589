













































































































































































































import { CenoTvorba } from '@/models/nemovitost';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class PnpsCenoTvorbaComponent extends Vue {
    @Prop() value!: CenoTvorba;
    @Prop() readonly!: boolean;

    cenoTvorba: CenoTvorba | null = null;
    isFixCenaSluzbyZaklad = false;
    isFixCenaSluzbySazba1 = false;
    isFixCenaSluzbySazba2 = false;
    isFixCenaServisniPoplatek = false;

    @Watch('value', { immediate: true })
    onChangeValue() {
        this.cenoTvorba = new CenoTvorba(this.value);
    }

    @Watch('isFixCenaSluzbyZaklad')
    onChangeIsFixCenaSluzbyZaklad(checked: boolean) {
        if (!checked && this.cenoTvorba != null) {
            this.cenoTvorba.cenaSluzbySazbaZakladFixni = null;
            this.onChangeCenoTvorba();
        }
    }

    @Watch('isFixCenaSluzbySazba1')
    onChangeIsFixCenaSluzbySazba1(checked: boolean) {
        if (!checked && this.cenoTvorba != null) {
            this.cenoTvorba.cenaSluzbySazba1Fixni = null;
            this.onChangeCenoTvorba();
        }
    }

    @Watch('isFixCenaSluzbySazba2')
    onChangeIsFixCenaSluzbySazba2(checked: boolean) {
        if (!checked && this.cenoTvorba != null) {
            this.cenoTvorba.cenaSluzbySazba2Fixni = null;
            this.onChangeCenoTvorba();
        }
    }

    @Watch('isFixCenaServisniPoplatek')
    onChangeIsFixCenaServisniPoplatek(checked: boolean) {
        if (!checked && this.cenoTvorba != null) {
            this.cenoTvorba.cenaServisniPoplatekFixni = null;
            this.onChangeCenoTvorba();
        }
    }

    onChangeNajemne() {
        if (this.cenoTvorba == null) return;

        this.onChangeCenoTvorba();
    }

    onChangeCenoTvorba() {
        this.cenoTvorba?.vypocitatCelkoveCeny();
        this.$emit('input', this.cenoTvorba);
    }

    mounted() {
        this.isFixCenaSluzbyZaklad = this.cenoTvorba?.cenaSluzbySazbaZakladFixni != null;
        this.isFixCenaSluzbySazba1 = this.cenoTvorba?.cenaSluzbySazba1Fixni != null;
        this.isFixCenaSluzbySazba2 = this.cenoTvorba?.cenaSluzbySazba2Fixni != null;
        this.isFixCenaServisniPoplatek = this.cenoTvorba?.cenaServisniPoplatekFixni != null;
    }
}
