import { AxiosError } from 'axios';
import { SnackbarService } from '@/services/snackbar.service';

import router from '@/router';

export function handleException(exception: any) {
    const snackbarService = new SnackbarService();
    const error = exception as AxiosError;

    if (error.response) {
        // * Unauthorized
        if (error.response.status === 401) {
            // ToDo: Redirect page -> byl jste odhlašen
            if (router.currentRoute.name !== 'login-page') {
                router.push({ name: 'login-page', query: { redirect: router.currentRoute.fullPath } });
            }

            return;
        }

        // * BadRequest
        if (error.response.status === 400) {
            snackbarService.showSnackbar({ text: error.response?.data.value ?? error.message, color: 'red', timeout: 10000 });
            return;
        }
    }

    // ToDo: Neocekavana chyba
    snackbarService.showSnackbar({ text: 'Neočekávaná chyba', color: 'red' });
}
