



















































































































import { ODataResult, TypNemovitost, StavPronajem, Vybaveni, Budova, GridOptions, Lokalita, TimeVersion } from '@/models';
import { NemovitostCv } from '@/models/nemovitost';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Modules } from '@/store/modules';
import { mapGetters } from 'vuex';
import { ModAppAction } from '@/store/app/app.action';
import { AppGetter } from '@/store/app/app.getter';
import { QueryOptions } from 'odata-query';

import SpNemovitostListComponent from '@/ui/components/nemovitost/sp-nemovitost-list.component.vue';
import { DraftGetter } from '@/store/draft/draft.getter';
import { ModDraftMutation } from '@/store/draft/draft.mutation';

@Component({
    computed: {
        ...mapGetters(Modules.APP, [AppGetter.CURR_TIMEVERSION]),
        ...mapGetters(Modules.DRAFT, [DraftGetter.SIDE_EDIT, DraftGetter.PNPS_DRAFT, DraftGetter.PNPS])
    },
    components: {
        'cms-sp-nemovitost-list': SpNemovitostListComponent
    }
})
export default class SpNemovitostListPage extends Vue {
    data: ODataResult<NemovitostCv> | null = null;
    lokalitas: ODataResult<Lokalita> | null = null;
    typNemovitosts: ODataResult<TypNemovitost> | null = null;
    stavPronajems: ODataResult<StavPronajem> | null = null;
    vybavenis: ODataResult<Vybaveni> | null = null;
    budovas: ODataResult<Budova> | null = null;

    dialogSpravaNemovitosti = false;

    CURR_TIMEVERSION!: TimeVersion;

    @Watch('$route.query', { immediate: true })
    onChangeRouteQuery() {
        if (this.$route.query.filter) {
            this.filter = JSON.parse(String(this.$route.query.filter));
        } else {
            this.setDefaultFilter();
        }

        if (this.$route.query.options) {
            this.options = JSON.parse(String(this.$route.query.options));
        } else {
            this.setDefaultOptions();
        }

        this.fetchData();
    }

    @Watch('$route.params.lokId', { immediate: true })
    async onChangeParams(val: number) {
        this.filter.lokalitaId = +val;

        const lokId = +this.$route.params.lokId;
        if (!isNaN(lokId)) {
            this.budovas = (
                await this.$odata.getList<Budova>('budova', {
                    filter: { cmsLokalitaId: { eq: lokId } }
                })
            ).data;
        } else {
            this.budovas = null;
        }
    }

    @Watch('filter.lokalitaId')
    onChangeLokalita(val: any) {
        if (this.$route.params.lokId != val) {
            this.$router.replace({ name: 'nemovitost.list', params: { lokId: val } });

            this.prepareFilter();
        }
    }

    onChangeLokalitaId() {
        this.$router.push({ name: 'sp.nemovitost.list', params: { lokId: String(this.filter.lokalitaId) } });
    }

    isLoading = false;

    options = {
        page: 1,
        itemsPerPage: 15,
        sortBy: ['katalogoveCislo'],
        sortDesc: [false],
        multiSort: true,
        mustSort: false
    };

    filter = {
        cislo: '',
        oznaceni: '',
        katalogoveCislo: '',
        lokalitaId: 0,
        plochaOd: '',
        plochaDo: '',
        garanceNajemDo: '',
        typNemovitosts: [],
        stavPronajems: [],
        vybavenis: [],
        maxNajem: '',
        budovas: []
    };

    setDefaultOptions() {
        this.options = {
            page: 1,
            itemsPerPage: 15,
            sortBy: ['katalogoveCislo'],
            sortDesc: [false],
            multiSort: true,
            mustSort: false
        };
    }

    setDefaultFilter() {
        const lokId = +this.$route.params.lokId;

        this.filter = {
            cislo: '',
            oznaceni: '',
            katalogoveCislo: '',
            lokalitaId: isNaN(lokId) ? 0 : lokId,
            plochaOd: '',
            plochaDo: '',
            garanceNajemDo: '',
            typNemovitosts: [],
            stavPronajems: [],
            vybavenis: [],
            maxNajem: '',
            budovas: []
        };
    }

    prepareFilter() {
        if (this.$route.query.filter) {
            this.$router
                .push({
                    path: this.$route.path,
                    query: { filter: JSON.stringify(this.filter), options: JSON.stringify(this.options) }
                })
                .catch(() => {});
        } else {
            this.$router
                .replace({
                    path: this.$route.path,
                    query: { filter: JSON.stringify(this.filter), options: JSON.stringify(this.options) }
                })
                .catch(() => {});
        }
    }

    async resetFilter(): Promise<void> {
        this.setDefaultFilter();

        this.options.page = 1;
        this.prepareFilter();
    }

    async mounted(): Promise<void> {
        this.typNemovitosts = (await this.$odata.getList<TypNemovitost>('typnemovitost')).data;
        this.stavPronajems = (await this.$odata.getList<StavPronajem>('stavpronajem')).data;
        this.vybavenis = (await this.$odata.getList<Vybaveni>('vybaveni')).data;
    }

    selected: NemovitostCv[] = [];

    optionsUpdate(opt: GridOptions) {
        this.options = { ...opt };

        this.prepareFilter();
    }

    openDialogSpravaNemovitosti(): void {
        if (this.CURR_TIMEVERSION.casovaHladinaTyp.id != 1) {
            this.dialogSpravaNemovitosti = true;
        } else {
            this.vytvorit(false);
        }
    }

    async vytvorit(refresh = true) {
        this.dialogSpravaNemovitosti = false;

        await this.$store.dispatch(ModAppAction.INIT_PUBLISHED.toString());
        this.$store.commit(ModDraftMutation.SET_DRAFT.toString(), { draft: true });

        if (refresh) {
            this.prepareFilter();
        }
    }

    async fetchData(): Promise<void> {
        this.isLoading = true;

        const skip = (this.options.page - 1) * this.options.itemsPerPage;
        const orderBy = this.options.sortBy.map(
            (sort, index) => sort.replaceAll('.', '/') + (this.options.sortDesc[index] ? ' desc' : '')
        ) as any;

        const filter: any[] = [];
        if (this.filter.typNemovitosts.length) filter.push({ 'nemovitost/typNemovitostId': { in: this.filter.typNemovitosts } });
        if (this.filter.stavPronajems.length) filter.push({ 'nemovitost/stavPronajemId': { in: this.filter.stavPronajems } });
        if (this.filter.budovas.length) filter.push({ 'nemovitost/budovaId': { in: this.filter.budovas } });
        if (this.filter.lokalitaId) filter.push({ 'nemovitost/cmslokalitaid': { eq: +this.filter.lokalitaId } });
        if (this.filter.plochaOd) filter.push({ plocha: { ge: +this.filter.plochaOd } });
        if (this.filter.plochaDo) filter.push({ plocha: { le: +this.filter.plochaDo } });
        if (this.filter.garanceNajemDo) filter.push({ GaranceNajemDo: { le: new Date(this.filter.garanceNajemDo) } });
        if (this.filter.oznaceni) filter.push({ oznaceni: { eq: this.filter.oznaceni } });

        if (this.filter.cislo) filter.push({ cislo: { eq: +this.filter.cislo } });

        if (this.filter.vybavenis.length) filter.push({ vybavenis: { any: { id: { in: this.filter.vybavenis } } } });
        if (this.filter.maxNajem) filter.push({ cenoTvorba: { maxNajem: { le: +this.filter.maxNajem } } });

        const query: Partial<QueryOptions<NemovitostCv>> = {
            top: this.options.itemsPerPage,
            count: true,
            filter: filter,
            skip,
            orderBy,
            select: [
                'id',
                'nemovitostId',
                'nemovitost',
                'plocha',
                'cenoTvorba',
                'cislo',
                'katalogoveCislo',
                'vyskaStrop',
                'garanceNajemDo'
            ],
            expand: {
                nemovitost: {
                    select: ['id', 'typNemovitost', 'budova', 'najemOd', 'najemDo', 'lokalitaJv', 'stavPronajemId'],
                    expand: {
                        typNemovitost: { select: ['id', 'nazev'] },
                        stavPronajem: { select: ['id', 'nazev'] },
                        budova: { select: ['nazev'] },
                        lokalitaJv: { select: ['obchodniNazev'] },
                        lokalita: { expand: { lokalitaCvs: { expand: { lokalitaJvs: {} } } } }
                    }
                },
                cenoTvorba: {}
            }
        };
        this.data = (await this.$odata.getList<NemovitostCv>('nemovitostCv', query)).data;

        this.isLoading = false;
    }

    rowClick(e: NemovitostCv) {
        this.$router.push({ name: 'sp.nemovitost.detail', params: { nemCvId: String(e.id) } });
    }

    addDraft(): void {
        this.$store.commit(ModDraftMutation.ADD_NEMOVITOST.toString(), { nemovitosts: this.selected });
    }
}
