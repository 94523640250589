















































































































































































import { handleException } from '@/helpers';
import { Partner, PartnerFirmaCreate } from '@/models/partner';
import R from '@/models/resources';
import { VForm } from '@/models/vue-components';
import BankAccountComponent from '@/ui/components/inputs/bank-account.component.vue';
import NotSavedComponent from '@/ui/components/not-saved.component';
import PartnerAdresaComponent from '@/ui/components/partner/partner-adresa.component.vue';
import PartnerOsobaComponent from '@/ui/components/partner/partner-osoba.component.vue';
import { Component, Mixins, Ref, Vue, Watch } from 'vue-property-decorator';

import { kontrolaDuplicita, isDuplicita } from './partner-duplicita';

@Component({
    components: {
        'cms-partner-osoba': PartnerOsobaComponent,
        'cms-partner-adresa': PartnerAdresaComponent,
        'cms-bank-account': BankAccountComponent
    }
})
export default class PartnerFirmaNewPage extends Mixins(Vue, NotSavedComponent) {
    @Ref('partnerForm') partnerForm!: VForm;

    isSaving = false;

    partner = { fyzickaOsoba: false, osloveni: '', osobas: [{}], adresas: [{}, {}] };

    duplictyDialog = false;
    duplicity: Partner[] = [];

    @Watch('partner', { deep: true })
    onChangePartner() {
        this.isChanged = true;
    }

    addOsoba() {
        this.partner.osobas.push({});
    }

    removeOsoba(index: number) {
        this.partner.osobas = this.partner.osobas.filter(f => f != this.partner.osobas[index]);
    }

    beforeSave() {
        if (this.partnerForm.validate()) {
            this.isSaving = true;
            const partner = (this.partner as any) as Partner;

            kontrolaDuplicita(partner.osobas[0].email, partner.osobas[0].tel, partner.ico)
                .then((duplicity: Partner[]) => {
                    this.duplicity = duplicity;
                    if (duplicity.length) {
                        this.duplictyDialog = true;
                    } else {
                        this.save();
                    }
                })
                .catch(e => {
                    this.isSaving = false;
                    handleException(e);
                });
        }
    }

    save() {
        const partner = (this.partner as any) as Partner;
        const partnerFirma: PartnerFirmaCreate = {
            ...partner,
            fyzickaOsoba: false
        };

        this.$odata
            .create<Partner>('partner', partnerFirma)
            .then(a => {
                this.isSaving = false;
                this.isChanged = false;

                this.$snackbar.success(R.Ulozeno);
                this.$router.push({ name: 'partner.detail', params: { id: String(a.data.id) } });
            })
            .catch(err => {
                handleException(err);
            })
            .finally(() => {
                this.isSaving = false;
            });
    }

    isDuplicity(val: string): boolean {
        return isDuplicita((this.partner as any) as Partner, val);
    }
}
