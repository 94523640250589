


















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CmsPasswordFieldComponent extends Vue {
    @Prop() value!: string;
    @Prop() label!: string;
    @Prop({ default: true }) dense!: boolean;
    @Prop({ default: false }) required!: boolean;
    @Prop() placeholder!: string;

    @Prop({ default: () => [] }) rules!: any[];

    @Prop() props!: object;

    get ruleset(): any[] {
        const result = [...this.rules];

        if (this.required) result.push((v: any) => !!v || this.label + ' musí být vyplněn');

        return result;
    }

    show = false;
}
