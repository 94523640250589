





















































































import { Partner } from '@/models/partner';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import BankAccountComponent from '../../inputs/bank-account.component.vue';
import CmsCisloPopisneFieldComponent from '../../inputs/cislo-popisne-field.vue';
import CisloPopisneFieldComponent from '../../inputs/cislo-popisne-field.vue';
import CmsEmailFieldComponent from '../../inputs/email-field.component.vue';
import CmsPSCFieldComponent from '../../inputs/psc-field.component.vue';
import CmsRodneCisloFieldComponent from '../../inputs/rodne-cislo-field.component.vue';
import CmsTelFieldComponent from '../../inputs/tel-field.component.vue';
import CmsTextFieldComponent from '../../inputs/text-field.component.vue';

@Component({
    components: {
        'cms-text-field': CmsTextFieldComponent,
        'cms-rodne-cislo-field': CmsRodneCisloFieldComponent,
        'cms-bank-account': BankAccountComponent,
        'cms-email-field': CmsEmailFieldComponent,
        'cms-tel-field': CmsTelFieldComponent,
        'cms-psc': CmsPSCFieldComponent,
        'cms-cislo-popisne': CmsCisloPopisneFieldComponent
    }
})
export default class PartnerDetailTab extends Vue {
    @Prop() value!: Partner;

    editPartner: Partner | null = null;

    created(): void {
        this.editPartner = { ...this.value };
    }

    //Je povinné vyplnit tel nebo email. * se zobrazí v obou polí, pokud je jedno z polí vyplněno obě * zmizí
    emailTelRequired(): boolean {
        const isEmailFilled = this.editPartner?.email?.length;
        const isTelFilled = this.editPartner?.telefon1?.length;
        return !isEmailFilled && !isTelFilled;
    }

    @Watch('editPartner')
    onEditPartner(): void {
        this.$emit('input', this.editPartner);
    }
}
