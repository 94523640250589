export class TimeVersion {
    static Publikacno = 1;
    static Priprava = 2;
    static Archivacni = 3;

    constructor(timeVersion: TimeVersion) {
        this.id = timeVersion?.id;
        this.nazev = timeVersion?.nazev + ' - ' + timeVersion.casovaHladinaTyp?.nazev;
        this.datum = timeVersion.datum;
        this.casovaHladinaTypId = timeVersion.casovaHladinaTypId;
        this.casovaHladinaTyp = timeVersion.casovaHladinaTyp;

        this.komerceNahrany = timeVersion.komerceNahrany;
    }

    id: number;
    nazev: string;
    datum: Date;
    casovaHladinaTypId: number;

    komerceNahrany: number;

    casovaHladinaTyp: { id: number; nazev: string };
}
