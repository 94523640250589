














import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import CmsTextFieldComponent from './text-field.component.vue';

@Component({
    components: { 'cms-text-field': CmsTextFieldComponent }
})
export default class BankAccountComponent extends Vue {
    @Prop({ type: Boolean }) readonly!: boolean;
    @Prop({ type: Boolean }) disabled!: boolean;
    @Prop() value!: string;
    @Prop({ default: 'Bankovní účet' }) label!: string;
    @Prop({ type: Boolean }) required!: boolean;

    rules = [(v: string) => this.isBankAccountValid(v) || 'Bankovní účet nemá správný formát'];

    input(event: any) {
        event = event.trim();
        this.value = event;

        this.$emit('input', event);
    }

    isBankAccountValid(value: string): boolean {
        if (!value) {
            return true;
        }

        const bankAccount = value.split('/');
        if (bankAccount.length !== 2) {
            return false;
        }

        if (bankAccount[0].length < 3) {
            return false;
        }

        const account = bankAccount[0];
        const bankCode = bankAccount[1];

        if (bankCode.length !== 4) {
            return false;
        }

        return this.isWeightValid(account);

        /*   const accountSplit = account.split('-');
        if (accountSplit.length > 2) {
            return false;
        }

        if (accountSplit[0].length > 10) {
            return false;
        }

        if (accountSplit.length === 2 && accountSplit[0].length > 10) {
            return false;
        }

        if (accountSplit.length === 2 && accountSplit[1].length !== 10) {
            return false;
        }

        if (accountSplit.length === 1 && this.isWeightValid(accountSplit[0])) {
            return true;
        }

        if (accountSplit.length === 2 && this.isWeightValid(accountSplit[0]) && this.isWeightValid(accountSplit[1])) {
            return true;
        }

        return false;*/
    }

    isWeightValid(account: string): boolean {
        account = account.replace('-', '').padStart(16, '0');

        let soucetVah = 0;
        for (let i = 0; i <= 15; i++) {
            const n = +account[i];
            const r = Math.pow(2, 15 - i) % 11;

            soucetVah += r * n;
        }
        return soucetVah % 11 === 0;
    }
}
