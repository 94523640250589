import { RouteConfig } from 'vue-router';

import LokalitaListPage from '@/ui/pages/lokalita/lokalita-list.page.vue';


export const LOKALITA_ROUTES: RouteConfig[] = [
    {
        path: '',
        component: LokalitaListPage,
        name: 'lokalita.list'
    },
 
    {
        path: '*',
        redirect: 'lokalita.list'
    },

];
