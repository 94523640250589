import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

export class BaseService {
    protected get<T>(url: string, config?: AxiosRequestConfig | undefined): Promise<AxiosResponse<T>> {
        return axios.get<T>(url, config);
    }

    protected post<T>(url: string, data?: any, config?: AxiosRequestConfig | undefined): Promise<AxiosResponse<T>> {
        return axios.post<T>(url, data, config);
    }

    protected patch<T>(url: string, data?: any, config?: AxiosRequestConfig | undefined): Promise<AxiosResponse<T>> {
        return axios.patch(url, data, config);
    }

    protected delete(url: string, config?: AxiosRequestConfig | undefined): Promise<AxiosResponse> {
        return axios.delete(url, config);
    }
}
