export interface UserRight {
    pravaId: number;
    key: string;
    hodnota: number;
}


export enum ePrava {
    JeZpracovatel = 835

}