





































































































import { ODataResult } from '@/models';
import { orderBy } from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class ObpHistoryComponent extends Vue {
    @Prop() entityId!: number;

    bezZmeny = 'Bez změny';
    refresher = 0;

    getProperty(index: number) {
        return this.properties[index];
    }

    showProperty(property: string) {
        return this.selected.some(a => a == this.properties.findIndex(f => f.value === property));
    }

    getValueOld(item: { oldValue: any }) {
        return item.oldValue || '(null)';
    }

    getDateValueOld(item: { oldValue: any }, format: string) {
        return this.$formatDate(item.oldValue, format);
    }

    getValueNew(item: { newValue: any }) {
        return item.newValue || '(null)';
    }

    getDateValueNew(item: { newValue: any }, format: string) {
        return this.$formatDate(item.newValue, format);
    }

    selected = [2];
    orderedSelected: number[] = [];

    @Watch('selected', { immediate: true })
    onChangeSelected() {
        this.isLoading = true;

        this.orderedSelected = orderBy(this.selected, v => v);

        this.$odata
            .function<ODataResult<any>>('obchodnipripad', {
                key: this.entityId,
                func: { GetHistory: { Show: this.selected.map(m => this.properties[m].value) } }
            })
            .then(res => {
                this.data = res.data.value;
                this.isLoading = false;
            });
    }

    data: any[] = [];
    isLoading = false;

    properties = [
        {
            text: 'Draft',
            value: 'isDraft'
        },
        {
            text: 'Číslo',
            value: 'cisloObp'
        },
        {
            text: 'Stav pronájmu',
            value: 'stavPronajem'
        },
        {
            text: 'Nájem od',
            value: 'najemOd'
        },
        {
            text: 'Nájem do',
            value: 'najemDo'
        },
        {
            text: 'Prodejce',
            value: 'prodejceUzivatel'
        },
        {
            text: 'Partner',
            value: 'partner'
        }
    ];
}
