
































































































import { handleException } from '@/helpers';
import { TimeVersion } from '@/models/timeversion';
import { TimeVersionService } from '@/services/timeversion.service';
import { ModAppAction } from '@/store/app/app.action';
import { AppGetter } from '@/store/app/app.getter';
import { ModAppMutation } from '@/store/app/app.mutation';
import { AuthAction } from '@/store/auth/auth.types';
import { DraftGetter } from '@/store/draft/draft.getter';
import { Modules } from '@/store/modules';
import { Component, Vue } from 'vue-property-decorator';
import { mapActions, mapGetters } from 'vuex';

@Component({
    computed: {
        ...mapGetters(Modules.APP, [
            AppGetter.CURR_TIMEVERSION,
            AppGetter.TIMEVERSIONS,
            AppGetter.TIMEVERSIONS_ARCHIVE,
            AppGetter.TIMEVERSIONS_NEW,
            AppGetter.BREADCRUMPS
        ]),
        ...mapGetters(Modules.DRAFT, [DraftGetter.SIDE_EDIT, DraftGetter.NEMOVITOSTS])
    },
    methods: {
        ...mapActions(Modules.AUTH, [AuthAction.LOGOUT])
    }
})
export default class TopbarComponent extends Vue {
    migrationDialog = false;
    dialog = false;
    tmpTimeversion: TimeVersion | null = null;

    setVersion(timeversion: TimeVersion) {
        this.dialog = true;
        this.tmpTimeversion = timeversion;
    }

    migratovat(timeversion: TimeVersion) {
        this.$root
            .$confirm('Migrace časové hladiny', 'Opravdu chcete zmigrovat data do této časové hladiny?', { type: 'warning' })
            .then(a => {
                if (a) {
                    this.migrationDialog = true;

                    const tvs = new TimeVersionService();
                    tvs.migration()
                        .then(() => {
                            this.migrationDialog = false;

                            this.tmpTimeversion = timeversion;

                            this.$snackbar.success('Migrace proběhla úspěšně');
                            this.$store.dispatch(ModAppAction.INIT_TIMEVERSIONS.toString());
                            this.confirm();
                        })
                        .catch(ex => {
                            handleException(ex);
                        });
                }
            });
    }

    confirm() {
        this.dialog = false;

        if (this.$route.name !== 'home') {
            this.$router.push({ name: 'home' }).then(() => {
                this.$store.commit(ModAppMutation.SET_TIMEVERSION.toString(), { timeversion: { ...this.tmpTimeversion } });
                this.tmpTimeversion = null;
            });
        } else {
            this.$store.commit(ModAppMutation.SET_TIMEVERSION.toString(), { timeversion: { ...this.tmpTimeversion } });
            this.tmpTimeversion = null;
        }
    }

    cancel() {
        this.dialog = false;
        this.tmpTimeversion = null;
    }
}
