import { Module } from 'vuex';
import { appActions } from './app.action';
import { appGetters } from './app.getter';
import { appMutations } from './app.mutation';
import { AppState } from './app.state';

const appModule: Module<AppState, any> = {
    namespaced: true,
    state: {
        apiUrl: null,
        fileUrl: null,
        appVersion: null,
        timeversion: null,
        timeversions: [],
        timeversionsArchive: [],
        timeversionsNew: [],
        breadcrumps: [],
        snackbar: null
    },
    getters: appGetters,
    mutations: appMutations,
    actions: appActions
};

export default appModule;
