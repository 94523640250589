












import CmsListComponent from '@/ui/components/list/list.component.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
        'cms-list': CmsListComponent
    }
})
export default class TypDodatekListPage extends Vue {
    item = {
        id: '',
        nazev: ''
    };

    headers = [{ text: 'Název', value: 'nazev' }];
}
