import { handleException } from '@/helpers';
import { UserRight } from '@/models/user-right';
import store from '@/store';
import { ModAuthGetter } from '@/store/auth/auth.getter';
import { BaseApiService } from './base-api.service';

export class RightService extends BaseApiService {
    constructor() {
        super();
    }

    public async hasAccessAsync(right: string): Promise<boolean | null> {
        if (!right) return false;

        const prava = store.getters[ModAuthGetter.USER_RIGHT] as UserRight[];

        if (!prava.length) {
            try {
                const res = (await this.get<boolean>(`auth/hasAccess?right=${right}`)).data;
                return res;
            } catch (e) {
                handleException(e);
                return null;
            }
        }

        const p = prava.find(f => f.key == right);
        if (p && p.hodnota > 1) {
            return true;
        }

        return false;
    }

    public hasAccess(right: string): boolean {
        if (!right) return false;

        const prava = store.getters[ModAuthGetter.USER_RIGHT] as UserRight[];
 
        const p = prava.find(f => f.key == right);
        if (p && p.hodnota > 1) {
       
            return true;
        }

        return false;
    }

    public hasRead(right: string): boolean {
        if (!right) return true;

        const prava = store.getters[ModAuthGetter.USER_RIGHT] as UserRight[];

        const p = prava.find(f => f.key == right);
        if (p && p.hodnota > 1) {
            return true;
        }

        return false;
    }

    public hasWrite(right: string): boolean {
        if (!right) return true;

        const prava = store.getters[ModAuthGetter.USER_RIGHT] as UserRight[];

        const p = prava.find(f => f.key == right);
        if (p && p.hodnota == 3) {
            return true;
        }

        return false;
    }
}
