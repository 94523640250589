var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._t("filter"),_c('v-data-table',{staticClass:"mt-3 elevation-1 row-pointer",attrs:{"dense":!_vm.$vuetify.breakpoint.mdAndDown,"headers":_vm.cols,"items":_vm.data ? _vm.data.value : [],"options":_vm.opt,"server-items-length":_vm.data ? _vm.data['@odata.count'] : 0,"loading":_vm.isLoading,"footer-props":{ 'items-per-page-options': [10, 15, 30, 50, 100] }},on:{"update:options":function($event){return _vm.$emit('optionsUpdate', $event)},"click:row":function($event){return _vm.$emit('rowClick', $event)}},scopedSlots:_vm._u([{key:"item.pocetSklad",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.volneSklad))]),_vm._v(" / "),_c('span',[_vm._v(_vm._s(item.pocetSklad))])]}},{key:"item.pocetKancelar",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.volneKancelar))]),_vm._v(" / "),_c('span',[_vm._v(_vm._s(item.pocetKancelar))])]}},{key:"item.pocetParkovaciStani",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.volneParkovaciStani))]),_vm._v(" / "),_c('span',[_vm._v(_vm._s(item.pocetParkovaciStani))])]}},{key:"item.pocetPozemek",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.volnePozemek))]),_vm._v(" / "),_c('span',[_vm._v(_vm._s(item.pocetPozemek))])]}},{key:"item.pocetOstatni",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.volneOstatni))]),_vm._v(" / "),_c('span',[_vm._v(_vm._s(item.pocetOstatni))])]}},{key:"item.pocetNemovitosti",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.volneNemovitosti))]),_vm._v(" / "),_c('span',[_vm._v(_vm._s(item.pocetNemovitosti))])]}}],null,true)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }