import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { flatten, uniqBy } from 'lodash';

/* GUARDS */
import { isAuth } from '@/guards/isAuth.guard';
import { hasRight } from '@/guards/right.guard';

/* VIEWS - Layouts */
import LayoutView from '@/ui/views/layout.view.vue';
import AuthView from '@/ui/views/auth.view.vue';
import PartnerView from '@/ui/views/partner.view.vue';
import LokalitaView from '@/ui/views/lokalita.view.vue';
import NemovitostView from '@/ui/views/nemovitost.view.vue';
import ListsView from '@/ui/views/lists.view.vue';
import StavPronajemView from '@/ui/views/stav-pronajem.view.vue';
import TypDodatekView from '@/ui/views/typ-dodatek.view.vue';

import ObpView from '@/ui/views/obp.view.vue';
import PnpsView from '@/ui/views/pnps.view.vue';
import ReporterView from '@/ui/views/reporter.view.vue';

/* ROUTES */
import { AUTH_ROUTES } from './auth.routes';
import { PARTNER_ROUTES } from './partner.routes';
import { LOKALITA_ROUTES } from './lokalita.routes';
import { LISTS_ROUTES } from './lists.routes';
import { NEMOVITOST_ROUTES } from './nemovitost.routes';
import { STAV_PRONAJEM_ROUTES } from './stav-pronajem.routes';
import { OBP_ROUTES } from './obp.routes';
import { PNPS_ROUTES } from './pnps.routes';
import { REPORTER_ROUTES } from './reporter.routes';

/* STORE */
import store from '@/store';
import { ModAppMutation } from '@/store/app/app.mutation';

/* PAGES */
import DashboardPage from '@/ui/pages/dashboard/dashboard.page.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        component: LayoutView,
        meta: {
            authRequired: true,
            breadcrump: 'Dashboard'
        },
        children: [
            {
                path: '',
                name: 'home',
                component: DashboardPage
            },
            {
                path: 'lokalita',
                component: LokalitaView,
                children: LOKALITA_ROUTES,
                meta: {
                    breadcrump: 'Přehled lokalit'
                }
            },
            {
                path: 'nemovitost',
                component: NemovitostView,
                children: NEMOVITOST_ROUTES,
                meta: {
                    right: 'kwNemovitosti',
                    breadcrumps: [
                        {
                            text: 'Přehled lokalit',
                            href: '/lokalita'
                        }
                    ],
                    breadcrump: 'Přehled nemovitostí'
                }
            },
            {
                path: 'stav-pronajem',
                component: StavPronajemView,
                children: STAV_PRONAJEM_ROUTES,
                meta: {
                    breadcrump: 'Stav pronájem'
                }
            },
 
 
            {
                path: 'partner',
                component: PartnerView,
                children: PARTNER_ROUTES,
                meta: {
                    right: 'kwPartneri',
                    breadcrump: 'Přehled partnerů'
                }
            },
            {
                path: 'obp',
                component: ObpView,
                children: OBP_ROUTES,
                meta: {
                    breadcrump: 'Přehled obchodních případů'
                }
            },
            {
                path: 'pnps',
                component: PnpsView,
                children: PNPS_ROUTES,
                meta: {
                    right: 'kwPnps',
                    breadcrump: 'Přehled PNPS'
                }
            },
            {
                path: 'reporter',
                component: ReporterView,
                children: REPORTER_ROUTES,
                meta: {
                    breadcrumps: [
                        {
                            text: 'Reporty',
                            href: '/list/report'
                        }
                    ]
                }
            },
 
            {
                path: 'list',
                component: ListsView,
                children: LISTS_ROUTES
            }
        ]
    },
    {
        path: '/auth',
        component: AuthView,
        children: AUTH_ROUTES
    },
    { path: '*', redirect: { name: 'home' } }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach(async (to, from, next) => {
    await isAuth(to, from, next);
    await hasRight(to, from, next);
    next();
});

router.afterEach((to, from) => {
    const bcs = flatten(
        to.matched.map(m => {
            const bd = m.meta.breadcrumps || [];

            if (m.meta.breadcrump) {
                bd.push({
                    text: m.meta.breadcrump,
                    href: m.path || '/'
                });
            }

            return bd;
        })
    ).map(bc => {
        return {
            text: bc.text,
            href: (bc.href as string)
                ?.split('/')
                .map(m => {
                    for (const param in to.params) {
                        if (m === ':' + param) {
                            return to.params[param];
                        }
                    }

                    for (const query in to.query) {
                        if (m === ':' + query) {
                            return to.query[query];
                        }
                    }

                    return m;
                })
                .join('/')
        };
    });

    store.commit(ModAppMutation.SET_BREADCRUMPS.toString(), {
        breadcrumps: uniqBy(bcs, b => b.href)
    });
});

export default router;
