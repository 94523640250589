

















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { PartnerOsoba } from '@/models/partner/osoba';
import CmsRodneCisloFieldComponent from '../inputs/rodne-cislo-field.component.vue';
import CmsEmailFieldComponent from '../inputs/email-field.component.vue';
import CmsTelFieldComponent from '../inputs/tel-field.component.vue';

@Component({
    components: {
        'cms-rodne-cislo-field': CmsRodneCisloFieldComponent,
        'cms-email-field': CmsEmailFieldComponent,
        'cms-tel-field': CmsTelFieldComponent
    }
})
export default class PartnerOsobaComponent extends Vue {
    @Prop() value!: PartnerOsoba;

    update(key: string, value: any) {
        this.$emit('input', { ...this.value, [key]: value });
    }

    //Je povinné vyplnit tel nebo email. * se zobrazí v obou polí, pokud je jedno z polí vyplněno obě * zmizí
    emailTelRequired(): boolean {
        const isEmailFilled = this.value?.email?.length;
        const isTelFilled = this.value?.tel?.length;
        return !isEmailFilled && !isTelFilled;
    }
}
