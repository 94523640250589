import { MutationTree } from 'vuex';
import { Modules } from '../modules';
import { TimeVersion } from '@/models/timeversion';
import { AppState } from './app.state';
import { Breadcrump } from '@/models/breadcrump';
import { Snackbar } from '@/models/snackbar';

export enum AppMutation {
    SET_CONFIG = 'SET_CONFIG',
    SET_APPVERSION = 'SET_APPVERSION',

    SET_TIMEVERSION = 'SET_TIMEVERSION',
    SET_TIMEVERSIONS = 'SET_TIMEVERSIONS',

    SET_BREADCRUMPS = 'SET_BREADCRUMPS',
    ADD_BREADCRUMPS = 'ADD_BREADCRUMPS',

    SHOW_SNACKBAR = 'SNOW_SNACKBAR',
    HIDE_SNACKBAR = 'HIDE_SNACKBAR'
}

export enum ModAppMutation {
    SET_CONFIG = Modules.APP + '/' + AppMutation.SET_CONFIG,
    SET_APPVERSION = Modules.APP + '/' + AppMutation.SET_APPVERSION,

    SET_TIMEVERSION = Modules.APP + '/' + AppMutation.SET_TIMEVERSION,
    SET_TIMEVERSIONS = Modules.APP + '/' + AppMutation.SET_TIMEVERSIONS,
    SET_BREADCRUMPS = Modules.APP + '/' + AppMutation.SET_BREADCRUMPS,
    ADD_BREADCRUMPS = Modules.APP + '/' + AppMutation.ADD_BREADCRUMPS,

    SHOW_SNACKBAR = Modules.APP + '/' + AppMutation.SHOW_SNACKBAR,
    HIDE_SNACKBAR = Modules.APP + '/' + AppMutation.HIDE_SNACKBAR
}

export interface AppMutationTypes {
    [AppMutation.SET_CONFIG](state: AppState, payload: { apiUrl: string; fileUrl: string }): void;
    [AppMutation.SET_APPVERSION](state: AppState, payload: { version: string }): void;

    [AppMutation.SET_TIMEVERSION](state: AppState, payload: { timeversion: TimeVersion }): void;
    [AppMutation.SET_TIMEVERSIONS](state: AppState, payload: { timeversions: TimeVersion[] }): void;

    [AppMutation.SET_BREADCRUMPS](state: AppState, payload: { breadcrumps: Breadcrump[] }): void;
    [AppMutation.ADD_BREADCRUMPS](state: AppState, payload: { breadcrump: Breadcrump }): void;

    [AppMutation.SHOW_SNACKBAR](state: AppState, snackbar: Snackbar): void;
    [AppMutation.HIDE_SNACKBAR](state: AppState): void;
}

export const appMutations: MutationTree<AppState> & AppMutationTypes = {
    [AppMutation.SET_CONFIG]: (state: AppState, payload: { apiUrl: string; fileUrl: string }) => {
        state.apiUrl = payload.apiUrl;
        state.fileUrl = payload.fileUrl;
    },

    [AppMutation.SET_APPVERSION]: (state: AppState, payload: { version: string }) => {
        state.appVersion = payload.version;
    },

    [AppMutation.SET_TIMEVERSION]: (state: AppState, payload: { timeversion: TimeVersion }) => {
        state.timeversion = payload.timeversion;
        sessionStorage.setItem('cms_timeversion', payload.timeversion?.id?.toString());

        localStorage.removeItem('cms_timeversion'); // ToDo: V budnoucnu smazat
    },

    [AppMutation.SET_TIMEVERSIONS]: (state: AppState, payload: { timeversions: TimeVersion[] }) => {
        state.timeversions = payload.timeversions.filter(f => f.casovaHladinaTyp.id != 3 && f.komerceNahrany === 1);
        state.timeversionsArchive = payload.timeversions.filter(f => f.casovaHladinaTyp.id == 3 && f.komerceNahrany === 1);
        state.timeversionsNew = payload.timeversions.filter(f => f.komerceNahrany === 0);
    },

    [AppMutation.SET_BREADCRUMPS]: (state: AppState, payload: { breadcrumps: Breadcrump[] }) => {
        state.breadcrumps = [...payload.breadcrumps];
    },

    [AppMutation.ADD_BREADCRUMPS]: (state: AppState, payload: { breadcrump: Breadcrump }) => {
        state.breadcrumps = [...state.breadcrumps, payload.breadcrump];
    },

    [AppMutation.SHOW_SNACKBAR]: (state: AppState, snackbar: Snackbar) => {
        state.snackbar = { ...snackbar };
    },

    [AppMutation.HIDE_SNACKBAR]: (state: AppState) => {
        state.snackbar = null;
    }
};
