

























































































































































































































import { PnpsUpdate, PozadavekNaPripravuSmlouvy, PnpsNemovitostVazba, PnpsCreate } from '@/models/pnps';

import PnpsCenoTvorbaTab from '@/ui/components/pnps/detail-tabs/pnps-cenotvorba.tab.vue';
import PnpsDetailTab from '@/ui/components/pnps/detail-tabs/pnps-detail.tab.vue';
import PnpsNemovitostTab from '@/ui/components/pnps/detail-tabs/pnps-nemovitost.tab.vue';
import PnpsCelkoveCenyTab from '@/ui/components/pnps/detail-tabs/pnps-celkoveceny.vue';

import { QueryOptions } from 'odata-query';
import { Component, Vue, Watch, Mixins, Ref } from 'vue-property-decorator';
import { eStavZadost } from '@/models/stav-zadost';
import { eTypPronajem } from '@/models/typ-pronajem';
import { CenoTvorba, NemovitostCv } from '@/models/nemovitost';

import { ModAppAction } from '@/store/app/app.action';
import NotSavedComponent from '@/ui/components/not-saved.component';
import { VForm } from '@/models/vue-components';
import R from '@/models/resources';
import PnpsHistoryTab from '@/ui/components/pnps/detail-tabs/pnps-history.tab.vue';
import PnpsSpravaTab from '@/ui/components/pnps/detail-tabs/pnps-sprava.tab.vue';
import { handleException } from '@/helpers';
import { ModDraftMutation } from '@/store/draft/draft.mutation';
import { sumBy } from 'lodash';
import { RightService } from '@/services/right.service';
import { LokalitaCiselnaRada } from '@/models/lokalitaCiselnaRada';
import { ODataResult } from '@/models';

@Component({
    components: {
        'cms-pnps-detail-tab': PnpsDetailTab,
        'cms-pnps-nemovitost-tab': PnpsNemovitostTab,
        'cms-pnps-cenotvorba-tab': PnpsCenoTvorbaTab,
        'cms-pnps-history-tab': PnpsHistoryTab,
        'cms-pnps-sprava-tab': PnpsSpravaTab,
        'cms-celkove-ceny-tab': PnpsCelkoveCenyTab
    }
})
export default class PnpsDetailPage extends Mixins(Vue, NotSavedComponent) {
    @Ref('form') form!: VForm;

    activeTab = 0;
    loaded = false;
    isSaving = false;
    spravaVisible = true;
    eStavZadost = eStavZadost;
    validovatRequiredPole = true; //Vypíná se při storno požadavku
    historyValues: any = null;

    ciselneRadyNabidka: any[] = [];
    pnps: PozadavekNaPripravuSmlouvy | null = null;
    nemovitosts: NemovitostCv[] = [];
    nemovitostsOdebratId: number[] = [];
    nemovitostVazbas: PnpsNemovitostVazba[] = [];
    cenoTvorbas: CenoTvorba[] = [];

    isReadOnly = true;
    dialogStorno = false;
    dialogCiselneRady = false;

    selectedlokalitaCiselnaRadaId: number | null = null;

    async mounted(): Promise<void> {
        this.isReadOnly = !new RightService().hasWrite(this.$route.meta.right);
    }

    get sumaCena() {
        if (
            this.pnps?.typPronajemId == null ||
            this.cenoTvorbas.length == 0 ||
            this.cenoTvorbas.every(c => c.cenaNajemnePnpsCelkemSDph == null)
        )
            return null;

        return sumBy(this.cenoTvorbas, v => v.cenaNajemnePnpsCelkemSDph ?? 0);
    }

    @Watch('pnps', { deep: true })
    onChangePnps() {
        this.isChanged = true;
    }

    @Watch('pnps.typPronajemId')
    onChangeTypPronajem() {
        this.cenoTvorbas = this.cenoTvorbas.map(m => m.setCenyPnps(this.pnps?.typPronajemId as eTypPronajem, this.pnps?.dphId === 1));
    }

    @Watch('pnps.dphId')
    onChangeDph() {
        this.cenoTvorbas = this.cenoTvorbas.map(m => m.setCenyPnps(this.pnps?.typPronajemId as eTypPronajem, this.pnps?.dphId === 1));
    }

    @Watch('$route.params.pnpsId', { immediate: true })
    async onChangePnpsId() {
        this.loaded = false;
        await this.fetchPnps();
    }

    async fetchPnps() {
        this.loaded = false;
        const query: Partial<QueryOptions<PozadavekNaPripravuSmlouvy>> = {
            expand: {
                pnpsSluzbys: {},
                komentare: {
                    expand: {
                        uzivatel: {}
                    }
                },
                soubors: {},
                pnpsSpravaSoubors: {
                    expand: { soubor: {} }
                },
                cenoTvorbas: { expand: { nemovitost: {} } },
                obchodniPripad: { select: ['id', 'cisloObp', 'lokalitaId'] },
                podpozadavky: { expand: { cenoTvorbas: { expand: { nemovitost: {} } } } },
                pnpsNemovitostVazbas: { expand: { pnpsPridal: {} } },
                stavZadost: {
                    select: ['nazev']
                },
                stavPronajem: {}
            }
        };

        this.pnps = null;
        this.pnps = (await this.$odata.getById<PozadavekNaPripravuSmlouvy>('pnps', +this.$route.params.pnpsId, query)).data;
        this.pnps.sluzbyPausalni = [];
        this.pnps.sluzbyZalohove = [];
        this.pnps.spravaSoubors = this.pnps.pnpsSpravaSoubors.map(i => i.soubor);
        this.pnps.pnpsSluzbys.forEach(v =>
            v.typ == 0 ? this.pnps?.sluzbyPausalni.push(v.sluzbyId) : this.pnps?.sluzbyZalohove.push(v.sluzbyId)
        );

        //Nemovitosti které nejsou odebrány a přidávající dodatek není stornovaný
        const aktualniNemovitostiId = this.pnps.pnpsNemovitostVazbas
            .filter(x => x.datumOdebrani == null && x.pnpsPridal?.stavZadostId != eStavZadost.Stornovano)
            .map(x => x.nemovitostId);

        this.cenoTvorbas = this.pnps.cenoTvorbas
            .filter(x => x.nemovitostId != null && aktualniNemovitostiId.includes(x.nemovitostId))
            .map(m => new CenoTvorba(m).setPuvodniHodnoty(this.pnps?.typPronajemId as eTypPronajem));
        this.cenoTvorbas.forEach(x => (x.cisloSmlouvy = this.pnps?.cisloSmlouvyText ?? ' - '));
        this.cenoTvorbas = this.cenoTvorbas.map(m => m.setCenyPnps(this.pnps?.typPronajemId as eTypPronajem, this.pnps?.dphId === 1));

        this.pnps.podpozadavky
            .filter(x => x.stavZadostId != eStavZadost.Stornovano)
            .forEach(x => {
                x.cenoTvorbas.forEach(y => {
                    if (y.nemovitostId != null && aktualniNemovitostiId.includes(y.nemovitostId)) {
                        y.cisloSmlouvy = x.cisloSmlouvyText;
                        y.readonly = true;
                        this.cenoTvorbas.push(new CenoTvorba(y));
                    }
                });
            });

        //TODO Refaktorovat - proč se znovu načitaj vazby
        const vazbas = (
            await this.$odata.getList<PnpsNemovitostVazba>('pnpsNemovitostVazba', {
                filter: { pnpsId: { eq: this.pnps.id } },
                expand: { pnpsPridal: {}, nemovitost: { expand: { budova: {}, nemovitostCvs: {}, podlazis: {}, typNemovitost: {} } } }
            })
        ).data.value;

        this.nemovitostVazbas = vazbas;
        if (vazbas.length) {
            this.nemovitosts = (
                await this.$odata.getList<NemovitostCv>('nemovitostCv', {
                    filter: { nemovitostId: { in: vazbas.map(m => m.nemovitostId) } },
                    expand: {
                        nemovitost: { expand: { budova: {}, typNemovitost: {}, podlazis: {} } },
                        cenoTvorba: { expand: { nemovitost: {} } }
                    },
                    orderBy: ['katalogoveCislo']
                })
            ).data.value;
        } else {
            this.nemovitosts = [];
        }
        this.$nextTick(() => {
            this.isChanged = false;
        });
        this.loaded = true;
    }

    ulozit(afterSave: () => void): void {
        if (this.validovatRequiredPole && !this.form.validate()) return;

        if (this.pnps) {
            this.isSaving = true;

            const res: PnpsUpdate = {
                id: this.pnps.id,
                typPronajemId: this.pnps.typPronajemId,
                zadatelUzivatelId: this.pnps.zadatelUzivatelId,
                zpracovatelUzivatelId: this.pnps.zpracovatelUzivatelId,
                najemOd: this.pnps.najemOd,
                najemDo: this.pnps.najemDo,
                najemPredani: this.pnps.najemPredani,
                datumPodpisuPlan: this.pnps.datumPodpisuPlan,
                emailProFaktury: this.pnps.emailProFaktury,
                dphId: this.pnps.dphId,
                zakon340Id: this.pnps.zakon340Id,
                pojisteniId: this.pnps.pojisteniId,
                pronajimatelPartnerId: this.pnps.pronajimatelPartnerId,
                najemcePartnerId: this.pnps.najemcePartnerId,
                zadano: this.pnps.zadano,
                cenoTvorbas: this.cenoTvorbas.filter(x => !x.readonly),
                spravaSoubors: this.pnps.spravaSoubors,
                soubors: this.pnps.soubors,
                poznamka: this.pnps.poznamka,
                cisloSmlouvyText: this.pnps.cisloSmlouvyText,
                nemovitostIds: this.nemovitosts.filter(m => !this.nemovitostsOdebratId.includes(m.nemovitostId)).map(m => m.nemovitostId),
                komentare: this.pnps.komentare.filter(f => f.tmpId),
                sluzbyZalohove: this.pnps.sluzbyZalohove,
                sluzbyPausalni: this.pnps.sluzbyPausalni,
                rucitel: this.pnps.rucitel,
                rucitelText: this.pnps.rucitelText,
                vypovedniLhuta: this.pnps.vypovedniLhuta,
                pnpsNemovovitostVazbas: this.nemovitostVazbas,
                typDodatekId: null,
                nahrada: null,
                administrativniPoplatek: false
            };

            this.$odata
                .update('pnps', this.pnps?.id, res)
                .then(() => {
                    this.isSaving = false;
                    this.isChanged = false;

                    if (afterSave != undefined) {
                        afterSave();
                    } else {
                        this.$snackbar.success(R.Ulozeno);

                        this.$nextTick(() => {
                            this.fetchPnps();
                        });
                    }
                })
                .catch(i => {
                    this.isSaving = false;
                    this.$snackbar.error(i.response.data.value);
                });
        }
    }

    nemovitostRemove(e: number) {
        const nem = this.nemovitosts.find(f => f.id == e);
        //   this.nemovitosts = this.nemovitosts.filter(f => f.id != e);
        this.nemovitostsOdebratId.push(e);
        this.cenoTvorbas = this.cenoTvorbas.filter(
            f => (f.nemovitostId && f.nemovitostId != nem?.nemovitostId) || (f.nemovitostCvId && f.nemovitostCvId != e)
        );

        this.isChanged = true;
    }

    addPodpozadavek() {
        this.ulozit(() => {
            const newPnps: PnpsCreate = {
                partnerId: this.pnps?.najemcePartnerId,
                nemovitostIds: [],
                pnpsParentId: this.pnps?.id
            };

            this.$odata
                .create<PozadavekNaPripravuSmlouvy>('pnps', newPnps)
                .then(a => {
                    this.$router.push({
                        name: 'podpozadavek.pnps.detail',
                        params: { pnpsId: this.$route.params.pnpsId, podPnpsId: String(a.data.id) }
                    });
                })
                .catch(error => {
                    handleException(error);
                });
        });
    }

    spravovatNemovitosti() {
        this.activeTab = 0;

        this.ulozit(async () => {
            await this.$store.dispatch(ModAppAction.INIT_PUBLISHED.toString());

            const items = this.nemovitosts.filter(n =>
                this.nemovitostVazbas
                    .filter(v => v.datumOdebrani == null)
                    .map(i => i.nemovitostId)
                    .includes(n.nemovitostId)
            );

            this.$store.commit(ModDraftMutation.SET_PNPS.toString(), { pnps: this.pnps });
            this.$store.commit(ModDraftMutation.ADD_NEMOVITOST.toString(), { nemovitosts: [...items] });

            this.$router.push({
                name: 'nemovitost.list',
                query: { filter: JSON.stringify({ lokalitaId: this.pnps?.obchodniPripad.lokalitaId }) }
            });
        });
    }

    setStav(stavId: number): void {
        if (this.pnps?.stavZadostId == eStavZadost.Stornovano) {
            this.$snackbar.warn('Požadavek je stornován, nelze měnit stavy');
        } else if (this.pnps?.stavZadostId == stavId) {
            this.$snackbar.info('Požadavek je v požadovaném stavu');
        } else {
            this.ulozit(() => {
                this.$odata
                    .function<any>('pnps', { key: this.pnps?.id, func: { setstav: { stavId } } })
                    .then(a => {
                        if (this.pnps) {
                            this.pnps.cisloSmlouvyText = a.data.cisloSmlouvy;
                            this.pnps.stavZadost.nazev = a.data.stavZadostNazev;
                            this.pnps.stavZadost.id = a.data.stavZadostId;
                            this.pnps.stavZadostId = a.data.stavZadostId;

                            if (stavId == eStavZadost.Podepsano) {
                                this.pnps.stavPronajemId = 4;
                                this.pnps.stavPronajem.zkratka = 'Pronajmuto';
                            }

                            this.$snackbar.success('Nastaven stav ' + this.pnps.stavZadost.nazev);
                        }
                    })
                    .catch(error => {
                        handleException(error);
                    });
            });
        }
    }

    async odeslat() {
        let ciselnaRadaIds: (number | null)[] = [];
        const ciselnaRadas: (number | null)[] = this.nemovitostVazbas
            .filter(d => d.datumOdebrani == null && d.nemovitost.budova != null)
            .map(n => n.nemovitost.budova.lokalitaCiselnaRadaId);
        if (ciselnaRadas.length) {
            ciselnaRadaIds = [...new Set(ciselnaRadas)];
        }
        if (ciselnaRadaIds.length == 1 && ciselnaRadaIds[0] != null) {
            this.selectedlokalitaCiselnaRadaId = ciselnaRadaIds[0];

            this.setStavOdeslat();
            return;
        }

        /* const ciselnaRadaFilter = ciselnaRadaIds.filter(x => x != null);*/

        this.ciselneRadyNabidka = [];
        (
            await this.$odata.getList<LokalitaCiselnaRada>('LokalitaCiselnaRada', {
                filter: {
                    or: {
                        id: { in: ciselnaRadaIds.filter(x => x != null) },
                        cmsLokalitaId: { eq: this.nemovitosts[0].nemovitost.cmsLokalitaId }
                    }
                },
                orderBy: 'cisloOd asc'
            })
        ).data.value.forEach(r =>
            this.ciselneRadyNabidka.push({
                id: r.id,
                cisloOd: r.cisloOd,
                cisloDo: r.cisloDo,
                nazev: (r.cisloOd ?? 0) + ' - ' + (r.cisloDo ?? 9999999)
            })
        );

        if (!this.ciselneRadyNabidka.length) {
            this.$snackbar.error('Nenalezena žádná číselná řada');
        } else {
            this.dialogCiselneRady = true;
        }
    }
    setStavOdeslat() {
        if (this.pnps?.stavZadostId == eStavZadost.Stornovano) {
            this.$snackbar.warn('Požadavek je stornován, nelze měnit stavy');
        } else if (this.pnps?.stavZadostId == eStavZadost.NovyPozadavek) {
            this.$snackbar.info('Požadavek je v požadovaném stavu');
        } else {
            this.ulozit(() => {
                this.$odata
                    .function<any>('pnps', {
                        key: this.pnps?.id,
                        func: { setStavOdeslat: { ciselnaRadaId: this.selectedlokalitaCiselnaRadaId } }
                    })
                    .then(a => {
                        if (this.pnps) {
                            this.pnps.cisloSmlouvyText = a.data.cisloSmlouvy;
                            this.pnps.stavZadost.nazev = a.data.stavZadostNazev;
                            this.pnps.stavZadost.id = a.data.stavZadostId;
                            this.pnps.stavZadostId = a.data.stavZadostId;
                            this.$snackbar.success('Nastaven stav ' + this.pnps.stavZadost.nazev);
                            this.dialogCiselneRady = false;
                        }
                    })
                    .catch(error => {
                        handleException(error);
                    });
            });
        }
    }

    duvodStorna = '';
    stornoDialog(): void {
        if (this.pnps?.stavZadostId == eStavZadost.Stornovano) this.$snackbar.info('Požadavek je stornován');
        else {
            this.dialogStorno = true;
        }
    }

    zvolitCiselnouRadu() {}

    storno() {
        if (this.duvodStorna.length < 10) {
            this.$snackbar.error('Důvod storna musí být dlouhý alespoň 10 znaků');
            return;
        }
        this.dialogStorno = false;
        this.validovatRequiredPole = false;
        this.ulozit(() => {
            this.$odata
                .function<any>('pnps', { key: this.pnps?.id, func: { storno: { duvod: this.duvodStorna } } })
                .then(a => {
                    if (this.pnps) {
                        this.pnps.cisloSmlouvyText = a.data.cisloSmlouvy;
                        this.pnps.stavZadost.nazev = a.data.stavZadostNazev;
                        this.pnps.stavZadost.id = a.data.stavZadostId;

                        this.pnps.stavZadostId = eStavZadost.Stornovano;
                        this.$snackbar.success('Požadavek byl stornován');
                    }
                })
                .catch(error => {
                    handleException(error);
                })
                .finally(() => (this.validovatRequiredPole = true));
        });
    }

    zur() {
        this.ulozit(() => {
            this.$odata
                .function<any>('pnps', { key: this.pnps?.id, func: 'Zur()' })
                .then(a => {
                    if (this.pnps) {
                        this.pnps.stavPronajem = a.data;
                        this.pnps.stavPronajemId = 3;
                        this.$snackbar.success('Nastavena Závazná ústní rezervace');
                    }
                })
                .catch(error => {
                    handleException(error);
                });
        });
    }

    updatePnpsNemovitostVazba(vazba: PnpsNemovitostVazba) {
        const vazbaEdit = this.nemovitostVazbas.filter(x => x.id === vazba.id)[0];
        vazbaEdit.datumPrevzeti = vazba.datumPrevzeti;
        vazbaEdit.datumPredani = vazba.datumPredani;
    }
}
