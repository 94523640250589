import { RouteConfig } from 'vue-router';

import SpNemovitostListPage from '@/ui/pages/stav-pronajem/sp-nemovitost-list.page.vue';
import SpNemovitostDetail from '@/ui/pages/stav-pronajem/sp-nemovitost-detail.page.vue';
import SpLokalitaListPage from '@/ui/pages/stav-pronajem/sp-lokalita-list.page.vue';

export const STAV_PRONAJEM_ROUTES: RouteConfig[] = [
    {
        path: '',
        component: SpLokalitaListPage,
        name: 'sp.lokalita.list'
    },
    {
        path: ':lokId/sp-nemovitost',
        component: SpNemovitostListPage,
        name: 'sp.nemovitost.list'
    },
    {
        path: ':lokId/sp-nemovitost/:nemCvId',
        name: 'sp.nemovitost.detail',
        component: SpNemovitostDetail,
        meta: {
            breadcrump: 'Stav pronájmu - detail nemovitosti'
        }
    },
    {
        path: '*',
        redirect: 'sp.lokalita.list'
    }
];
