









































import { GridOptions, ODataResult } from '@/models';
import { NemovitostCv } from '@/models/nemovitost';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { Modules } from '@/store/modules';
import { AppGetter } from '@/store/app/app.getter';

@Component({
    computed: {
        ...mapGetters(Modules.APP, [AppGetter.CURR_TIMEVERSION])
    }
})
export default class SpNemovitostListComponent extends Vue {
    @Prop() value!: NemovitostCv[];

    @Prop({ required: true }) data!: ODataResult<NemovitostCv>;
    @Prop({ required: true }) isLoading!: boolean;
    @Prop({ required: true }) opt!: GridOptions;

    @Prop({ required: false }) headers?: any[];
    @Prop({ default: false }) showSelect!: boolean;

    get cols(): any[] {
        return this.defaultHeaders;
    }

    getItemClass(item: any): string {
        const stav = item.nemovitost.stavPronajemId;
        const r = stav === 2 ? 'stav-prodeje-nur' : stav === 3 ? 'stav-prodeje-zur' : stav === 4 ? 'stav-prodeje-prodano' : '';
        return r;
    }

    defaultHeaders = [
        { text: 'Katalogové číslo', value: 'katalogoveCislo' },
        { text: 'Lokalita', value: 'nemovitost.lokalitaJv.obchodniNazev', sortable: false },
        { text: 'Budova', value: 'nemovitost.budova.nazev' },
        { text: 'Stav', value: 'nemovitost.stavPronajem.zkratka' },
        { text: 'Typ nemovitosti', value: 'nemovitost.typNemovitost.nazev' },
        { text: 'Nájem od', value: 'nemovitost.najemOd' },
        { text: 'Nájem do', value: 'nemovitost.najemDo' },
        { text: 'Garance nájmu', value: 'garanceNajemDo' },
        { text: 'Nájemné', value: 'cenoTvorba.maxNajem' },
        { text: 'Cena služeb', value: 'cenoTvorba.cenaSluzebBezDph' },
        { text: 'Plocha', value: 'plocha' }
    ];
}
