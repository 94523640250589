

















import { Snackbar } from '@/models/snackbar';
import { AppGetter } from '@/store/app/app.getter';
import { ModAppMutation } from '@/store/app/app.mutation';
import { Modules } from '@/store/modules';
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component({
    computed: {
        ...mapGetters(Modules.APP, [AppGetter.SNACKBAR])
    }
})
export default class CmsSnackbarComponent extends Vue {
    SNACKBAR!: Snackbar;

    get snackbar(): boolean {
        return !!this.SNACKBAR;
    }

    onClose(e: boolean): void {
        if (!e) {
            this.$store.commit(ModAppMutation.HIDE_SNACKBAR.toString());
        }
    }
}
