import { RouteConfig } from 'vue-router';

import NemovitostListPage from '@/ui/pages/nemovitost/nemovitost-list.page.vue';
import NemovitostDetailPage from '@/ui/pages/nemovitost/nemovitost-detail.page.vue';

export const NEMOVITOST_ROUTES: RouteConfig[] = [
    {
        path: '',
        name: 'nemovitost.list',
        component: NemovitostListPage
    },
    {
        path: 'new',
        name: 'nemovitost.new',
        component: NemovitostDetailPage,
        meta: {
            right: 'kwNemovitostDetail',
            breadcrump: 'Vytvoření nemovitosti'
        }
    },
    {
        path: ':nemCvId',
        component: NemovitostDetailPage,
        name: 'nemovitost.detail',
        meta: {
            right: 'kwNemovitostDetail',
            breadcrump: 'Detail nemovitosti'
        }
    }
];
