import { User } from '@/models/user';
import { UserRight } from '@/models/user-right';
import axios, { AxiosResponse } from 'axios';
import { BaseApiService } from './base-api.service';
import { Vue } from 'vue-property-decorator';

export class UserService extends BaseApiService {
    getCurrentUser(): Promise<User> {
        return this.get<User>('auth/user').then(f => f.data);
    }

    getPrava(): Promise<UserRight[]> {
        return this.get<UserRight[]>('auth/prava').then(f => f.data);
    }

    login(username: string, password: string): Promise<AxiosResponse<any>> {
        return this.post<any>('auth/login', {
            username,
            password
        });
    }

    logout() {
        if (localStorage.getItem('cms_ad') == "true") {
            Vue.prototype.$msal.logoutPopup();
        }

        return this.post<any>('auth/logout', {
            token: localStorage.getItem('cms_token')
        });
    }
}
