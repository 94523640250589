









































import { Lokalita } from '@/models/lokalita';
import { debounce } from 'lodash';
import { mapGetters } from 'vuex';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Modules } from '@/store/modules';
import { AppGetter } from '@/store/app/app.getter';
import { TimeVersion } from '@/models';

@Component({
    computed: {
        ...mapGetters(Modules.APP, [AppGetter.CURR_TIMEVERSION])
    }
})
export default class CmsLokalitaSelectComponent extends Vue {
    @Prop() value!: number;
    @Prop({ type: Boolean }) readonly!: boolean;
    @Prop({ default: true, type: Boolean }) dense!: boolean;

    @Prop({ default: () => [] }) rules!: any[];

    @Prop({ type: Boolean }) required!: boolean;

    @Prop({ type: Boolean }) cv!: boolean;

    @Prop() right!: string;

    CURR_TIMEVERSION!: TimeVersion;

    label = 'Lokalita';

    get hasRight(): boolean {
        return this.$right.hasRead(this.right);
    }

    get isReadonly(): boolean {
        return (
            this.readonly ||
            !this.$right.hasWrite(this.right) ||
            (this.cv && (!this.CURR_TIMEVERSION || this.CURR_TIMEVERSION.casovaHladinaTyp.id === TimeVersion.Archivacni))
        );
    }

    get ruleset(): any[] {
        const result = [...this.rules];

        if (this.required) result.push((v: any) => !!v || 'Lokalita musí být vyplněna');

        return result;
    }

    data: Lokalita[] = [];

    isLoading = false;
    model: Lokalita | null = null;
    number: number | null = null;

    search = null;

    @Watch('value', { immediate: true })
    async onChangeValue() {
        try {
            if (this.value) {
                const res = (await this.$odata.getById<Lokalita>('lokalitaView', +this.value)).data;
                if (res) {
                    this.model = res;
                    this.data = [this.model];
                }
            } else {
                this.model = null;
                this.data = [];
            }
        } catch {
            this.model = null;
            this.data = [];
        }
    }

    numberUpDebounce = debounce(this.numberUp, 300);

    @Watch('search')
    onSearchChangeDebounce = debounce(this.onSearchChange, 400);

    async onSearchChange(val: string) {
        if (!val) {
            this.data = [];
            this.model = null;
            return;
        }

        if (this.model?.nazev == val) return;

        this.isLoading = true;

        const filter = { 'toLower(unaccentNazev)': { contains: this.$lowerUnaccent(val) } };

        try {
            this.data = (await this.$odata.getList<Lokalita>('lokalitaView', { filter, top: 50 })).data.value;
        } catch {
            this.data = [];
        }

        this.isLoading = false;
    }

    @Watch('model')
    onModelChange(val: Lokalita) {
        this.$emit('input', val?.id);
        this.number = val?.cislo;
    }

    async numberUp(): Promise<void> {
        if (this.number) {
            const res = (await this.$odata.getList<Lokalita>('lokalita', { filter: { cislo: +this.number } })).data;
            if (res) {
                if (res.value.length == 1) {
                    this.data = [res.value[0]];
                    this.model = res.value[0];

                    return;
                }
            }
        }

        this.model = null;
        this.data = [];
    }
}
