import { AuthState } from './auth.state';
import { MutationTree } from 'vuex';
import { Modules } from '../modules';
import { User } from '@/models/user';
import { UserRight } from '@/models/user-right';
import { AuthResponse } from '@/models/auth-reponse';

export enum AuthMutation {
    INIT_AUTH = 'INIT_AUTH',
    SET_AUTH = 'SET_AUTH',
    CLEAR_AUTH = 'CLEAR_AUTH',

    SET_USER = 'SET_USER',
    SET_PRAVA = 'SET_PRAVA'
}

export enum ModAuthMutation {
    INIT_AUTH = Modules.AUTH + '/' + AuthMutation.INIT_AUTH,
    SET_AUTH = Modules.AUTH + '/' + AuthMutation.SET_AUTH,
    CLEAR_AUTH = Modules.AUTH + '/' + AuthMutation.CLEAR_AUTH,

    SET_USER = Modules.AUTH + '/' + AuthMutation.SET_USER,
    SET_PRAVA = Modules.AUTH + '/' + AuthMutation.SET_PRAVA
}

export interface AuthMutationTypes {
    [AuthMutation.INIT_AUTH](state: AuthState): void;

    [AuthMutation.SET_AUTH](
        state: AuthState,
        payload: {
            token: string | null;
            expiration: Date | null;
            type: string | null;
            refreshToken: string | null;
        }
    ): void;

    [AuthMutation.CLEAR_AUTH](state: AuthState): void;
    [AuthMutation.SET_USER](state: AuthState, payload: { user: User | null }): void;
    [AuthMutation.SET_PRAVA](state: AuthState, payload: { prava: UserRight[] | null }): void;
}

export const authMutations: MutationTree<AuthState> & AuthMutationTypes = {
    [AuthMutation.INIT_AUTH](state: AuthState) {
        state.expiration = localStorage.getItem('cms_expi') ? new Date(localStorage.getItem('cms_expi') || '') : null;
        state.token = localStorage.getItem('cms_token');
        state.type = localStorage.getItem('cms_type');
        state.ad = localStorage.getItem('cms_ad') == "true";
    },

    [AuthMutation.SET_AUTH]: (state: AuthState, payload: AuthResponse) => {
        state.token = payload.token;
        state.expiration = new Date(payload.expiration);
        state.type = payload.type;
        state.ad = payload.ad;

        localStorage.setItem('cms_token', payload.token);
        localStorage.setItem('cms_expi', new Date(payload.expiration).toISOString());
        localStorage.setItem('cms_type', payload.type);
        localStorage.setItem('cms_ad', state.ad + '');
    },

    [AuthMutation.CLEAR_AUTH]: (state: AuthState) => {
        state.token = null;
        state.expiration = null;
        state.type = null;

        state.user = null;
        state.prava = [];

        localStorage.removeItem('cms_token');
        localStorage.removeItem('cms_expi');
        localStorage.removeItem('cms_type');
        localStorage.removeItem('cms_refresh-token');
        localStorage.removeItem('cms_ad');
    },

    [AuthMutation.SET_USER]: (state: AuthState, payload: { user: User }) => {
        state.user = payload.user;
    },

    [AuthMutation.SET_PRAVA]: (state: AuthState, payload: { prava: UserRight[] | null }) => {
        state.prava = payload.prava || [];
    }
};
