












import { CenoTvorba, NemovitostCv } from '@/models/nemovitost';
import { orderBy } from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import PnpsCenoTvorbaComponent from '../pnps-cenotvorba.component.vue';

@Component({
    components: {
        'cms-pnps-cenotvorba': PnpsCenoTvorbaComponent
    }
})
export default class PnpsCenoTvorbaTab extends Vue {
    @Prop() value!: CenoTvorba[];
    @Prop() readonly!: boolean;
    @Prop({ required: true }) nemovitosts!: NemovitostCv[];

    cenoTvorbas: CenoTvorba[] = [];

    getKatalogoveCislo(nemovitostId?: number): string | undefined {
        return this.nemovitosts.find(f => f.nemovitostId == nemovitostId)?.katalogoveCislo;
    }

    @Watch('value', { immediate: true, deep: true })
    onChangeValue(): void {
        this.cenoTvorbas = orderBy(
            this.value.map(m => {
                m.katalogoveCislo = this.getKatalogoveCislo(m.nemovitostId);
                m.oznaceni = this.nemovitosts.find(f => f.nemovitostId == m.nemovitostId)?.oznaceni;
                m.typ = this.nemovitosts.find(f => f.nemovitostId == m.nemovitostId)?.nemovitost?.typNemovitost?.nazev;
                return new CenoTvorba(m);
            }),
            o => o.katalogoveCislo
        ).filter(f => !!f.katalogoveCislo);
    }

    updateCenoTvorba(e: CenoTvorba) {
        this.cenoTvorbas[this.cenoTvorbas.findIndex(f => f.id == e.id)] = e;

        this.$emit('input', this.cenoTvorbas);
    }
}
