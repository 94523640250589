






































































































































import { AppGetter } from '@/store/app/app.getter';
import { AuthGetter } from '@/store/auth/auth.getter';
import { AuthAction } from '@/store/auth/auth.types';
import { Modules } from '@/store/modules';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapActions, mapGetters } from 'vuex';

@Component({
    computed: {
        ...mapGetters(Modules.APP, [AppGetter.APPVERSION]),
        ...mapGetters(Modules.AUTH, [AuthGetter.CURRENT_USER, AuthGetter.USER_RIGHT])
    },
    methods: {
        ...mapActions(Modules.AUTH, [AuthAction.LOGOUT])
    }
})
export default class SideMenuComponent extends Vue {
    @Prop() isSmall!: boolean;

    darkMode = false;

    toggleDarkTheme() {
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
        localStorage.setItem('darkTheme', this.$vuetify.theme.dark.toString());
    }
    mounted() {
        const theme = localStorage.getItem('darkTheme');

        if (theme) {
            if (theme === 'true') {
                this.$vuetify.theme.dark = true;
            } else {
                this.$vuetify.theme.dark = false;
            }
        } else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            this.$vuetify.theme.dark = true;
            localStorage.setItem('darkTheme', this.$vuetify.theme.dark.toString());
        }
    }
}
