






























import { AppGetter } from '@/store/app/app.getter';
import { Modules } from '@/store/modules';
import { mapGetters } from 'vuex';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { PozadavekNaPripravuSmlouvy } from '@/models/pnps';

@Component({
    computed: {
        ...mapGetters(Modules.APP, [AppGetter.CURR_TIMEVERSION])
    }
})
export default class PnpsNemovitostTab extends Vue {
    @Prop() readonly!: boolean;
    @Prop() value!: PozadavekNaPripravuSmlouvy;

    headers = [
        { text: 'Smlouva', value: 'cislo' },
        { text: 'Nájemné', value: 'najemne' },
        { text: 'Služby Základní sazba', value: 'sluzbyZakladniSazba' },
        { text: 'Služby Snížená sazba', value: 'sluzbySnizenaSazba1' },
        { text: 'Služby 2. snížená sazba', value: 'sluzbySnizenaSazba2' },
        { text: 'Zálohy Základní sazba', value: 'zalohyZakladniSazba' },
        { text: 'Zálohy Snížená sazba', value: 'zalohySnizenaSazba1' },
        { text: 'Zálohy 2. Snížená sazba', value: 'zalohySnizenaSazba2' },
        { text: 'Servisní poplatek', value: 'servisniPoplatek' },
        { text: 'Celkem měsíčně s DPH', value: 'cenaNajemnePnpsCelkemSDph' },
        { text: 'Navýšení jistoty', value: 'navyseniJistoty' }
    ];

    datasource: any[] = [];
    dialog = false;

    async mounted() {
        this.datasource = (
            await this.$odata.function<any>('pnps', {
                func: { GetCelkoveCeny: { pnpsId: this.value.id } }
            })
        ).data.value;
    }
}
