




















































import { handleException } from '@/helpers';
import { Budova, Lokalita } from '@/models';
import { LokalitaCiselnaRada } from '@/models/lokalitaCiselnaRada';
import R from '@/models/resources';
import { VForm } from '@/models/vue-components';
import { SnackbarService } from '@/services/snackbar.service';
import { Component, Vue, Watch, Mixins, Ref } from 'vue-property-decorator';

@Component({
    components: {}
})
export default class LokalitaDetailPage extends Vue {
    @Ref('form') form!: VForm;

    formData = {
        id: +this.$route.params.lokId,
        nazev: ''
    };
    isSaving = false;
    isChanged = false;
    isReadonly = false;

    showDialog = false;
    iswNewDialog = false;

    ciselnaRadas: LokalitaCiselnaRada[] = [];
    rules = [
        (v: any) => {
            return v.length <= 6 || 'Nepovolená délka';
        },
        (v: any) => {
            const x = v.replace(/\s/g, '');
            const regexp = new RegExp('^[0-9]*$');
            if (!regexp.test(x)) {
                return 'Požadovaný formát čísel: x';
            }
        }
    ];

    editingItem: any = {};
    selectedItem: any = {};
    headers = [
        { text: 'Čislo od', value: 'cisloOd' },
        { text: 'Čislo do', value: 'cisloDo' },
        { text: '', value: 'odebrat' }
    ];

    editItem(item: LokalitaCiselnaRada) {
        this.selectedItem = item;
        this.editingItem = { ...this.selectedItem };
        this.showDialog = true;
    }
    newItem() {
        this.editingItem = {
            cmsLokalitaId: +this.$route.params.lokId,
            cisloOd: null,
            cisloDo: null
        };
        this.showDialog = this.iswNewDialog = true;
    }

    removeItem(item: LokalitaCiselnaRada) {
        const index = this.ciselnaRadas.indexOf(item);

        this.ciselnaRadas = [...this.ciselnaRadas.filter(x => x != item)];
    }

    close() {
        this.showDialog = this.iswNewDialog = false;
        this.form.resetValidation();
    }

    saveDialog() {
        if (!this.form.validate()) return;

        if (this.iswNewDialog) {
            this.ciselnaRadas.push(this.editingItem);
        } else {
            this.selectedItem.cisloDo = this.editingItem.cisloDo;
            this.selectedItem.cisloOd = this.editingItem.cisloOd;
        }
        this.close();
    }

    async save(): Promise<void> {
        let kolizeCiselnychRad = false;
        this.ciselnaRadas.forEach(c => {
            if (this.ciselnaRadas.filter(x => x != c && c.cisloOd <= x.cisloDo && c.cisloDo >= x.cisloOd).length) {
                kolizeCiselnychRad = true;
            }
        });

        if (kolizeCiselnychRad) this.$snackbar.showSnackbar({ text: 'Kolize číselných řad', color: 'error' });
        else {
            this.isSaving = true;

            await this.$odata
                .update('lokalita', this.$route.params.lokId, {
                    id: +this.$route.params.lokId,
                    lokalitaCiselnaRadas: this.ciselnaRadas
                })
                .then(x => {
                    this.$snackbar.showSnackbar({ text: R.Ulozeno, color: 'success' });

                    this.isChanged = false;
                })
                .catch(x => {
                    this.$snackbar.showSnackbar({
                        text: 'Chyba v ukládání, odstraněné číselné řady obsahují vazby na budovy',
                        color: 'error'
                    });
                });

            this.isSaving = false;
        }
    }

    @Watch('$route.params.lokId', { immediate: true })
    async onChangeLokId() {
        if (this.$route.params.lokId) {
            const lokId = +this.$route.params.lokId;
            const result = (
                await this.$odata.getList<Lokalita>('lokalita', {
                    filter: { id: { eq: lokId } },
                    top: 1
                })
            ).data.value;
            if (result.length) {
                this.formData.id = lokId;
                this.formData.nazev = result[0].obchodniNazev;

                this.ciselnaRadas = (
                    await this.$odata.getList<LokalitaCiselnaRada>('lokalitaCiselnaRada', {
                        filter: { cmsLokalitaId: { eq: lokId } }
                    })
                ).data.value;
            }
        }
    }
}
