

































import { GridOptions, ODataResult, Report } from '@/models';

import { QueryOptions } from 'odata-query';

import { Component, Vue } from 'vue-property-decorator';

@Component
export default class MojeReporty extends Vue {
    data: ODataResult<Report> | null = null;
    isLoading = false;

    headers = [
        { text: 'ID', value: 'id' },
        { text: 'Název', value: 'nazev' },
        { text: 'Popis', value: 'popis' }
    ];

    options: GridOptions = {
        page: 1,
        itemsPerPage: 15,
        sortBy: ['id'],
        sortDesc: [false],
        multiSort: true,
        mustSort: false
    };

    filter: any = {};

    rowClick(e: Report): void {
        this.$router.push({ name: 'myreporter.report', params: { reportId: e.id.toString() } });
    }

    mounted() {
        this.fetchData();
    }

    onFilterSubmit(): void {
        this.options.page = 1;
        this.prepareFilter();
    }

    prepareFilter() {
        this.fetchData();
    }

    resetFilter(): void {
        this.setDefaultFilter();

        this.options.page = 1;
        this.prepareFilter();
    }

    setDefaultFilter() {
        this.filter = {};
    }

    async fetchData(): Promise<void> {
        this.isLoading = true;
        const filter = [];
        if (this.filter.id) filter.push({ id: { eq: this.filter.id } });
        if (this.filter.nazev) filter.push({ 'toLower(nazev)': { contains: this.filter.nazev.toLowerCase() } });

        const query: Partial<QueryOptions<Report>> = {
            func: 'GetReportyDlePrav()',
            filter,
            select: ['id', 'nazev', 'popis'],
            top: this.options.itemsPerPage,
            count: true,
            skip: (this.options.page - 1) * this.options.itemsPerPage,
            orderBy: this.options.sortBy.map((sort, index) => sort + (this.options.sortDesc[index] ? ' desc' : '')) as any
        };

        const tmpData = await this.$odata.function<any>('report', query).then(r => {
            this.data = r.data;
        });
        this.isLoading = false;
    }
}
