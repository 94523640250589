import { ODataService } from '@/services/odata.service';
import { Partner } from '@/models/partner';

export function kontrolaDuplicita(email: string, telefon: string, ico: string) {
    const odata = new ODataService();

    const or: any[] = [];
    const osobasFilter = [];

    const filter = [{ nazev: { ne: null } }, { or: or }];

    if (!email && !telefon && !ico) {
        return Promise.resolve([]);
    }

    if (email) {
        or.push({ osobas: { any: { 'toLower(email)': email.toLowerCase() } } });
        or.push({ 'toLower(email)': { contains: email.toLowerCase() } });

        osobasFilter.push({ 'toLower(email)': { contains: email.toLowerCase() } });
    }

    if (telefon) {
        or.push({ osobas: { any: { tel: telefon } } });
        or.push({ telefon1: { contains: telefon } });
        or.push({ telefon2: { contains: telefon } });
        or.push({ telefon3: { contains: telefon } });

        osobasFilter.push({ tel: { contains: telefon } });
    }

    if (ico && ico.trim().length) {
        or.push({ ico: { eq: ico } });
    }

    if (!email && !telefon && !ico) {
        return Promise.resolve([]);
    }

    const filterOsoba: any[] = [{ id: { ne: null } }, { or: osobasFilter }];

    return odata
        .getList<Partner>('partner', {
            expand: {
                osobas: { filter: filterOsoba }
            },
            filter
        })
        .then(a => a.data.value);
}

export function isDuplicita(partner: Partner, val: string): boolean {
    if (
        val &&
        ((partner.osobas[0].email && val.toLowerCase() === partner.osobas[0].email.toLowerCase()) ||
            (partner.osobas[0].tel && val === partner.osobas[0].tel) ||
            (partner.ico && val.toLowerCase() === partner.ico.toLowerCase()))
    ) {
        return true;
    }
    return false;
}
