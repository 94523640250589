import { Breadcrump } from '@/models/breadcrump';
import { Snackbar } from '@/models/snackbar';
import { TimeVersion } from '@/models/timeversion';
import { GetterTree } from 'vuex';
import { Modules } from '../modules';
import { AppState } from './app.state';

export enum AppGetter {
    APIURL = 'APIURL',
    FILEURL = 'FILEURL',
    FILEUPLOADURL = 'FILEUPLOADURL',
    APPVERSION = 'APPVERSION',

    CURR_TIMEVERSION = 'CURR_TIMEVERSION',

    TIMEVERSIONS = 'TIMEVERSIONS',
    TIMEVERSIONS_ARCHIVE = 'TIMEVERSIONS_ARCHIVE',
    TIMEVERSIONS_NEW = 'TIMEVERSIONS_NEW',

    BREADCRUMPS = 'BREADCRUMPS',

    SNACKBAR = 'SNACKBAR'
}

export enum ModAppGetter {
    APIURL = Modules.APP + '/' + AppGetter.APIURL,
    FILEURL = Modules.APP + '/' + AppGetter.FILEURL,
    FILEUPLOADURL = Modules.APP + '/' + AppGetter.FILEUPLOADURL,
    APPVERSION = Modules.APP + '/' + AppGetter.APPVERSION,


    CURR_TIMEVERSION = Modules.APP + '/' + AppGetter.CURR_TIMEVERSION,
    TIMEVERSIONS = Modules.APP + '/' + AppGetter.TIMEVERSIONS,
    TIMEVERSIONS_ARCHIVE = Modules.APP + '/' + AppGetter.TIMEVERSIONS_ARCHIVE,
    TIMEVERSIONS_NEW = Modules.APP + '/' + AppGetter.TIMEVERSIONS_NEW,

    BREADCRUMPS = Modules.APP + '/' + AppGetter.BREADCRUMPS,

    SNACKBAR = Modules.APP + '/' + AppGetter.SNACKBAR
}

export interface AppGetterTypes {
    [AppGetter.APIURL](state: AppState): string | null;
    [AppGetter.FILEURL](state: AppState): string | null;
    [AppGetter.APPVERSION](state: AppState): string | null;

    [AppGetter.CURR_TIMEVERSION](state: AppState): TimeVersion | null;

    [AppGetter.TIMEVERSIONS](state: AppState): TimeVersion[];
    [AppGetter.TIMEVERSIONS_ARCHIVE](state: AppState): TimeVersion[];
    [AppGetter.TIMEVERSIONS_NEW](state: AppState): TimeVersion[];

    [AppGetter.BREADCRUMPS](state: AppState): Breadcrump[];
    [AppGetter.SNACKBAR](state: AppState): Snackbar | null;
}

export const appGetters: GetterTree<AppState, any> & AppGetterTypes = {
    [AppGetter.APIURL]: state => {
        return state.apiUrl;
    },

    [AppGetter.FILEURL]: state => {
        return state.fileUrl;
    },

    [AppGetter.APPVERSION]: state => {
        return state.appVersion;
    },

    [AppGetter.CURR_TIMEVERSION]: state => {
        return state.timeversion;
    },

    [AppGetter.TIMEVERSIONS]: state => {
        return state.timeversions;
    },

    [AppGetter.TIMEVERSIONS_ARCHIVE]: state => {
        return state.timeversionsArchive;
    },

    [AppGetter.TIMEVERSIONS_NEW]: state => {
        return state.timeversionsNew;
    },

    [AppGetter.BREADCRUMPS]: state => {
        return state.breadcrumps;
    },

    [AppGetter.SNACKBAR]: state => {
        return state.snackbar;
    }

    
};
