

















































































































































































































































































































































































































































































































































import { Component, Vue, Watch, Mixins, Ref } from 'vue-property-decorator';

import { TypNemovitost, Budova, Podlazi, Vybaveni, ODataResult } from '@/models';
import R from '@/models/resources';
import { NemovitostCv, NemovitostInput, NemovitostDetail, CenoTvorba } from '@/models/nemovitost';
import { handleException } from '@/helpers';
import { QueryOptions } from 'odata-query/dist';

import NotSavedComponent from '@/ui/components/not-saved.component';
import { VForm } from '@/models/vue-components';
import { ObchodniPripad } from '@/models/obp';
import { PozadavekNaPripravuSmlouvy } from '@/models/pnps';
import { RightService } from '@/services/right.service';

@Component
export default class NemovitostDetailPage extends Mixins(Vue, NotSavedComponent) {
    @Ref('form') form!: VForm;

    isSaving = false;
    isLoadingObp = false;
    isReadOnly = true;

    nextNumber = 0;

    data: ODataResult<PozadavekNaPripravuSmlouvy> | null = null;

    @Watch('formData.plocha')
    onChange() {
        if (this.formData.cenoTvorba != null) {
            this.formData.cenoTvorba.plocha = this.formData.plocha;
        }
    }

    @Watch('$route.query.lokId', { immediate: true })
    onChangeLokId() {
        if (this.$route.query.lokId && !this.$route.params.nemCvId) {
            const lokId = +this.$route.query.lokId;
            if (!isNaN(lokId)) {
                this.formData.cmsLokalitaId = lokId;
            }
        }
    }

    @Watch('formData', { deep: true })
    onChangeFormData() {
        this.isChanged = true;
    }

    @Watch('$route.params.nemCvId', { immediate: true })
    async onChangeParams(nemCvId: any): Promise<void> {
        if (nemCvId) {
            const query: Partial<QueryOptions<NemovitostCv>> = {
                expand: { vybavenis: {}, cenoTvorba: {}, nemovitost: { expand: { podlazis: {} } } }
            };
            const nemovitost = (await this.$odata.getById<NemovitostCv>('nemovitostCv', +nemCvId, query)).data;

            this.formData = {
                id: +nemCvId,
                nemovitostId: nemovitost.nemovitostId,
                budovaId: nemovitost.nemovitost.budovaId,
                cmsLokalitaId: nemovitost.nemovitost.cmsLokalitaId,
                podlazis: (nemovitost.nemovitost.podlazis || []).map(m => m.id),
                vybavenis: (nemovitost.vybavenis || []).map(m => m.id),
                cislo: nemovitost.cislo,
                oznaceni: nemovitost.oznaceni,
                katalogoveCislo: nemovitost.katalogoveCislo,
                vyskaStrop: nemovitost.vyskaStrop,
                plocha: nemovitost.plocha,
                garanceNajemDo: nemovitost.garanceNajemDo ? nemovitost.garanceNajemDo : null,
                typNemovitostId: nemovitost.nemovitost.typNemovitostId,
                cenoTvorba: new CenoTvorba(nemovitost.cenoTvorba),
                poznamka: nemovitost.poznamka,
                nepronajmutelne: nemovitost.nepronajmutelne
            };

            this.fetchData();

            this.$nextTick(() => {
                this.isChanged = false;
            });
        }
    }

    budovas: Budova[] = [];
    podlazis: Podlazi[] = [];
    nemovitostTypes: TypNemovitost[] = [];
    vybavenis: Vybaveni[] = [];

    vybaveniInput = '';

    formData: NemovitostDetail = {
        cmsLokalitaId: +this.$route.params.lokId,
        podlazis: [],
        vybavenis: [],
        poznamka: '',
        nepronajmutelne: false,
        cenoTvorba: new CenoTvorba()
    };

    async mounted(): Promise<void> {
        this.nemovitostTypes = (await this.$odata.getList<TypNemovitost>('typnemovitost')).data.value;
        this.podlazis = (await this.$odata.getList<Podlazi>('podlazi')).data.value;
        this.vybavenis = (await this.$odata.getList<Vybaveni>('vybaveni')).data.value;
        this.isReadOnly = !new RightService().hasWrite(this.$route.meta.right);
    }

    @Watch('formData.cmsLokalitaId', { immediate: true })
    async onChangeCmsLokalitaId(val: any) {
        if (val) {
            this.budovas = (await this.$odata.getList<Budova>('budova', { filter: { cmsLokalitaId: { eq: +val } } })).data.value;

            if (!this.$route.params.nemCvId) {
                const result = (
                    await this.$odata.getList<NemovitostCv>('nemovitostCv', {
                        select: ['cislo'],
                        filter: { nemovitost: { cmsLokalitaId: { eq: +val }, budovaId: { eq: null } } },
                        top: 1,
                        orderBy: 'cislo desc'
                    })
                ).data.value;
                if (result.length) {
                    this.nextNumber = result[0].cislo + 1;
                } else {
                    this.nextNumber = 1;
                }

                this.formData.cislo = this.nextNumber;
                this.$nextTick(() => {
                    this.$forceUpdate();
                });
            } else {
                this.nextNumber = 0;
            }
        } else {
            this.nextNumber = 0;
            this.formData.cislo = null;

            this.$nextTick(() => {
                this.$forceUpdate();
            });
        }
    }

    @Watch('formData.budovaId')
    async onChangeBudovaId() {
        if (!this.$route.params.nemCvId) {
            const result = (
                await this.$odata.getList<NemovitostCv>('nemovitostCv', {
                    select: ['cislo'],
                    filter: {
                        nemovitost: { cmsLokalitaId: { eq: this.formData.cmsLokalitaId }, budovaId: { eq: this.formData.budovaId } }
                    },
                    top: 1,
                    orderBy: 'cislo desc'
                })
            ).data.value;

            if (result.length) {
                this.nextNumber = result[0].cislo + 1;
            } else {
                this.nextNumber = 1;
            }

            this.formData.cislo = this.nextNumber;
            this.$nextTick(() => {
                this.$forceUpdate();
            });
        } else {
            this.nextNumber = 0;
        }
    }

    removeVybaveni(id: never) {
        this.formData.vybavenis = [...this.formData.vybavenis.filter(f => f != id)];
    }

    async save(): Promise<void> {
        if (!this.form.validate()) return;

        this.isSaving = true;

        const nemovitostInput: NemovitostInput = {
            id: +this.$route.params.nemCvId || null,
            budovaId: this.formData.budovaId || null,
            cislo: this.formData.cislo || null,
            plocha: this.formData.plocha || 0,
            typNemovitostId: this.formData.typNemovitostId || null,
            cmsLokalitaId: +this.formData.cmsLokalitaId,
            oznaceni: String(this.formData.oznaceni),
            vyskaStrop: this.formData.vyskaStrop || null,
            garanceNajemDo: this.formData.garanceNajemDo ? new Date(this.formData.garanceNajemDo) : null,
            podlaziIds: this.formData.podlazis,
            vybaveniIds: this.formData.vybavenis,
            poznamka: this.formData.poznamka,
            nepronajmutelne: this.formData.nepronajmutelne,
            cenoTvorba: {
                plocha: this.formData.cenoTvorba.plocha || 0,

                cenaNajemneDN: this.formData.cenoTvorba.cenaNajemneDN,
                cenaNajemneDU1Rok: this.formData.cenoTvorba.cenaNajemneDU1Rok,
                cenaNajemneDUKonecZivotnosti: this.formData.cenoTvorba?.cenaNajemneDUKonecZivotnosti,
                cenaNajemneFixni: this.formData.cenoTvorba.cenaNajemneFixni,

                cenaSluzbySazbaZaklad: this.formData.cenoTvorba.cenaSluzbySazbaZaklad,
                cenaSluzbySazba1: this.formData.cenoTvorba.cenaSluzbySazba1,
                cenaSluzbySazba2: this.formData.cenoTvorba.cenaSluzbySazba2,

                cenaZalohySazbaZaklad: this.formData.cenoTvorba.cenaZalohySazbaZaklad,
                cenaZalohySazba1: this.formData.cenoTvorba.cenaZalohySazba1,
                cenaZalohySazba2: this.formData.cenoTvorba.cenaZalohySazba2,

                cenaServisniPoplatek: this.formData.cenoTvorba.cenaServisniPoplatek
            }
        };

        if (nemovitostInput.id) {
            try {
                await this.$odata.update('nemovitostCv', nemovitostInput.id, nemovitostInput);
                this.$snackbar.showSnackbar({ text: R.Ulozeno, color: 'success' });

                this.isChanged = false;
            } catch (ex) {
                handleException(ex);
            }
        } else {
            try {
                const result = await this.$odata.create<NemovitostCv>('nemovitostCv', nemovitostInput);
                this.$snackbar.showSnackbar({ text: R.Ulozeno, color: 'success' });

                this.isChanged = false;

                this.$router.replace({
                    name: 'nemovitost.detail',
                    params: { lokId: String(nemovitostInput.cmsLokalitaId), nemCvId: String(result.data.id) }
                });
            } catch (ex) {
                handleException(ex);
            }
        }

        this.isSaving = false;
    }

    async fetchData(): Promise<void> {
        this.isLoadingObp = true;
        const filter: any[] = [];
        //Načti PNPS které mají nemovitost přidanou a nemají odebrání (žádný dodatek ji neodebral)
        filter.push({
            pnpsNemovitostVazbas: {
                any: { nemovitostId: { eq: +(this.formData.nemovitostId || 0) }, pnpsOdebralId: { eq: null } }
            }
        });
        //Smlouva není ve stavu Storno
        filter.push({ not: { stavZadostId: { eq: 9 } } });
        this.data = (
            await this.$odata.getList<PozadavekNaPripravuSmlouvy>('pnps', {
                filter,
                expand: { najemcePartner: {}, stavPronajem: {}, stavZadost: {}, zadatelUzivatel: {}, zpracovatelUzivatel: {} }
            })
        ).data;

        this.isLoadingObp = false;
    }

    getItemClass(e: ObchodniPripad) {
        const stav = e.stavPronajemId;
        const r = stav === 2 ? 'stav-prodeje-nur' : stav === 3 ? 'stav-prodeje-zur' : stav === 4 ? 'stav-prodeje-prodano' : '';
        return r;
    }

    rowClick(pnps: PozadavekNaPripravuSmlouvy) {
        if (pnps.parentPnpsId) {
            this.$router.push({
                name: 'podpozadavek.pnps.detail',
                params: { pnpsId: String(pnps.parentPnpsId), podPnpsId: String(pnps.id) }
            });
        } else {
            this.$router.push({
                name: 'pnps.detail',
                params: { pnpsId: String(pnps.id) }
            });
        }
    }

    options = {
        page: 1,
        itemsPerPage: 15,
        sortBy: ['stavPronajemId'],
        sortDesc: [true],
        multiSort: true,
        mustSort: false
    };

    headers = [
        { text: 'Id', value: 'id' },
        { text: 'Nájemce', value: 'najemcePartner.nazev' },
        { text: 'Stav', value: 'stavPronajem.zkratka' },
        { text: 'Stav žádosti', value: 'stavZadost.nazev' },
        { text: 'Číslo', value: 'cisloSmlouvyText' },
        { text: 'Nájem od', value: 'najemOd' },
        { text: 'Nájem do', value: 'najemDo' },
        { text: 'Žadatel', value: 'zadatelUzivatel.fullName', sortable: false }
    ];
}
