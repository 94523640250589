import { Snackbar } from '@/models/snackbar';
import store from '@/store';
import { ModAppMutation } from '@/store/app/app.mutation';

export class SnackbarService {
    success(text: string) {
        store.commit(ModAppMutation.SHOW_SNACKBAR.toString(), { text, color: 'success' });
    }

    info(text: string) {
        store.commit(ModAppMutation.SHOW_SNACKBAR.toString(), { text, color: 'info' });
    }

    warn(text: string) {
        store.commit(ModAppMutation.SHOW_SNACKBAR.toString(), { text, color: 'warning' });
    }

    error(text: string) {
        store.commit(ModAppMutation.SHOW_SNACKBAR.toString(), { text, color: 'error' });
    }

    showSnackbar(snackbar: Snackbar) {
        store.commit(ModAppMutation.SHOW_SNACKBAR.toString(), snackbar);
    }
}
