import { RouteConfig } from 'vue-router';

import PnpsListPage from '@/ui/pages/pnps/pnps-list.page.vue';
import PnpsDetailPage from '@/ui/pages/pnps/pnps-detail.page.vue';
import PodpozadavekPnpsDetailPage from '@/ui/pages/pnps/podpozadavek-pnps-detail.page.vue';

export const PNPS_ROUTES: RouteConfig[] = [
    {
        path: '',
        component: PnpsListPage,
        name: 'pnps.list'
    },
    {
        path: ':pnpsId',
        component: PnpsDetailPage,
        name: 'pnps.detail',
        meta: {
            right: 'kwPnpsDetail',
            breadcrump: 'Požadavek na přípravu smlouvy'
        }
    },
    {
        path: ':pnpsId/podpozadavek/:podPnpsId',
        component: PodpozadavekPnpsDetailPage,
        name: 'podpozadavek.pnps.detail',
        meta: {
            right: 'kwPnpsDetail',
            breadcrumps: [
                {
                    text: 'PNPS',
                    href: '/pnps/:pnpsId'
                }
            ],
            breadcrump: 'Podpožadavek - PNPS'
        }
    },
    {
        path: '*',
        redirect: 'pnps.list'
    }
];
