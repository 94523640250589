






























import { ODataResult } from '@/models';
import { LokalitaCiselnaRada } from '@/models/lokalitaCiselnaRada';
import CmsTextFieldComponent from '@/ui/components/inputs/text-field.component.vue';
import CmsListComponent from '@/ui/components/list/list.component.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';

@Component({
    components: {
        'cms-list': CmsListComponent,
        'cms-text-field': CmsTextFieldComponent
    }
})
export default class BudovaListPage extends Vue {
    item = {
        id: '',
        cislo: '',
        nazev: '',
        penb: false,
        cmsLokalitaId: 0,
        lokalitaCiselnaRadaId: null
    };
    expand = { lokalitaCiselnaRada: {} };
    headers = [
        { text: 'Číslo Lokality', value: 'lokalitaCislo' },
        { text: 'Lokalita', value: 'lokalitaNazev' },
        { text: 'Číslo', value: 'cislo' },
        { text: 'Název', value: 'nazev' }
        /*  { text: 'PENB', value: 'penb' }*/
    ];

    radas: any[] = [];

    async edit(item: any) {
        await this.lokalitaZmena(item.cmsLokalitaId);
    }

    async lokalitaZmena(lokalitaId: number | null) {
        this.radas = [];
        if (lokalitaId) {
            (
                await this.$odata.getList<LokalitaCiselnaRada>('LokalitaCiselnaRada', {
                    filter: {
                        cmsLokalitaId: { eq: lokalitaId }
                    },
                    orderBy: 'cisloOd asc'
                })
            ).data.value.forEach(r =>
                this.radas.push({
                    id: r.id,
                    cisloOd: r.cisloOd,
                    cisloDo: r.cisloDo,
                    nazev: (r.cisloOd ?? 0) + ' - ' + (r.cisloDo ?? 9999999)
                })
            );
        }
    }
}
