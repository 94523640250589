









































































import { GridOptions, Lokalita, ODataResult, StavPronajem } from '@/models';
import { ObchodniPripad } from '@/models/obp';
import { QueryOptions } from 'odata-query';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { uniq } from 'lodash';
import { mapGetters } from 'vuex';
import { Modules } from '@/store/modules';
import { AppGetter } from '@/store/app/app.getter';

@Component({
    computed: {
        ...mapGetters(Modules.APP, [AppGetter.CURR_TIMEVERSION])
    }
})
export default class ObpListPage extends Vue {
    data: ODataResult<ObchodniPripad> | null = null;
    stavPronajems: ODataResult<StavPronajem> | null = null;
    lokalitas: Lokalita[] = [];
    isLoading = false;

    async mounted(): Promise<void> {
        this.stavPronajems = (await this.$odata.getList<StavPronajem>('stavpronajem')).data;
    }

    @Watch('$route.query', { immediate: true })
    onChangeRouteQuery() {
        if (this.$route.query.filter) {
            this.filter = JSON.parse(String(this.$route.query.filter));
        } else {
            this.setDefaultFilter();
        }

        if (this.$route.query.options) {
            this.options = JSON.parse(String(this.$route.query.options));
        } else {
            this.setDefaultOptions();
        }

        this.fetchData();
    }

    options: GridOptions = {
        page: 1,
        itemsPerPage: 15,
        sortBy: ['cisloObp', 'id'],
        sortDesc: [false, false],
        multiSort: true,
        mustSort: false
    };

    headers = [
        { text: 'Číslo', value: 'cisloObp' },
        { text: 'Id', value: 'id' },
        { text: 'Lokalita', value: 'lokalitaNazev', sortable: false },
        { text: 'Partner', value: 'partner.nazev' },
        { text: 'Draft', value: 'isDraft' },
        { text: 'Stav', value: 'stavPronajem.zkratka' },
        { text: 'Nájem od', value: 'najemOd' },
        { text: 'Nájem do', value: 'najemDo' },
        { text: 'Prodejce', value: 'prodejceUzivatel.fullName', sortable: false }
    ];

    setDefaultFilter() {
        this.filter = {
            partnerId: 0,
            lokalitaId: 0,
            stavPronajems: [],
            isDraftOnly: false,
            najemOd: null,
            najemDo: null,
            prodejceUzivatelid: 0
        };
    }

    setDefaultOptions() {
        this.options = {
            page: 1,
            itemsPerPage: 15,
            sortBy: ['cisloObp', 'id'],
            sortDesc: [false, false],
            multiSort: true,
            mustSort: false
        };
    }

    getLokalitaNazev(lokalitaId: number) {
        return (this.lokalitas || []).find(f => f.id == lokalitaId)?.nazev;
    }

    async fetchData(): Promise<void> {
        this.isLoading = true;

        const filter: any[] = [];
        if (this.filter.lokalitaId) filter.push({ lokalitaId: { eq: this.filter.lokalitaId } });
        if (this.filter.stavPronajems.length) filter.push({ stavPronajemId: { in: this.filter.stavPronajems } });
        if (this.filter.isDraftOnly) filter.push({ isDraft: { eq: true } });
        if (this.filter.najemOd) filter.push({ najemOd: { ge: new Date(String(this.filter.najemOd)) } });
        if (this.filter.najemDo) filter.push({ najemDo: { le: new Date(String(this.filter.najemDo)) } });
        if (this.filter.prodejceUzivatelid) filter.push({ prodejceUzivatelid: { eq: this.filter.prodejceUzivatelid } });
        if (this.filter.partnerId) filter.push({ partnerId: { eq: this.filter.partnerId } });
        const query: Partial<QueryOptions<ObchodniPripad>> = {
            top: this.options.itemsPerPage > 0 ? this.options.itemsPerPage : 100,
            expand: {
                partner: { select: ['nazev'] },
                prodejceUzivatel: {},
                nemovitosts: { expand: { nemovitostCvs: {} } }
                // stavPronajem: { select: ['zkratka'] }
            },
            count: true,
            skip: (this.options.page - 1) * this.options.itemsPerPage,
            filter,
            orderBy: this.options.sortBy.map(
                (sort, index) => sort.replaceAll('.', '/') + (this.options.sortDesc[index] ? ' desc' : '')
            ) as any
        };

        const tmpData = (await this.$odata.getList<ObchodniPripad>('obchodnipripad', query)).data;

        if (tmpData['@odata.count']) {
            this.lokalitas = (
                await this.$odata.getList<Lokalita>('lokalitaView', {
                    filter: { id: { in: uniq(tmpData.value.map(m => m.lokalitaId)) } }
                })
            ).data.value;
        } else {
            this.lokalitas = [];
        }

        this.data = tmpData;

        this.isLoading = false;
    }

    onFilterSubmit(): void {
        this.options.page = 1;
        this.prepareFilter();
    }

    filter = {
        partnerId: 0,
        lokalitaId: 0,
        stavPronajems: [],
        isDraftOnly: false,
        najemOd: null,
        najemDo: null,
        prodejceUzivatelid: 0
    };

    resetFilter(): void {
        this.setDefaultFilter();

        this.options.page = 1;
        this.prepareFilter();
    }

    prepareFilter() {
        if (this.$route.query.filter) {
            this.$router
                .push({
                    path: this.$route.path,
                    query: { filter: JSON.stringify(this.filter), options: JSON.stringify(this.options) }
                })
                .catch(() => {});
        } else {
            this.$router
                .replace({
                    path: this.$route.path,
                    query: { filter: JSON.stringify(this.filter), options: JSON.stringify(this.options) }
                })
                .catch(() => {});
        }
    }

    rowClick(obp: ObchodniPripad) {
        this.$router.push({ name: 'obp.detail', params: { obpId: String(obp.id) } });
    }
}
