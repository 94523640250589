import { render, staticRenderFns } from "./obp-history.component.vue?vue&type=template&id=783b6848&"
import script from "./obp-history.component.vue?vue&type=script&lang=ts&"
export * from "./obp-history.component.vue?vue&type=script&lang=ts&"
import style0 from "./obp-history.component.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VChip,VChipGroup,VIcon,VSimpleTable})
