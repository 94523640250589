import store from '@/store';
import { ModAppGetter } from '@/store/app/app.getter';
import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';

export class FileService extends BaseService {
    fileUpload(formData: FormData): Promise<AxiosResponse<any>> {
           //const prodUrl = 'https://www.central-group.cz/webservices/fileuploader.asmx/UploadFileWebCms';
 
        return this.post<any>(store.getters[ModAppGetter.FILEURL]  + '/webservices/fileuploader.asmx/UploadFileWebCms'/*prodUrl*/ , formData);
    }

    fileDownload(path: string): Promise<AxiosResponse<any>> {
        return this.get<any>(store.getters[ModAppGetter.FILEURL] + `/uloziste/${path.substring(0, 2)}/${path}`);
    }
}
