

















































import { TypAdresa } from '@/models';
import { PartnerAdresa } from '@/models/partner';
import { Component, Prop, Vue } from 'vue-property-decorator';
import CmsCisloPopisneFieldComponent from '../inputs/cislo-popisne-field.vue';
import CmsPSCFieldComponent from '../inputs/psc-field.component.vue';
import CmsTextFieldComponent from '../inputs/text-field.component.vue';

@Component({
    components: {
        'cms-text-field': CmsTextFieldComponent,
        'cms-cislo-field': CmsCisloPopisneFieldComponent,
        'cms-psc-field': CmsPSCFieldComponent
    }
})
export default class PartnerAdresaComponent extends Vue {
    @Prop() value!: PartnerAdresa;
    @Prop() typAdresa!: number;

    @Prop({ type: Boolean, default: false }) required!: boolean;

    async mounted(): Promise<void> {
        let filter;
        if (this.typAdresa) {
            filter = { typAdresaId: +this.typAdresa };
        }
        this.adresaTypes = (await this.$odata.getList<TypAdresa>('typadresa', { filter })).data.value;
    }

    adresaTypes: TypAdresa[] = [];

    get editAdresa() {
        if (this.typAdresa) {
            return { ...this.value, typAdresaId: this.typAdresa };
        } else {
            return this.value;
        }
    }

    update(key: string, value: any) {
        this.$emit('input', { ...this.editAdresa, [key]: value });
    }
}
