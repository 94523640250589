
















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class NemovitostListSmallComponent extends Vue {
    @Prop({ required: true }) data!: any[];
    @Prop({ required: true }) isLoading!: boolean;
    @Prop({ required: true }) najemcePartnerId!: number;

    @Prop({ required: false }) headers?: any[];

    @Prop({ type: Boolean }) showFooter!: boolean;

    get cols(): any[] {
        return this.headers || this.defaultHeaders;
    }

    defaultHeaders = [
        { text: 'Katalogové číslo', value: 'katalogoveCislo' },
        { text: 'Typ nemovitosti', value: 'typNemovitost' },

        { text: 'Lokalita', value: 'lokalita', sortable: false },
        { text: 'Budova', value: 'budova' },

        { text: 'Nájem od', value: 'najemOd' },
        { text: 'Nájem do', value: 'najemDo' },
        { text: 'Garance nájmu', value: 'garanceNajemDo' },
        { text: 'Označení (interní)', value: 'oznaceni' },
        { text: 'Nájemné', value: 'najemne' },
        { text: 'Cena služeb', value: 'cenaSluzebBezDph' },
        { text: 'Plocha', value: 'plocha' }
    ];
}
