









































































































import { Budova, ODataResult, Podlazi, StavPronajem, TypNemovitost, Vybaveni } from '@/models';
import { NemovitostFilter } from '@/models/nemovitost';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class NemovitostFilterComponent extends Vue {
    @Prop() value: NemovitostFilter = { pronajmutelne: 1 };

    typNemovitosts: ODataResult<TypNemovitost> | null = null;
    stavPronajems: ODataResult<StavPronajem> | null = null;
    vybavenis: ODataResult<Vybaveni> | null = null;
    budovas: ODataResult<Budova> | null = null;
    podlazis: ODataResult<Podlazi> | null = null;
    pronajmutelne: any[] = [
        { id: 0, name: 'Vše' },
        { id: 1, name: 'Pronajmutelné' },
        { id: 2, name: 'Nepronajmutelné' }
    ];

    async mounted(): Promise<void> {
        this.typNemovitosts = (await this.$odata.getList<TypNemovitost>('typnemovitost', { orderBy: ['id'] })).data;
        this.stavPronajems = (await this.$odata.getList<StavPronajem>('stavpronajem', { orderBy: ['id'] })).data;
        this.vybavenis = (await this.$odata.getList<Vybaveni>('vybaveni', { orderBy: ['nazev'] })).data;
    }

    @Watch('value.lokalitaId', { immediate: true })
    async onChangeLokalita(val: any) {
        const lokId = +val;
        if (!isNaN(lokId)) {
            this.budovas = (
                await this.$odata.getList<Budova>('budova', {
                    filter: { cmsLokalitaId: { eq: lokId } },
                    orderBy: ['nazev']
                })
            ).data;

            this.podlazis = (
                await this.$odata.function<ODataResult<Podlazi>>('podlazi', {
                    func: { GetProLokalita: { lokalitaId: +val } },
                    orderBy: ['nazev']
                })
            ).data;
        } else {
            this.podlazis = (await this.$odata.getList<Podlazi>('podlazi', { orderBy: ['nazev'] })).data;
            this.budovas = null;
        }
    }

    onChangeData(prop: string, val: any) {
        this.$emit('input', { ...this.value, [prop]: val });
    }
}
