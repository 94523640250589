export default class R {
    static Ulozeno = 'Uloženo';
    static Odstraneno = 'Odstraněno';
 
    static ChceteSmazatZaznam = 'Chcete opravdu smazat tento záznam?';
    static NeulozeneZmeny = 'Neuložené změny';
    static NeulozeneZmenyOtazka = 'Chcete odejít bez uložení změn?';

    static LoginChyba = 'Špatné uživatelské jméno nebo heslo';
    static NelzeSmazat = 'Položku nelze smazat'
}
