import { BaseService } from '@/services/base.service';
import { TimeVersionService } from '@/services/timeversion.service';
import { concat } from 'lodash';
import { ActionTree } from 'vuex';
import { Modules } from '../modules';
import { AppMutation } from './app.mutation';
import { AppState } from './app.state';
import { AugmentedActionContext } from './app.types';

export enum AppAction {
    INIT_TIMEVERSION = 'INIT_TIMEVERSION',
    INIT_PREPARED = 'INIT_PREPARED',
    INIT_PUBLISHED = 'INIT_PUBLISHED',
    INIT_TIMEVERSIONS = 'INIT_TIMEVERSIONS'
}

export enum ModAppAction {
    INIT_PREPARED = Modules.APP + '/' + AppAction.INIT_PREPARED,
    INIT_PUBLISHED = Modules.APP + '/' + AppAction.INIT_PUBLISHED,
    INIT_TIMEVERSIONS = Modules.APP + '/' + AppAction.INIT_TIMEVERSIONS,
    INIT_TIMEVERSION = Modules.APP + '/' + AppAction.INIT_TIMEVERSION
}

export interface AppActionsTypes {
    [AppAction.INIT_PREPARED](context: AugmentedActionContext): void;
    [AppAction.INIT_PUBLISHED](context: AugmentedActionContext): void;
    [AppAction.INIT_TIMEVERSIONS](context: AugmentedActionContext): void;
    [AppAction.INIT_TIMEVERSION](context: AugmentedActionContext): void;
}

export const appActions: ActionTree<AppState, any> & AppActionsTypes = {
    [AppAction.INIT_PREPARED]: async context => {
        const d = await new TimeVersionService().getPrepared();
        context.commit(AppMutation.SET_TIMEVERSION, { timeversion: d });
    },

    [AppAction.INIT_PUBLISHED]: async context => {
        const d = await new TimeVersionService().getPublished();
        context.commit(AppMutation.SET_TIMEVERSION, { timeversion: d });
    },

    [AppAction.INIT_TIMEVERSIONS]: async context => {
        const service = new TimeVersionService();

        const a = await service.getVersions();
        const b = await service.getVersionsArchive();
        const c = await service.getNewVersios();

        context.commit(AppMutation.SET_TIMEVERSIONS, { timeversions: concat(a, b, c) });
    },

    [AppAction.INIT_TIMEVERSION]: async context => {
        const tv = sessionStorage.getItem('cms_timeversion');

        if (tv && !isNaN(+tv)) {
            const timeversion = await new TimeVersionService().getById(+tv);
            context.commit(AppMutation.SET_TIMEVERSION, { timeversion: timeversion });
        } else {
            context.dispatch(AppAction.INIT_PUBLISHED);
        }
    }
};
