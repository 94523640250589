import { RouteConfig } from 'vue-router';
import ObpListPage from '@/ui/pages/obp/obp-list.page.vue';
import ObpDetailPage from '@/ui/pages/obp/obp-detail.page.vue';

export const OBP_ROUTES: RouteConfig[] = [
    {
        path: '',
        component: ObpListPage,
        name: 'obp.list'
    },
    {
        path: ':obpId',
        component: ObpDetailPage,
        name: 'obp.detail'
    },
    {
        path: '*',
        redirect: 'obp.list'
    }
];
