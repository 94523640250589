




import { mapGetters } from 'vuex';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Modules } from '@/store/modules';
import { AuthGetter } from '@/store/auth/auth.getter';
import { AppGetter } from '@/store/app/app.getter';
import { TimeVersion } from '@/models';

@Component({
    computed: {
        ...mapGetters(Modules.AUTH, [AuthGetter.TOKEN]),
        ...mapGetters(Modules.APP, [AppGetter.CURR_TIMEVERSION])
    }
})
export default class DesignerPage extends Vue {
    TOKEN!: string;
    CURR_TIMEVERSION!: TimeVersion;

    url = '';

    @Watch('TOKEN', { immediate: true })
    onChangeToken() {
        this.url =
            (process.env.NODE_ENV == 'production' ? '' : 'https://localhost:6001') +
            '/app/report?llt=' +
            this.TOKEN +
            '&reportId=' +
            this.$route.params.reportId +
            '&cv=' +
            this.CURR_TIMEVERSION.id +
            '&' +
            this.parseQuery();
    }

    get height() {
        return window.innerHeight - 64 - 32 + 'px';
    }

    parseQuery(): string {
        let result = '';

        Object.keys(this.$route.query).forEach(key => {
            result += key + '=' + this.$route.query[key];
        });

        return result;
    }
}
