







































































































































































































































import { ODataResult } from '@/models';
import { orderBy } from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class PnpsHistoryComponent extends Vue {
    @Prop() entityId!: number;

    bezZmeny = 'Bez změny';

    getProperty(index: number) {
        return this.properties[index];
    }

    showProperty(property: string) {
        return this.selected.some(a => a == this.properties.findIndex(f => f.value === property));
    }

    getValueOld(item: { oldValue: any }) {
        return item.oldValue || '(null)';
    }

    getDateValueOld(item: { oldValue: any }, format: string) {
        return this.$formatDate(item.oldValue, format);
    }

    getValueNew(item: { newValue: any }) {
        return item.newValue || '(null)';
    }

    getDateValueNew(item: { newValue: any }, format: string) {
        return this.$formatDate(item.newValue, format);
    }

    getDateValue(item: { oldValue: any; newValue: any }, format: string) {
        return item
            ? `${this.$formatDate(item.oldValue, format)}<v-icon>mdi-arrow-right</v-icon>${this.$formatDate(item.newValue, format)}`
            : 'Bez změny';
    }

    selected = [1];
    orderedSelected: number[] = [];

    @Watch('selected', { immediate: true })
    onChangeSelected() {
        this.isLoading = true;

        this.orderedSelected = orderBy(this.selected, v => v);

        this.$odata
            .function<ODataResult<any>>('pnps', {
                key: this.entityId,
                func: { GetHistory: { Show: this.selected.map(m => this.properties[m].value) } }
            })
            .then(res => {
                this.data = res.data.value;

                this.isLoading = false;
            });
    }

    data: any[] = [];
    dataNemovitosti: any[] = [];
    isLoading = false;

    nullText = '--null--';

    properties = [
        {
            text: 'Číslo smlouvy',
            value: 'cisloSmlouvyText'
        },
        {
            text: 'Typ pronájmu',
            value: 'typPronajem'
        },
        {
            text: 'Stav pronájmu',
            value: 'stavPronajem'
        },
        {
            text: 'Stav žádosti',
            value: 'stavZadost'
        },
        {
            text: 'Žadatel',
            value: 'zadatelUzivatel'
        },
        {
            text: 'Zpracovatel',
            value: 'zpracovatelUzivatel'
        },
        {
            text: 'Pronajímatel',
            value: 'pronajimatelPartner'
        },
        {
            text: 'Nájemce',
            value: 'najemcePartner'
        },
        {
            text: 'Plánovaný termín podpisu',
            value: 'datumPodpisuPlan'
        },
        {
            text: 'Nájem od',
            value: 'najemOd'
        },
        {
            text: 'Nájem do',
            value: 'najemDo'
        },
        {
            text: 'Předání nájmu',
            value: 'najemPredani'
        },
        {
            text: 'Email pro faktury',
            value: 'emailProFaktury'
        },
        {
            text: 'DPH',
            value: 'dph'
        },
        {
            text: 'Zákon 340',
            value: 'zakon340'
        },
        {
            text: 'Pojištění',
            value: 'pojisteni'
        },
        {
            text: 'Důvod storna',
            value: 'duvodStorno'
        },
        {
            text: 'Ručitel text',
            value: 'rucitelText'
        },
        {
            text: 'Výpovědní lhůta',
            value: 'vypovedniLhuta'
        },
        {
            text: 'Administrativní poplatek',
            value: 'administrativniPoplatek'
        },
        {
            text: 'Náhrada',
            value: 'nahrada'
        }
    ];

    mounted() {
        this.$odata
            .function<any>('pnps', {
                key: this.entityId,
                func: 'GetNemovitostHistory()'
            })
            .then(x => {
                this.dataNemovitosti = x.data.value;
                console.log(this.dataNemovitosti);
            })
            .catch(x => console.log(x));
    }
}
