











































import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { QueryOptions } from 'odata-query';

import { ODataResult, GridOptions } from '@/models';
import { CenoTvorba, NemovitostCv, NemovitostFilter } from '@/models/nemovitost';

import { DraftGetter } from '@/store/draft/draft.getter';
import { Modules } from '@/store/modules';

import NemovitostFilterComponent from '@/ui/components/nemovitost/nemovitost-filter.component.vue';
import NemovitostListComponent from '@/ui/components/nemovitost/nemovitost-list.component.vue';
import { ModDraftMutation } from '@/store/draft/draft.mutation';

@Component({
    computed: {
        ...mapGetters(Modules.DRAFT, [DraftGetter.SIDE_EDIT])
    },
    components: {
        'cms-nemovitost-list': NemovitostListComponent,
        'cms-nemovitost-filtet': NemovitostFilterComponent
    }
})
export default class NemovitostListPage extends Vue {
    data: ODataResult<any> | null = null;

    isLoading = false;

    selected: any[] = [];

    options = {
        page: 1,
        itemsPerPage: 15,
        sortBy: ['katalogoveCislo'],
        sortDesc: [false],
        multiSort: true,
        mustSort: false
    };

    filter: NemovitostFilter = { pronajmutelne: 1 };

    setDefaultOptions() {
        this.options = {
            page: 1,
            itemsPerPage: 15,
            sortBy: ['katalogoveCislo'],
            sortDesc: [false],
            multiSort: true,
            mustSort: false
        };
    }

    setDefaultFilter() {
        this.filter = { pronajmutelne: 1 };
    }

    prepareFilter() {
        if (this.$route.query.filter && this.$route.query.options) {
            this.$router
                .push({
                    path: this.$route.path,
                    query: { filter: JSON.stringify(this.filter), options: JSON.stringify(this.options) }
                })
                .catch(() => {});
        } else {
            this.$router
                .replace({
                    path: this.$route.path,
                    query: { filter: JSON.stringify(this.filter), options: JSON.stringify(this.options) }
                })
                .catch(() => {});
        }
    }

    @Watch('$route.query', { immediate: true })
    onChangeRouteQuery() {
        if (this.$route.query.filter) {
            this.filter = JSON.parse(String(this.$route.query.filter));
        } else {
            this.setDefaultFilter();
        }

        if (this.$route.query.options) {
            this.options = JSON.parse(String(this.$route.query.options));
        } else {
            this.setDefaultOptions();
        }

        this.fetchData();
    }

    async fetchData(): Promise<void> {
        this.isLoading = true;

        const skip = (this.options.page - 1) * this.options.itemsPerPage;
        const orderBy = this.options.sortBy.map(
            (sort, index) => sort.replaceAll('.', '/') + (this.options.sortDesc[index] ? ' desc' : '')
        ) as any;
        const filter: any[] = [];
        if (this.filter.pronajmutelne) {
            filter.push({ nepronajmutelne: { eq: this.filter.pronajmutelne === 2 ? true : false } });
        }

        if (this.filter.typNemovitosts?.length) filter.push({ typNemovitostiId: { in: this.filter.typNemovitosts } });
        if (this.filter.stavPronajems?.length) filter.push({ stavPronajemId: { in: this.filter.stavPronajems } });
        if (this.filter.budovas?.length) filter.push({ budovaId: { in: this.filter.budovas } });
        if (this.filter.lokalitaId) filter.push({ lokalitaId: { eq: +this.filter.lokalitaId } });
        if (this.filter.plochaOd) filter.push({ plocha: { ge: +this.filter.plochaOd } });
        if (this.filter.plochaDo) filter.push({ plocha: { le: +this.filter.plochaDo } });

        if (this.filter.garanceNajemDo) filter.push({ GaranceNajemDo: { le: new Date(this.filter.garanceNajemDo) } });
        if (this.filter.oznaceni) filter.push({ 'tolower(unaccentOznaceni)': { contains: this.$lowerUnaccent(this.filter.oznaceni) } });

        if (this.filter.cislo) filter.push({ cislo: { eq: +this.filter.cislo } });

        if (this.filter.vybavenis?.length) filter.push({ vybavenis: { any: { id: { in: this.filter.vybavenis } } } });
        if (this.filter.maxNajem) filter.push({ maxNajem: { le: +this.filter.maxNajem } });

        if (this.filter.podlazi?.length) {
            const filterPodlazi: any[] = [];

            this.filter.podlazi.forEach(i => {
                filterPodlazi.push({ podlaziIds: { contains: '|' + i + '|' } });
            });
            filter.push({ or: filterPodlazi });
        }

        const query: Partial<QueryOptions<any>> = {
            top: this.options.itemsPerPage,
            count: true,
            filter,
            orderBy,
            skip
        };

        this.data = (await this.$odata.getList<any>('nemovitostCvView', query)).data;

        this.isLoading = false;
    }

    optionsUpdate(opt: GridOptions) {
        this.options = { ...opt };

        this.prepareFilter();
    }

    async onFilterSubmit(): Promise<void> {
        this.options.page = 1;
        this.prepareFilter();
        this.fetchData();
    }

    async resetFilter(): Promise<void> {
        this.setDefaultFilter();

        this.options.page = 1;
        this.prepareFilter();
    }

    rowClick(e: any): void {
        this.$router.push({
            name: 'nemovitost.detail',
            params: { nemCvId: e.id }
        });
    }

    async addDraft(): Promise<void> {
        const result = await this.$odata.getList<NemovitostCv>('nemovitostCv', {
            expand: { cenoTvorba: {}, nemovitost: { expand: { typNemovitost: {} } } },
            filter: { id: { in: this.selected.map(m => m.id) } }
        });

        this.$store.commit(ModDraftMutation.ADD_NEMOVITOST.toString(), {
            nemovitosts: result.data.value.map(m => {
                return { ...m, cenoTvorba: new CenoTvorba(m.cenoTvorba) };
            })
        });
    }
}
