


























































import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { Report, GridOptions } from '@/models/';

import { ODataResult } from '@/models/odata.results';
import { QueryOptions } from 'odata-query';
import { VForm } from '@/models/vue-components';

@Component
export default class ReportListPage extends Vue {
    @Ref('form') form!: VForm;

    data: ODataResult<Report> | null = null;
    isLoading = false;

    filter: any = {};

    dialog = false;

    report: any = {};

    async fetchData(): Promise<void> {
        this.isLoading = true;

        const filter = [];
        if (this.filter.id) filter.push({ id: { eq: this.filter.id } });
        if (this.filter.nazev) filter.push({ 'toLower(nazev)': { contains: this.filter.nazev.toLowerCase() } });

        const query: Partial<QueryOptions<Report>> = {
            filter,
            select: ['id', 'nazev', 'popis'],
            top: this.options.itemsPerPage,
            count: true,
            skip: (this.options.page - 1) * this.options.itemsPerPage,
            orderBy: this.options.sortBy.map((sort, index) => sort + (this.options.sortDesc[index] ? ' desc' : '')) as any
        };

        const tmpData = (await this.$odata.getList<Report>('report', query)).data;

        this.data = tmpData;

        this.isLoading = false;
    }

    @Watch('$route.query')
    onChangeRouteQuery() {
        if (this.$route.query.filter) {
            this.filter = JSON.parse(String(this.$route.query.filter));
        } else {
            this.setDefaultFilter();
        }

        if (this.$route.query.options) {
            this.options = JSON.parse(String(this.$route.query.options));
        } else {
            this.setDefaultOptions();
        }

        this.fetchData();
    }

    headers = [
        { text: 'ID', value: 'id' },
        { text: 'Název', value: 'nazev' },
        { text: 'Popis', value: 'popis' }
    ];

    options: GridOptions = {
        page: 1,
        itemsPerPage: 15,
        sortBy: ['id'],
        sortDesc: [false],
        multiSort: true,
        mustSort: false
    };

    setDefaultFilter() {
        this.filter = {};
    }

    setDefaultOptions() {
        this.options = {
            page: 1,
            itemsPerPage: 15,
            sortBy: ['id'],
            sortDesc: [false],
            multiSort: true,
            mustSort: false
        };
    }

    resetFilter(): void {
        this.setDefaultFilter();

        this.options.page = 1;
        this.prepareFilter();
    }

    zalozit(): void {
        this.report = {};

        this.dialog = true;
    }

    rowClick(e: Report): void {
        this.report = { ...e };

        this.dialog = true;
    }

    prepareFilter() {
        if (this.$route.query.filter) {
            this.$router
                .push({
                    path: this.$route.path,
                    query: { filter: JSON.stringify(this.filter), options: JSON.stringify(this.options) }
                })
                .catch(() => {});
        } else {
            this.$router
                .replace({
                    path: this.$route.path,
                    query: { filter: JSON.stringify(this.filter), options: JSON.stringify(this.options) }
                })
                .catch(() => {});
        }
    }

    onFilterSubmit(): void {
        this.options.page = 1;
        this.prepareFilter();
    }

    @Watch('dialog')
    onChangeDialog() {
        if (!this.dialog) {
            this.report = {};
        }
    }

    async ulozit(designer: boolean) {
        if (this.form.validate()) {
            if (this.report.id) {
                await this.$odata.update('report', this.report.id, this.report);
            } else {
                this.report = (await this.$odata.create<Report>('report', this.report)).data;
            }

            if (designer) this.designer();
            this.fetchData();
            this.dialog = false;
        }
    }

    designer() {
        this.$router.push({ name: 'reporter.designer', params: { reportId: this.report.id } });
    }

    reporter() {
        this.$router.push({ name: 'reporter.report', params: { reportId: this.report.id } });
    }
}
