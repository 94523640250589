























import { Lokalita } from '@/models';
import { PozadavekNaPripravuSmlouvy } from '@/models/pnps';
import { uniq } from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class PnpsListSmallComponent extends Vue {
    @Prop({ required: true }) data!: PozadavekNaPripravuSmlouvy[];
    @Prop({ required: true }) isLoading!: boolean;

    @Prop({ required: false }) headers?: any[];

    @Prop({ type: Boolean }) showFooter!: boolean;

    get cols(): any[] {
        return this.headers || this.defaultHeaders;
    }

    lokalitas: Lokalita[] = [];

    @Watch('data', { immediate: true })
    async onChangeData() {
        if (this.data) {
            if (uniq(this.data.map(m => m.obchodniPripad.lokalitaId)).length) {
                this.lokalitas = (
                    await this.$odata.getList<Lokalita>('lokalitaView', {
                        filter: { id: { in: uniq(this.data.map(m => m.obchodniPripad.lokalitaId)) } },
                        select: ['id', 'nazev']
                    })
                ).data.value;
            }
        } else {
            this.lokalitas = [];
        }
    }

    getLokalitaNazev(lokalitaId: number) {
        return (this.lokalitas || []).find(f => f.id == lokalitaId)?.nazev;
    }

    defaultHeaders = [
        { text: 'Lokalita', value: 'obchodniPripad.lokalitaNazev', sortable: false },
        { text: 'Číslo smlouvy', value: 'cisloSmlouvyText' },
        { text: 'Stav', value: 'stavZadost.nazev' },
        { text: 'Partner', value: 'najemcePartner.nazev' },
        { text: 'Plánovaný termín podpisu', value: 'datumPodpisuPlan' },
        { text: 'Požadoval', value: 'zadatelUzivatel.fullName', sortable: false },
        { text: 'Zpracovatel', value: 'zpracovatelUzivatel.fullName', sortable: false }
    ];
}
