import { RightService } from '@/services/right.service';
import { SnackbarService } from '@/services/snackbar.service';
import { ODataService } from '@/services/odata.service';
import { formatDate, formatNumber, lowerUnaccent } from '@/helpers';

const vuePrototype = {
    install: (Vue: any) => {
        Vue.prototype.$snackbar = new SnackbarService();
        Vue.prototype.$right = new RightService();
        Vue.prototype.$odata = new ODataService();
        Vue.prototype.$formatDate = formatDate;
        Vue.prototype.$formatNumber = formatNumber;
        Vue.prototype.$lowerUnaccent = lowerUnaccent;
    }
};

export default vuePrototype;
