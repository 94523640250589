import R from '@/models/resources';
import { Component, Vue } from 'vue-property-decorator';

Component.registerHooks(['beforeRouteLeave']);

@Component
export default class NotSavedComponent extends Vue {
    isChanged = false;

    async beforeRouteLeave(to: any, from: any, next: Function) {
        if (!this.isChanged) {
            next();
        } else {
            this.$root.$confirm(R.NeulozeneZmeny, R.NeulozeneZmenyOtazka, { type: 'warning' }).then(a => {
                a ? next() : next(false);
            });
        }
    }
}
