import { RightService } from '@/services/right.service';
import { NavigationGuard, NavigationGuardNext, Route } from 'vue-router';

export const hasRight: NavigationGuard = (to: Route, from: Route, next: NavigationGuardNext) => {
    const rights = [...to.matched.map(m => m.meta.right), to.meta.right].filter(f => !!f);

    if (rights.length) {
        const service = new RightService();
        rights.forEach(async r => {
            const res = await service.hasAccessAsync(r);
            if (res !== null && !res) {
                next({ name: 'home' });
            }
        });
    }
};
