
















import { Component, Prop, Vue } from 'vue-property-decorator';
import PnpsHistoryComponent from '../pnps-history.component.vue';

@Component({
    components: {
        'cms-pnps-histore': PnpsHistoryComponent
    }
})
export default class PnpsHistoryTab extends Vue {
    @Prop() entityId!: number;
}
