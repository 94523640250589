


































































import { NemovitostCv } from '@/models/nemovitost';
import { PozadavekNaPripravuSmlouvy } from '@/models/pnps';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import NemovitostListSmallComponent from '../../nemovitost/nemovitost-list-small.component.vue';
import PnpsListSmallComponent from '../../pnps/pnps-list-small.component.vue';

@Component({
    components: {
        'cms-pnps-list-small': PnpsListSmallComponent,
        'cms-nemovitost-list-small': NemovitostListSmallComponent
    }
})
export default class PartnerPrehledSmluvTab extends Vue {
    @Prop() partnerId!: number;

    isLoadingPnpsNajemce = false;
    isLoadingNemovitostsNajemce = false;

    isLoadingPnpsPronajimatel = false;
    isLoadingNemovitostsPronajimatel = false;

    pnpsNajemce: PozadavekNaPripravuSmlouvy[] = [];
    nemovitostsNajemce: any[] = [];

    pnpsPronajimatel: PozadavekNaPripravuSmlouvy[] = [];
    nemovitostsPronajimatel: NemovitostCv[] = [];

    mounted() {
        this.fetchPnpsNajemce();
        this.fetchPnpsPronajimatel();
    }

    async fetchPnpsNajemce() {
        this.isLoadingPnpsNajemce = true;

        this.pnpsNajemce = (
            await this.$odata.getList<PozadavekNaPripravuSmlouvy>('pnps', {
                expand: {
                    obchodniPripad: { expand: { lokalita: {} } },
                    najemcePartner: { select: ['nazev'] },
                    zadatelUzivatel: { select: ['fullName'] },
                    zpracovatelUzivatel: { select: ['fullName'] },
                    stavZadost: { select: ['nazev'] }
                },
                orderBy: ['id desc'] as any,
                filter: {
                    najemcePartnerId: { eq: this.partnerId }
                }
            })
        ).data.value;

        this.isLoadingPnpsNajemce = false;
    }

    @Watch('pnpsNajemce', { deep: true })
    async fetchNemovitostNajemce() {
        if (this.pnpsNajemce.length) {
            this.isLoadingNemovitostsNajemce = true;

            this.nemovitostsNajemce = (
                await this.$odata.function<any>('nemovitostcv', {
                    func: { GetPronajate: { najemceId: this.partnerId } },

                    orderBy: ['katalogoveCislo']
                })
            ).data.value;
            this.isLoadingNemovitostsNajemce = false;
        }
    }

    async fetchPnpsPronajimatel() {
        this.isLoadingPnpsPronajimatel = true;

        this.pnpsPronajimatel = (
            await this.$odata.getList<PozadavekNaPripravuSmlouvy>('pnps', {
                expand: {
                    obchodniPripad: { expand: { lokalita: {} } },
                    najemcePartner: { select: ['nazev'] },
                    zadatelUzivatel: { select: ['fullName'] },
                    zpracovatelUzivatel: { select: ['fullName'] },
                    stavZadost: { select: ['nazev'] }
                },
                orderBy: ['id desc'] as any,
                filter: {
                    pronajimatelPartnerId: { eq: this.partnerId }
                }
            })
        ).data.value;

        this.isLoadingPnpsPronajimatel = false;
    }

    @Watch('pnpsPronajimatel', { deep: true })
    async fetchNemovitostPronajimatel() {
        if (this.pnpsPronajimatel.length) {
            this.isLoadingNemovitostsPronajimatel = true;

            this.nemovitostsPronajimatel = (
                await this.$odata.function<any>('nemovitostcv', {
                    func: { GetPronajimane: { pronajimatelId: this.partnerId } },

                    orderBy: ['katalogoveCislo']
                })
            ).data.value;

            this.isLoadingNemovitostsPronajimatel = false;
        }
    }

    pnpsClick(e: PozadavekNaPripravuSmlouvy) {
        this.$router.push({ name: 'pnps.detail', params: { pnpsId: String(e.id) } });
    }

    nemovitostClick(e: NemovitostCv) {
        this.$router.push({
            name: 'nemovitost.detail',
            params: { nemCvId: String(e.id) }
        });
    }
}
