


































import { TimeVersion } from '@/models';
import { mapGetters } from 'vuex';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Modules } from '@/store/modules';
import { AppGetter } from '@/store/app/app.getter';

@Component({
    computed: {
        ...mapGetters(Modules.APP, [AppGetter.CURR_TIMEVERSION])
    }
})
export default class CmsTelFieldComponent extends Vue {
    @Prop({ type: Boolean }) readonly!: boolean;
    @Prop({ type: Boolean }) disabled!: boolean;
    @Prop({ default: true, type: Boolean }) dense!: boolean;
    @Prop() value!: string;
    @Prop() label!: string;
    @Prop() suffix!: string;
    @Prop() prefix!: string;
    @Prop({ type: Boolean }) clearable!: boolean;
    @Prop({ type: Boolean }) required!: boolean;
    @Prop() prependIcon!: string;
    @Prop() prependInnerIcon!: string;
    @Prop() appendIcon!: string;
    @Prop() appendOuterIcon!: string;
    @Prop() type!: string;
    @Prop() minLength!: number;
    @Prop() maxLength!: number;
    @Prop({ type: Boolean }) counter!: boolean;
    @Prop() placeholder!: string;
    @Prop() hintText!: string;
    @Prop() props!: object;

    @Prop({ type: Boolean }) cv!: boolean;

    @Prop() right!: string;

    CURR_TIMEVERSION!: TimeVersion;

    get hasRight(): boolean {
        return this.$right.hasRead(this.right);
    }

    get isReadonly(): boolean {
        return (
            this.readonly ||
            !this.$right.hasWrite(this.right) ||
            (this.cv && (!this.CURR_TIMEVERSION || this.CURR_TIMEVERSION.casovaHladinaTyp.id === TimeVersion.Archivacni))
        );
    }

    get ruleset(): any[] {
        const result = [];
        if (this.required) result.push((v: any) => !!v || this.label + ' musí být vyplněno');
        result.push((v: any) => {
            const regex = /^(\+)?[\d\s]+$/;
            return !v || (regex.test(v) && v.length > 5) || 'Nevalidní tel. číslo';
        });

        return result;
    }
}
